@import '../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .draggable-list-wrapper {
    gap: vw(16, $s);
  }

  .draggable-list-sorting {
    // gap: vw(12, $s);
    flex: 0 0 vw(391, $s);
    max-width: vw(391, $s);

    &__head {
      border-radius: vw(12, $s) vw(12, $s) 0 0;

      .draggable-list-sorting__item {
        .item {
          &:first-child {
            padding-inline-start: vw(12, $s);
          }
          &:last-child {
            padding-inline-end: vw(12, $s);
          }
        }
      }
    }
    &__body {
      height: vw(276, $s);
      gap: vw(12, $s);
      padding-block-start: vw(12, $s);

      .draggable-list-sorting__item {
        .item {
          &:not(:last-child) {
            &::before {
              height: vw(12, $s);
              box-shadow: vw(-1, $s) 0 0 0 #F0F0F0 inset;
            }
          }
        }
      }
    }
    &__item {
      .item {
        height: vw(36, $s);
        gap: vw(8, $s);

        &:first-child {
          padding-inline-end: vw(12, $s);
          flex: 0 0 vw(197, $s);
          max-width: vw(197, $s);
        }
        &:last-child {
          padding-inline-start: vw(12, $s);
          // flex: 0 0 vw(194, $s);
          // max-width: vw(194, $s);
        }
        &:not(:last-child) {
          box-shadow: vw(-1, $s) 0 0 0 #F0F0F0 inset;
        }
        .anticon {
          font-size: vw(18, $s);
        }
      }
    }
  }

  .draggable-list-source {
    gap: vw(9, $s);
    padding: vw(12, $s);
    border-radius: vw(12, $s);
    flex: 0 0 vw(314, $s);
    max-width: vw(314, $s);

    &__body {
      height: vw(267, $s);
      gap: vw(12, $s);

      .draggable-list-source__item {
        border: vw(1, $s) solid var(--neutral-gray-100);
        // height: vw(32, $s);
        padding: vw(4, $s) vw(12, $s);
        border-radius: vw(8, $s);
      }
    }
  }
}

.draggable-list-wrapper {
  display: flex;
}

.draggable-list-sorting {
  display: flex;
  flex-direction: column;
  color: var(--neutral-gray-400);

  &__head {
    background: var(--primary-1);
  }
  &__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .draggable-list-sorting__item {
      .item {
        position: relative;

        &:not(:last-child) {
          &::before {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            bottom: 100%;
          }
        }
        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;

    .item {
      display: flex;
      align-items: center;

      .icon {
        cursor: pointer;
        display: inline-flex;
        line-height: 1;
        flex-shrink: 0;
      }
      .anticon {
        cursor: pointer;
      }
    }
    .content-between {
      justify-content: space-between;
    }
    .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.draggable-list-source {
  display: flex;
  flex-direction: column;
  background: #F9FAFB;

  &__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .draggable-list-source__item {
      background: #FFFFFF;
    }
  }
  &__item {
    user-select: none;
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-item-notification {
    padding: vw(24, $s);
    display: flex;
    flex-direction: column;
    gap: vw(32, $s);
    border: vw(1, $s) solid #F2F4F7;
    border-radius: vw(12, $s);
    justify-content: space-between;
    background-color: #FCFCFD;
    &.t-item-notification-show {
      padding: vw(16, $s) vw(24, $s);
      .type-content {
        color: #98A2B3;
        font-size: vw(14, $s);
      }
    }

    .top {
      display: flex;
      flex-direction: column;
      gap: vw(12, $s);
    }

    .label {
      display: inline-flex;
      gap: vw(8, $s);
      height: vw(30, $s);
      align-items: center;
      padding: 0 vw(10, $s);
      border-radius: vw(8, $s);
      border: vw(1, $s) solid #FA541C;
      background-color: #FFF2E8;
      color: #FA541C;
      &.type-2 {
        border: vw(1, $s) solid #40A9FF;
        background-color: #E6F7FF;
        color: #1890FF;
      }
      &.type-3 {
        border: vw(1, $s) solid #722ED1;
        background-color: #F9F0FF;
        color: #722ED1;
      }
      &.type-4 {
        border: vw(1, $s) solid #EB2F96;
        background-color: #FFF0F6;
        color: #EB2F96;
      }
      .icon-svg {
        display: flex;
        align-items: center;
        font-size: vw(16, $s);
      }
    }
    .title-block {
      font-family: var(--font-medium);
      font-size: vw(16, $s);
      line-height: vw(24, $s);
      color: #344054;
    }
    .type-status {
      color: #667085;
      padding-left: vw(14, $s);
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: vw(6, $s);
        height: vw(6, $s);
        border-radius: 50%;
        background-color: #1890FF;
      }
    }
    .type-content {
      color: #667085;
      font-size: vw(16, $s);
      line-height: vw(24, $s);
      a {
        margin-left: vw(4, $s);
        margin-right: vw(4, $s);
        color: #1890FF;
      }
    }
    .btn-block {
      display: flex;
      justify-content: flex-end;
      gap: vw(12, $s);
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: vw(32, $s);
        height: vw(32, $s);
        border-radius: vw(8, $s);
        box-shadow: 0 vw(9, $s) vw(24, $s) vw(4, $s) #0000000D;
        font-size: vw(16, $s);
        cursor: pointer;
        &.icon-delete {
          color: #F5222D;
        }
      }
    }
    img {
      height: auto;
    }
  }
}

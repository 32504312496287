@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .add-rank-page {
    .content-add-rank {
      display: flex;
      .form-add-rank {
        flex: 0 0 auto;
        width: 100%;
        max-width: vw(377, $s);
        .ant-form-item {
          font-size: vw(14, $s);
          margin-bottom: vw(16, $s);
          .ant-form-item-label >label {
            font-size: vw(14, $s);
            color: #344054;
            font-family: var(--font-bold);
            &::before {
              display: none;
            }
          }
        }
        .ant-upload-wrapper.ant-upload-picture-card-wrapper {
          .ant-upload.ant-upload-select {
            width: vw(102, $s);
            height: vw(102, $s);
            margin-inline-end: 0;
            margin-bottom: 0;
            border: vw(1, $s) dashed transparent;
            border-radius: vw(8, $s);
            .ant-upload {
              background-color: #F9FAFB;
              border-color: transparent;
            }
            button {
              font-size: vw(14, $s);
              color: #98A2B3;
              padding: 0 vw(8, $s);
              font-family: var(--font-regular);
              span {
                color: #344054;
              }
              div {
                margin-top: vw(8, $s) !important;
              }
            }
          }
        }
        .input-block {
          &.addon-after-input {
            input {
              border-start-end-radius: 0;
              border-end-end-radius: 0;
            }
            .ant-input-group-addon {
              padding: 0 vw(11, $s);
              color: rgba(0, 0, 0, 0.88);
              font-weight: normal;
              font-size: vw(14, $s);
              text-align: center;
              border-radius: vw(8, $s);
              border: vw(1, $s) solid #d9d9d9;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-left: 0 solid transparent;       
            }
          }
          input {
            border-radius: vw(8, $s);
            border-width: vw(1, $s);
            font-size: vw(14, $s);
            padding: vw(4, $s) vw(11, $s);
            line-height: vw(22, $s);
          }
        }
        .btn-block {
          height: vw(32, $s);
          border-top: vw(1, $s) solid #f0f0f0;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: vw(17, $s);
          .btn {
            display: flex;
            gap: vw(8, $s);
            align-items: center;
            justify-content: center;
            border-radius: vw(8, $s);
            border: vw(0, $s) solid #EAECF0;
            height: vw(32, $s);
            color: #000000D9;
            line-height: vw(22, $s);
            font-size: vw(14, $s);
            cursor: pointer;
            &.btn-primary {
              color: #fff;
              background-color: #096DD9;
            }
          }
        }
      }
      .detail-block {
        flex: 0 0 auto;
        width: 100%;
        max-width: calc(100% - vw(377, $s));
        padding-left: vw(24, $s);
      }
    }
  }
  .t-table-block.t-table-benefits {
    .ant-table-wrapper {
      table tr th:nth-child(1) {
        width: vw(60, $s);
      }

      table tr th:nth-child(2) {
        width: vw(93, $s);
      }

      table tr th:nth-child(3) {
        width: vw(167, $s);
      }

      table tr th:nth-child(4) {
        width: vw(872, $s);
      }
    }
  }

  .ctrl-filter-benefits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-block {
      font-size: vw(16, $s);
      line-height: vw(24, $s);
      font-family: var(--font-medium);
    }
  }
}
@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .vip-care {
    &-content {
  
    }
    &-report {
      padding-top: vw(10, $s);
      gap: vw(12, $s);

      .top {
        gap: vw(12, $s);

        .control {
          gap: vw(15, $s);

          .rs-radio {
            &--button {
              border-radius: vw(6, $s);
              padding: vw(4, $s) vw(12, $s);
              line-height: vw(22, $s);
            }
            &-group--button {
              gap: vw(15, $s);
            }
          }
          .ant-picker {
            width: vw(256, $s);
          }
        }
      }
      &-table {
        padding-top: vw(22, $s);
        gap: vw(12, $s);

        .top {
          gap: vw(12, $s);

          .ant {
            &-input-search {
              flex: 0 0 vw(600, $s);
              max-width: vw(600, $s);
            }
            &-btn {
              font-size: vw(14, $s);
              line-height: vw(22, $s);
            }
          }
          .control {
            gap: vw(12, $s);
          }
        }
      }
    }
  }
}

.vip-care {
  &-content {

  }
  &-report {
    display: flex;
    flex-direction: column;
    
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .control {
        display: grid;
        grid-auto-flow: column;

        .rs-radio {
          white-space: nowrap;
        }
      }
    }
    &-table {
      display: flex;
      flex-direction: column;

      .top {
        display: flex;
        justify-content: space-between;

        .ant-btn {
          font-family: var(--font-regular);
        }
        .control {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .w {
    &-275-px {
      width: vw(275, $s);
    }

    &-62-px {
      width: vw(62, $s);
    }
  }

  .h {
    &-26-px {
      height: vw(26, $s);
    }
  }

  .m-none-header.ant-modal .ant-modal-header {
    margin-bottom: unset !important;
  }

  .p-ant-picker.ant-picker {
    padding: vw(4, $s) vw(11, $s) vw(4, $s) vw(16, $s);
  }

  .size-input-picker.ant-picker .ant-picker-input>input {
    font-size: vw(14, $s);
  }


  .custom-table {

    &.ant-table-wrapper .ant-table-cell,
    .ant-table-wrapper .ant-table-thead>tr>th {
      padding: vw(16, $s) vw(16, $s);
    }

    &.ant-table-wrapper .ant-table-tbody>tr>td {
      padding: vw(16, $s) vw(16, $s);
    }
  }

  .ant-form-item .ant-form-item-control-input {
    min-height: unset
  }

  .custom.ant-form-item {
    .ant-row.ant-form-item-row {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .arc-statistical-cards {
    gap: vw(18, $s);
  }
}

.arc-statistical-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-columns: 1fr;
}
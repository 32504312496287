@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-mt-80 {
    margin-top: vw(80, $s);
  }

  .empty-component-link {
    &__inner {
      gap: vw(8, $s);
    }    
    img {
      height: vw(150, $s);
      width: vw(245, $s);
    }
    .title {
      line-height: vw(22, $s);
      font-size: vw(16, $s);
      font-family: var(--font-bold);
      color: #667085;
    }
    .note {
      max-width: vw(360, $s);
      text-align: center;
      color: #667085;
      strong {
        margin-right: vw(4, $s);
      }
      a {
        margin-left: vw(4, $s);
        margin-right: vw(4, $s);
        color: #349dff;
        position: relative;
        &::before {
          height: vw(1, $s);
          background-color: #349dff;
          width: 100%;
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;        
        }
      }
    }
  }
}

.empty-component-link {
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    object-fit: cover;
  }
  .title {
    color: var(--neutral-gray-500);
  }
}
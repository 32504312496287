@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .w-modal.ant-modal {
    width: vw(400, $s) !important;
  }
  .w-modal-V2.ant-modal {
    width: vw(842, $s) !important;
  }
  .w-modal-477.ant-modal {
    width: vw(477, $s) !important;
  }
  .ant-modal-size.ant-modal {
    font-size: vw(14, $s);
    line-height: vw(1.5714285714285714, $s);
  }

  .modal-content.ant-modal .ant-modal-content {
    border-radius: vw(2, $s) !important;
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05) !important;
    padding: unset !important;
    height: auto;
  }

  .modal-content-warning.ant-modal .ant-modal-content {
    border-radius: vw(2, $s) !important;
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05) !important;
    padding: unset !important;
    height: auto;
  }

  .border-b-F0F0F0 {
    border-bottom: vw(1, $s) solid #F0F0F0;
  }

  .border-t-F0F0F0 {
    border-top: vw(1, $s) solid #F0F0F0;
  }

  .w {
    &-104-px {
      width: vw(104, $s);
    }

    &-80-px {
      width: vw(80, $s);
    }
  }

  .h {
    &-104-px {
      height: vw(104, $s);
    }

    &-80-px {
      height: vw(80, $s);
    }
  }

}

.justify-betweeen {
  justify-content: space-between;
}

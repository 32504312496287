@include vwConvert() using ($s) {
  .rs-select {
    &.ant-select {
      &-single {
        height: vw(32, $s);

        .ant-select {
          &-selector {
            // border-width: vw(1, $s);
            // border-radius: vw(8, $s);

            .ant-select-selection-search {
              inset-inline: vw(11, $s);
            }
          }

          &-selection-item,
          &-selection-placeholder {
            // padding: vw(4, $s) vw(32, $s) vw(4, $s) vw(15, $s);
            padding: vw(4, $s) vw(24, $s) vw(4, $s) vw(12, $s);
            line-height: vw(30, $s);
          }
        }
      }

      &-multiple {
        min-height: vw(32, $s);
        // height: vw(32, $s);

        .ant-select-selector {
          border-radius: vw(8, $s);
          padding-inline: vw(3, $s);
          padding-block: vw(1, $s);
          min-height: vw(32, $s);
        }

        &.ant-select-show-arrow .ant-select-selector,
        &.ant-select-allow-clear .ant-select-selector {
          padding-inline-end: vw(24, $s);
        }

        .ant-select-selection-item {
          height: vw(24, $s);
          line-height: vw(22, $s);
          border-width: vw(1, $s);
        }

        .ant-select-selection-placeholder {
          inset-inline: vw(11, $s);
        }

        .ant-select-selection-overflow {
          .ant-select-selection-item {
            margin-block: vw(2, $s);
            margin-inline-end: vw(4, $s);
            border-radius: vw(4, $s);
            padding-inline: vw(8, $s) vw(4, $s);
          }

          .ant-select-selection-item-content {
            margin-inline-end: vw(4, $s);
          }

          .ant-select-selection-item-remove {
            font-size: vw(10, $s);
          }
        }
      }
    }

    &.ant-tree-select {
      .ant-select-selector {
        // padding: vw(4, $s) vw(32, $s) vw(4, $s) vw(12, $s);
        padding: 0 vw(32, $s) 0 vw(12, $s);
      }

      .ant-select-selection-placeholder {
        inset-inline: vw(12, $s);
      }

      .ant-select-selection-item {
        height: vw(24, $s);
        line-height: vw(22, $s);
        margin-block: vw(2, $s);
        margin-inline-end: vw(8, $s);
        border-radius: vw(4, $s);
        padding-inline-start: vw(8, $s);
        padding-inline-end: vw(4, $s);

        &-content {
          margin-inline-end: vw(4, $s);
        }

        &-remove {
          font-size: vw(10, $s);
        }
      }
    }

    &-dropdown {
      font-size: vw(14, $s);
      padding: vw(4, $s);
      border-radius: vw(8, $s);
      box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);

      .ant-select-item {
        font-size: vw(14, $s);
        min-height: vw(32, $s);
        padding: vw(5, $s) vw(12, $s);
        border-radius: vw(6, $s);
      }

      &.ant-tree-select-dropdown {
        padding: vw(8, $s) vw(4, $s);
        border-radius: vw(2, $s);
        box-shadow: 0 vw(2, $s) vw(8, $s) 0 #00000026;

        .ant-select-tree .ant-select-tree-treenode {
          padding-block-end: vw(4, $s);
        }

        .ant-select-tree-title {
          font-size: vw(14, $s);
          line-height: vw(22, $s);
        }

        .ant-select-tree-switcher {
          width: vw(24, $s);
          line-height: vw(24, $s);

          .ant-select-tree-switcher-icon {
            font-size: vw(12, $s);
          }
        }

        .ant-select-tree-checkbox {
          margin-inline-end: vw(8, $s);
          margin-block-start: vw(4, $s);

          .ant-select-tree-checkbox-inner {
            height: vw(16, $s);
            width: vw(16, $s);

            &::after {
              width: vw(5.7, $s);
              height: vw(9.1, $s);
            }
          }

          +span {
            padding-inline: vw(4, $s);
            min-height: vw(24, $s);
            line-height: vw(24, $s);
            border-radius: vw(6, $s);
          }
        }
      }

      .rc-virtual-list-holder,
      .ant-select-tree-list-holder {
        max-height: vw(256, $s) !important;
      }

      .ant-radio-group {
        margin-block-end: vw(8, $s);
      }
    }

    .ant-select-arrow {
      font-size: vw(12, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      inset-inline-end: vw(10, $s);
    }

    .ant-select-clear {
      font-size: vw(12, $s);
      width: vw(12, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      inset-inline-end: vw(14, $s);
    }

    .ant-select {
      &-selector {
        border-width: vw(1, $s);
        border-radius: vw(8, $s);
      }
    }

    &.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      box-shadow: 0 0 0 vw(2, $s) var(--primary-3);
    }

    &-flat.ant-select-single {
      gap: vw(4, $s);

      .ant-select-selector {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        padding-inline-end: vw(16, $s);
      }

      .ant-select-arrow {
        font-size: vw(12, $s);
        inset-inline-end: 0;
        height: vw(12, $s);
        margin-top: vw(-6, $s);
      }
    }

    &.show-tags-on-bottom {
      .ant-select-selection-overflow {
        // margin-block-start: vw(14, $s);
        gap: vw(8, $s);

        .ant-select-selection-item {
          padding-inline: vw(8, $s);
          font-size: vw(12, $s);
          border-radius: vw(8, $s);
          line-height: vw(20, $s);
          border-width: vw(1, $s);
        }
      }
    }
  }
}

.rs-select {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;

  &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #FCFCFD;
  }

  &-dropdown {
    font-family: inherit;
    color: inherit;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      font-weight: normal;
      color: inherit;
    }

    .ant-select-item {
      color: inherit;
    }

    &.ant-tree-select-dropdown {
      .ant-select-tree-title {
        font-family: var(--font-regular);
      }

      .ant-select-tree-switcher {
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;

        // &:not(.ant-select-tree-switcher-noop)::hover {
        //   background-color: transparent;
        // }
      }
    }
  }

  .ant-select {
    &-selector {
      font-size: inherit;
    }

    &-selection {
      &-placeholder {
        color: var(--neutral-gray-700);
      }
    }

    &-arrow,
    &-clear {
      color: var(--neutral-gray-700);
    }
  }

  &.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: var(--primary-5);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }

  &.ant-tree-select {
    font-size: inherit;
    font-family: inherit;
    color: inherit;

    .ant-select-arrow,
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  &-flat.ant-select-single {
    height: auto;
    display: inline-flex;
    align-items: center;
    font-family: var(--font-regular);


    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding: 0;
    }

    .ant-select-selector {
      border: none;
      padding-inline-start: 0;
      background: transparent;
    }

    // .ant-select-arrow {
    //   position: static;
    //   margin-block-start: 0;
    // }
    .ant-select-selector {
      box-shadow: none !important;
    }

    &.primary {

      .ant-select-selector,
      .ant-select-arrow,
      &.ant-select-open .ant-select-selection-item {
        color: var(--primary-6);
      }
    }
  }

  &.ant-select-multiple {
    .ant-select-selection-item {
      background: var(--primary-1);
      border-color: var(--primary-3)
    }
  }

  &--neutral-gray-100 {
    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border-color: var(--neutral-gray-100);
    }
  }

  &.hide-tags {
    .ant-select-selection-overflow {
      display: none;
    }
  }

  &.show-tags-on-bottom {
    .ant-select-selection-overflow {
      top: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .ant-select-selection-item {
        margin: 0;
        border-color: var(--select-tag-bdc);
        border-style: solid;
        background: var(--select-tag-bg);
        color: var(--select-tag-color);
        position: relative;
        height: auto;

        &:hover {
          .ant-select-selection-item-remove {
            opacity: 1;
          }
        }

        &-content {
          margin-inline-end: 0;
        }
      }

      .ant-select-selection-item-remove {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        color: var(--dust-red-6);
      }
    }

    &.golden-purple {
      --select-tag-bdc: var(--golden-purple-3);
      --select-tag-bg: var(--golden-purple-1);
      --select-tag-color: var(--golden-purple-6);
    }
  }
}
@include vwConvert() using ($s) {
  .td-customer-name{
    min-width: vw(170, $s);
  }
  .td-card-code{
    min-width: vw(100, $s);
  }
  .td-card-name{
    min-width: vw(180, $s);
  }
  .td-card-segment{
    min-width: vw(180, $s);
  }
  .td-card-version{
    min-width: vw(150, $s);
  }
}
.td-customer-name,.td-card-code,.td-card-name,.td-card-segment,.td-card-version{
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@for $i from 1 through 10 {
  .text-#{$i}-row {
    word-break: break-word;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}

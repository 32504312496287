@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-mw-300 {
    width: vw(300, $s) !important;
  }

  .pooling-data-detail-page {
    .title-btn-back {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(26, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
      display: flex;
      align-items: center;
      gap: vw(16, $s);
      color: #344054;

      .icon-svg {
        color: #000000E0;
        font-size: vw(16, $s);
        display: flex;
        align-items: center;
      }
    }

    .list-data {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: vw(24, $s);

      &.empty-item {
        grid-template-columns: repeat(2, 1fr);

        .select-search {
          margin-bottom: vw(55, $s);
        }
      }

      .one-data {
        padding-bottom: vw(66, $s);
      }

      .select-search {
        display: flex;
        gap: vw(12, $s);
        margin-top: vw(12, $s);

        .ant-select-selector {
          border: none !important;
        }

        .ant-input-wrapper {
          height: 100%;
          border: none;

          input,
          button {
            height: 100%;
            border: none;
          }
        }

        .ant-input {
          color: #344054;
          font-size: vw(16, $s);
          line-height: vw(24, $s);
          font-family: var(--font-regular);
          border-radius: vw(6, $s);
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-inline-end: vw(18, $s);
          line-height: vw(30, $s);
          font-size: vw(16, $s);
          color: #344054;
          font-family: var(--font-regular);
        }
      }

      .info-block {
        display: flex;
        flex-direction: column;
        padding: vw(24, $s);

        .title {
          font-family: var(--font-bold);
          font-weight: 600;
          padding: vw(4, $s) 0;
        }

        .list-info {
          display: flex;
          flex-direction: column;

          .one-info {
            padding: vw(12, $s) 0;
            display: flex;
            justify-content: space-between;

            .right {
              color: #98A2B3;
              max-width: vw(190, $s);
              text-align: right;

              .status {
                position: relative;
                display: inline-block;
                padding-left: vw(14, $s);

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: vw(6, $s);
                  height: vw(6, $s);
                  border-radius: 50%;
                  background-color: #98A2B3;
                }

                &.type-1 {
                  &::before {
                    background-color: #FAAD14;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ctrl-btn-add-new {
      display: flex;
      align-items: center;

      .btn-add-new-block {
        gap: vw(24, $s);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .btn {
          font-size: vw(36, $s);
          width: vw(56, $s);
          height: vw(56, $s);
          border-radius: vw(12, $s);
          border: vw(1, $s) dashed #1890FF;
          color: #1890FF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .message-pooling-success {
      width: vw(300, $s);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .img-box {
        display: flex;
        justify-content: center;
        width: vw(291, $s);
        height: vw(218, $s);
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .title-block {
        width: vw(218, $s);

        .title {
          font-size: vw(24, $s);
          line-height: vw(32, $s);
          font-family: var(--font-medium);
          text-align: center;
          margin-bottom: vw(12, $s);
        }
      }
      .text {
        text-align: center;
      }
    }

    .btn-pooling-data-block {
      display: flex;
      justify-content: center;
      margin-bottom: vw(24, $s);

      .ctrl-btn-pooling-data {
        padding: vw(12, $s);
        border-radius: vw(12, $s);
        background-color: #F2F4F7;
        &.ctrl-2btn {
          display: flex;
          gap: vw(12, $s);
          align-items: center;
          margin-top: vw(24, $s);
        }

        .btn {
          height: vw(38, $s);
          padding: 0 vw(16, $s);
          background-color: #096DD9;
          border-radius: vw(8, $s);
          color: #fff;
          box-shadow: 0 vw(6, $s) vw(8, $s) 0 #00000026;
          display: flex;
          align-items: center;
          cursor: pointer;
          &.btn-df {
            color: #344054;
            background-color: #fff;
          }
        }
      }
    }
  }

  .step2-pooling-data {
    margin-top: vw(48, $s);
    .information-tab__content-left {
      .status {
        padding-left: vw(14, $s);
        position: relative;
        color: #98A2B3;
        &::before {
          content: "";
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          position: absolute;
          width: vw(6, $s);
          height: vw(6, $s);
          border-radius: 50%;
        }
        &.type-1 {
          &::before {
            background-color: #52C41A;
          }
        }

        &.type-2 {
          &::before {
            background-color: #FAAD14;
          }
        }

        &.type-3 {
          &::before {
            background-color: #FF4D4F;
          }
        }
      }
    }
    .information-tab__content-right {
      .card-block {
        .card-block__title {
          padding-block: vw(4, $s);
        }
      }
    }
  }

  .t-cl-filter {
    filter: grayscale(1);
  }
}

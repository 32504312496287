@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .mw-256 {
    width: vw(256, $s);
  }
  .miw-136 {
    min-width: vw(136, $s) !important;
  }
  .miw-105 {
    min-width: vw(105, $s) !important;
  }
  .notification-templates-management {
    .ant-breadcrumb{
      font-size: vw(14, $s);
      color: #98A2B3;
      line-height: vw(22, $s);
      font-family: var(--font-regular);
      margin-top: 0;
      a {
        color: #98A2B3;
        height: auto;
      }
      li:last-child {
        color: #344054;
      }
      .ant-breadcrumb-separator {
        margin-inline: vw(8, $s);
        color: #98A2B3;
      }
    }
    .right {
      display: flex;
      gap: vw(12, $s);
    }
    .title-page {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(14, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
      &.title-sub {
        display: flex;
        align-items: center;
        gap: vw(12, $s);
        margin-bottom: vw(10, $s);
        .sub-title {
          font-size: vw(14, $s);
          font-family: var(--font-regular);
          line-height: vw(22, $s);
          color: #98A2B3;
        }
      }
    }

    .filter-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: vw(24, $s);
      .left {
        display: flex;
        gap: vw(12, $s);
        align-items: center;
        .title {
          font-size: vw(16, $s);
          color: #344054;
          line-height: vw(24, $s);
          font-weight: 500;
          font-family: var(--font-medium);
        }
      }
    }

    .list-tabs {
      display: flex;
      gap: vw(32, $s);
      border-bottom: vw(1, $s) solid #0000000F;
      .one-tab {
        display: flex;
        align-items: center;
        gap: vw(12, $s);
        padding-bottom: vw(12, $s);
        padding-top: vw(12, $s);
        border-bottom: vw(2, $s) solid transparent;
        cursor: pointer;
        &.active {
          color: #096DD9;
          border-bottom: vw(2, $s) solid #096DD9;
        }
        .icon-svg {
          display: flex;
          align-items: center;
        }
      }
    }

    .filter-block {
      display: flex;
      gap: vw(12, $s);
      margin-bottom: vw(18, $s);

      &.active {
        display: none;
      }

      &.filter-active-show {
        display: none;

        &.active {
          display: flex;
        }

        .total-chosse {
          display: flex;
          align-items: center;
        }

        .btn-input {
          display: flex;
          align-items: center;
          gap: vw(8, $s);
          border: vw(1, $s) solid #D0D5DD;

          .icon-svg {
            width: vw(12, $s);
            height: vw(12, $s);
            display: flex;
            align-items: center;

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .btn-input {
        display: flex;
        gap: vw(10, $s);
        border: vw(1, $s) solid #EAECF0;
        height: vw(32, $s);
        padding: vw(0, $s) vw(15, $s);
        align-items: center;
        border-radius: vw(6, $s);
        cursor: pointer;
        white-space: nowrap;

        &.btn-primary {
          background-color: #096DD9;
          color: #fff;
          border: vw(1, $s) solid #096DD9;
        }

        &.t-px-8 {
          padding: vw(8, $s);
        }

        .icon-svg {
          display: flex;
          align-items: center;
        }
      }

      .ant-input-group-wrapper {
        height: 100%;
      }

      .ant-input-wrapper {
        height: 100%;

        .ant-input-outlined,
        .ant-btn-default {
          border: vw(1, $s) solid #F2F4F7;
          height: 100%;
        }

        .ant-input {
          border-start-end-radius: 0;
          border-end-end-radius: 0;
        }
      }

      .ant-input {
        border-radius: vw(8, $s);
        border-width: vw(1, $s);
        font-size: vw(14, $s);
        padding: vw(4, $s) vw(11, $s);
        line-height: vw(22, $s);
      }

      .ant-input-group .ant-input-group-addon {
        font-size: vw(14, $s);
        border-radius: vw(6, $s);
      }

      .ant-btn {
        height: 100%;
        width: vw(32, $s);
        border-start-end-radius: vw(8, $s) !important;
        border-end-end-radius: vw(8, $s) !important;

        .ant-btn-icon {
          color: #8a8a8b;
        }

        .anticon {
          font-size: vw(16, $s);
        }
      }
    }
  }
}

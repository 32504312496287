@import '../../styles/root.scss';
@include vwConvert() using ($s) {
  .t-pr-24 {
    padding-right: vw(24, $s);
  }

  .t-pl-24 {
    padding-left: vw(24, $s);
  }

  .border-right-EAECF0 {
    border-right: vw(1, $s) solid #EAECF0;
  }

  .title-btn-back {
    display: flex;
    gap: vw(12, $s)
  }

  .pooling-data-page {
    .list-item {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: vw(24, $s);
      column-gap: vw(18, $s);
      // margin-top: vw(24, $s);

      .one-item {
        border-radius: vw(12, $s);
        border: vw(1, $s) solid #D0D5DD;
        padding: vw(20, $s) vw(24, $s);
        display: flex;
        gap: vw(24, $s);
        min-height: vw(100, $s);

        .icon-svg {
          position: relative;
          z-index: 1;
          font-size: vw(32, $s);
          display: flex;
          align-items: flex-start;

          .label-smal {
            position: absolute;
            height: vw(20, $s);
            padding: 0 vw(8, $s);
            display: flex;
            align-items: center;
            background-color: #FF4D4F;
            color: #fff;
            font-size: vw(12, $s);
            line-height: 1;
            border-radius: 9999px;
            top: vw(-10, $s);
            right: 50%;
            transform: translateX(95%);
          }
        }

        .name-content {
          .name {
            font-family: var(--font-medium);
            font-weight: 500;
            color: #344054;
            margin-bottom: vw(8, $s);
          }

          .content {
            font-size: vw(13, $s);
            line-height: vw(17, $s);
            color: #667085;
          }
        }
      }
    }
  }

  .pooling-data-detail-page {
    .title-btn-back {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(26, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
      display: flex;
      align-items: center;
      gap: vw(16, $s);
      color: #344054;

      .icon-svg {
        color: #000000E0;
        font-size: vw(16, $s);
        display: flex;
        align-items: center;
      }
    }
    .list-data {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: vw(24, $s);
      &.empty-item {
        grid-template-columns: repeat(2, 1fr);
        .select-search {
          margin-bottom: vw(55, $s);
        }
      }

      .one-data {
        padding-bottom: vw(66, $s);
      }

      .select-search {
        display: flex;
        gap: vw(12, $s);
        margin-top: vw(12, $s);

        .ant-select-selector {
          border: none !important;
        }

        .ant-input-wrapper {
          height: 100%;
          border: none;

          input,
          button {
            height: 100%;
            border: none;
          }
        }

        .ant-input {
          color: #344054;
          font-size: vw(16, $s);
          line-height: vw(24, $s);
          font-family: var(--font-regular);
          border-radius: vw(6, $s);
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-inline-end: vw(18, $s);
          line-height: vw(30, $s);
          font-size: vw(16, $s);
          color: #344054;
          font-family: var(--font-regular);
        }
      }

      .info-block {
        display: flex;
        flex-direction: column;
        padding: vw(24, $s);

        .title {
          font-family: var(--font-bold);
          font-weight: 600;
          padding: vw(4, $s) 0;
        }

        .list-info {
          display: flex;
          flex-direction: column;

          .one-info {
            padding: vw(12, $s) 0;
            display: flex;
            justify-content: space-between;

            .right {
              color: #98A2B3;
              max-width: vw(190, $s);
              text-align: right;

              .status {
                position: relative;
                display: inline-block;
                padding-left: vw(14, $s);

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: vw(6, $s);
                  height: vw(6, $s);
                  border-radius: 50%;
                  background-color: #98A2B3;
                }

                &.type-1 {
                  &::before {
                    background-color: #FAAD14;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ctrl-btn-add-new {
      display: flex;
      align-items: center;
      .btn-add-new-block {
        gap: vw(24, $s);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .btn {
          font-size: vw(36, $s);
          width: vw(56, $s);
          height: vw(56, $s);
          border-radius: vw(12, $s);
          border: vw(1, $s) dashed #1890FF;
          color: #1890FF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
  .t-border-FCFCFD-dr-8-hei-40 {
    border: vw(1, $s) solid #EAECF0;
    border-radius: vw(8, $s);
    height: vw(40, $s) !important;
  }

  .ant-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-inline-start: vw(12, $s);
  }

  .ant-steps.ant-steps-navigation .ant-steps-item::before {
    height: vw(2, $s);
  }

  .ant-steps.ant-steps-navigation .ant-steps-item::after {
    position: absolute;
    top: calc(50% - (vw(6, $s)));
    inset-inline-start: 100%;
    display: inline-block;
    width: vw(12, $s);
    height: vw(12, $s);
    border-top: vw(1, $s) solid rgba(0, 0, 0, 0.25);
    border-bottom: none;
    border-inline-start: none;
    border-inline-end: vw(1, $s) solid rgba(0, 0, 0, 0.25);
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    content: "";
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: #1677ff !important;
      .ant-steps-icon {
        color: #1677ff !important;
      }
    }
  }

  .ant-steps.t-step-pooling-detail {
    padding-top: 0;
    .ant-steps-item-tail {
      top: vw(8, $s);
    }

    .ant-steps-item {
      &.ant-steps-item-active {
        .ant-steps-item-icon {
          border: vw(1, $s) solid #1677ff;
          .ant-steps-icon {
            color: #1677ff;
          }
        }
      }
    }

    .ant-steps-item-container {
      margin-inline-start: vw(-16, $s);
      padding-bottom: vw(12, $s);
      margin-inline-start: vw(-12, $s) !important;
    }

    .ant-steps-item-icon {
      width: vw(32, $s);
      height: vw(32, $s);
      margin-top: 0;
      margin-bottom: 0;
      margin-inline: 0 vw(8, $s);
      font-size: vw(16, $s);
      line-height: vw(32, $s);
      text-align: center;
      border-radius: vw(32, $s);
      border: vw(1, $s) solid #98A2B3;
      background-color: #fff;
    }

    .ant-steps-item-icon .ant-steps-icon {
      top: vw(-0.5, $s);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: #98A2B3;
    }

    .ant-steps-item-content {
      .ant-steps-item-title {
        padding-inline-end: vw(12, $s);
        font-size: vw(14, $s);
        line-height: vw(24, $s);
        font-family: var(--font-regular);
        color: #344054;
        margin-bottom: vw(4, $s);
      }
      .ant-steps-item-description {
        color: #98A2B3;
        font-size: vw(14, $s);
        max-width: vw(149, $s);
      }
    }
  }

  .load-data-pooling-data {
    max-width: vw(417, $s);
    margin-left: auto;
    margin-right: auto;
    margin-top: vw(48, $s);

    .img-box {
      max-width: vw(320, $s);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: vw(48, $s);
    }

    .ant-flex-gap-small {
      gap: vw(12, $s);
    }

    .one-progress {
      display: flex;
      align-items: center;
      gap: vw(12, $s);

      .text {
        flex: 0 0 auto;
        max-width: vw(175, $s);
        width: 100%;
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }

      .ant-progress {
        padding-left: vw(16, $s);
        flex: 0 0 auto;
        max-width: calc(100% - vw(175, $s));
        width: 100%;

        &.ant-progress-status-success {
          .ant-progress-bg {
            background: #52c41a;
          }
        }

        &.ant-progress-show-info .ant-progress-outer {
          margin-inline-end: calc(-2em - vw(8, $s));
          padding-inline-end: calc(2em + vw(8, $s));
        }

        .ant-progress-bg {
          background: linear-gradient(90deg, #1890ff, #dbe9f6);
          height: vw(8, $s) !important;
        }

        .ant-progress-outer {
          height: vw(8, $s) !important;
        }
      }

      .ant-progress-line {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
      }

      .ant-progress-text {
        margin-inline-start: vw(8, $s);

        .anticon {
          font-size: vw(14, $s);
        }
      }
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .order-history-card {
    border-radius: vw(12, $s);
    margin-block-start: vw(12, $s);
    box-shadow: 0 vw(2, $s) 0 0 var(--neutral-gray-100);

    &__head {
      gap: vw(8, $s);
      padding: vw(12, $s);

      .ant-input-search {
        flex-grow: 0 0 vw(400, $s);
        max-width: vw(400, $s);
      }
      .collapse-button {
        &:not(:hover) {
          border-color: transparent;
          box-shadow: none;
          color: var(--neutral-gray-500);
          background: transparent;
        }
      }
      .rs-radio,
      .setting {
        padding-inline: vw(16, $s);
      }
    }
    .rs-table {
      .ant-btn {
        height: vw(24, $s);
        width: vw(24, $s);
      }
    }
  }
}

.order-history-card {
  overflow: hidden;

  .rs-table .ant-table-container table > thead > tr:first-child > *:first-child,
  .rs-table .ant-table-container table > thead > tr:first-child > *:last-child {
    border-radius: 0;
  }
  &__head {
    display: flex;
    align-items: center;
    background: #EAECF0;

    .title,
    .rs-radio-group {
      margin-inline-end: auto;
    }
    .setting {
      border-color: transparent;
      background: transparent;
      box-shadow: none;
    }
  }
  &__body {
    &.collapsed {
      display: none;
    }
  }
}

@include vwConvert() using ($s) {
  .rs-transfer {
    .ant-transfer-list {
      gap: vw(10, $s);
      padding-block-end: vw(5, $s);
      box-shadow: 0 vw(-5, $s) 0 0 var(--primary-6) inset;
      height: vw(232, $s);
    }
    .ant-transfer-list-body {
      border-width: vw(1, $s);
      border-radius: vw(6, $s);
    }
    .ant-transfer-list-content {
      padding: vw(8, $s);
    }
    .ant-transfer-list-content-item {
      line-height: vw(22, $s);
      min-height: vw(22, $s);
      padding-inline: vw(6, $s);

      &:not(:last-child) {
        margin-block-end: vw(2, $s);
      }
    }
    .ant-transfer-operation {
      margin-inline: vw(10, $s);
      gap: vw(3, $s);

      .ant-btn {
        height: vw(36, $s);
        width: vw(36, $s);
      }
      .ant-btn-primary {
        box-shadow: 0px vw(-1, $s) 0px 0px #8BC5E24D inset;

        &:disabled,
        &.ant-btn-disabled {
          box-shadow: 0px vw(-1, $s) 0px 0px #F0F0F0 inset;
        }
      }
      .ant-btn-icon {
        height: vw(28, $s);
        width: vw(28, $s);
        border-radius: vw(4, $s);

        .anticon {
          height: vw(7.44, $s);
          width: vw(20.13, $s);
        }
      }
    }
  }
}

.rs-transfer {
  font-family: var(--font-regular);
  font-size: inherit;

  .ant-transfer-list {
    width: 100%;
    border: none;
  }
  .ant-transfer-list-header {
    padding: 0;
    height: auto;
    border: none;
  }
  .ant-transfer-list-header-selected {
    display: none;
  }
  .ant-transfer-list-header-title {
    text-align: start;
    font-family: var(--font-medium);
  }
  .ant-transfer-list-body {
    border-style: solid;
    border-color: var(--primary-6);
    overflow: hidden;
  }
  .ant-transfer-list-content-item {
    padding-block: 0;
    font-size: inherit;
  }
  .ant-transfer-list-checkbox {
    display: none;
  }
  .ant-transfer-operation {
    .ant-btn {
      border: none;

      &:first-child {
        .anticon {
          background-image: url('../../images/icons/swap-right.svg');
        }
      }
      &:last-child {
        .anticon {
          background-image: url('../../images/icons/swap-left.svg');
        }
      }
    }
    .ant-btn-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        display: none;
      }
    }
    .ant-btn-primary {
      box-shadow: 0px -1px 0px 0px #8BC5E24D inset;
      background: #8BC5E24D;

      &:hover {
        background: #8BC5E24D;
      }
      .ant-btn-icon {
        background: var(--primary-6);
      }
      &:disabled,
      &.ant-btn-disabled {
        background: transparent;

        .ant-btn-icon {
          background: var(--neutral-gray-200);
        }
      }
    }
  }
}

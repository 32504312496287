@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-mt-18 {
    margin-top: vw(18, $s) !important;
  }

  .ctrl-title-vouche.title-btn-back {
    margin-top: vw(14, $s);
    margin-bottom: vw(30, $s);
  }

  .voucher-page {
    .ant-breadcrumb{
      font-size: vw(14, $s);
      color: #98A2B3;
      line-height: vw(22, $s);
      font-family: var(--font-regular);
      margin-top: 0;
      a {
        color: #98A2B3;
        height: auto;
      }
      li:last-child {
        color: #344054;
      }
      .ant-breadcrumb-separator {
        margin-inline: vw(8, $s);
        color: #98A2B3;
      }
    }
    .title-page {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(14, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
    }

    .filter-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        gap: vw(12, $s);
        align-items: center;
        .title {
          font-size: vw(16, $s);
          color: #344054;
          line-height: vw(24, $s);
          font-weight: 500;
          font-family: var(--font-medium);
        }
      }
    }

    .line-center {
      color: #D0D5DD;
      display: flex;
      align-items: center;
    }

    .marketing-campaigns-content.voucher-content {
      margin-top: vw(16, $s);
      .overview {
        flex-direction: column;
        gap: vw(48, $s);
        .total, .chart {
          max-width: 100%;
        }
        .chart {
          display: flex;
          justify-content: center;
          >div>div {
            height: vw(276, $s) !important;
            font-size: vw(14, $s);
          }

          .t-chart-block {
            width: vw(657, $s);
          }
        }
      }

      .voucher-list-item {
        display: flex;
        margin-top: vw(16, $s);
        justify-content: space-between;
        max-width: vw(930, $s);
        width: 100%;
        .one-item {
          color: #fff;
          display: flex;
          flex-direction: column;
          gap: vw(4, $s);
          .title {
            color: #F9FAFB;
          }
          .number {
            color: #fff;
            font-size: vw(24, $s);
            line-height: vw(32, $s);
            font-family: var(--font-medium);
          }
          .rate-block {
            display: flex;
            gap: vw(4, $s);
            align-items: center;
            .icon-svg {
              font-size: vw(14, $s);
              width: vw(14, $s);
              height: vw(14, $s);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .table-voucher {
        .ant-table-wrapper {
          table tr th:nth-child(1) {
            width: vw(93, $s);
          }

          table tr th:nth-child(2) {
            width: vw(143, $s);
          }

          table tr th:nth-child(3) {
            width: vw(159, $s);
          }

          table tr th:nth-child(4) {
            width: vw(159, $s);
          }

          table tr th:nth-child(5) {
            width: vw(159, $s);
          }

          table tr th:nth-child(6) {
            width: vw(159, $s);
          }

          table tr th:nth-child(7) {
            width: vw(159, $s);
          }

          table tr th:nth-child(8) {
            width: vw(159, $s);
          }
        }
      }

      .t-table-vouche-detail {
        .ant-table-wrapper {
          table tr th:nth-child(1) {
            width: vw(60, $s);
          }

          table tr th:nth-child(2) {
            width: vw(93, $s);
          }

          table tr th:nth-child(3) {
            width: vw(99, $s);
          }

          table tr th:nth-child(4) {
            width: vw(188, $s);
          }

          table tr th:nth-child(5) {
            width: vw(188, $s);
          }

          table tr th:nth-child(6) {
            width: vw(188, $s);
          }

          table tr th:nth-child(7) {
            width: vw(188, $s);
          }

          table tr th:nth-child(8) {
            width: vw(188, $s);
          }
        }
      }
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-cl-F5222D {
    color: #F5222D;
  }

  .accounts-management-list {
    .ant-breadcrumb {
      font-size: vw(14, $s);
      color: #98A2B3;
      line-height: vw(22, $s);
      font-family: var(--font-regular);
      margin-top: 0;

      a {
        color: #98A2B3;
        height: auto;
      }

      li:last-child {
        color: #344054;
      }

      .ant-breadcrumb-separator {
        margin-inline: vw(8, $s);
        color: #98A2B3;
      }
    }

    .title-page {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(14, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
    }

    .t-search-block {
      flex-grow: 1;
      max-width: 100%;
      width: auto;
      height: vw(32, $s);

      input {
        background-color: #FCFCFD;
      }

      .ant-input-wrapper .ant-input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .ant-btn {
        height: vw(32, $s);
        width: vw(32, $s);
        border-start-end-radius: vw(8, $s) !important;
        border-end-end-radius: vw(8, $s) !important;

        .ant-btn-icon {
          color: #8a8a8b;
        }

        .anticon {
          font-size: vw(16, $s);
        }
      }
    }

    .mw-100 {
      max-width: 100% !important;
    }

    .filter-block {
      display: flex;
      gap: vw(12, $s);
      margin-bottom: vw(18, $s);

      &.active {
        display: none;
      }

      &.filter-active-show {
        display: none;

        &.active {
          display: flex;
        }

        .total-chosse {
          display: flex;
          align-items: center;
        }

        .btn-input {
          display: flex;
          align-items: center;
          gap: vw(8, $s);
          border: vw(1, $s) solid #EAECF0;

          .icon-svg {
            width: vw(12, $s);
            height: vw(12, $s);
            display: flex;
            align-items: center;

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .t-search-block {
        input::placeholder {
          color: #667085;
        }
      }

      .btn-primary-auto {
        display: flex;
        gap: vw(10, $s);
        border: vw(1, $s) solid #EAECF0;
        height: vw(32, $s);
        padding: vw(0, $s) vw(15, $s);
        align-items: center;
        border-radius: vw(6, $s);
        cursor: pointer;
        white-space: nowrap;
        flex: 0 0 auto;
        background-color: #096DD9;
        color: #fff;
        border: vw(1, $s) solid #096DD9;
      }

      .btn-input {
        display: flex;
        gap: vw(10, $s);
        border: vw(1, $s) solid #EAECF0;
        height: vw(32, $s);
        padding: vw(0, $s) vw(15, $s);
        align-items: center;
        border-radius: vw(6, $s);
        cursor: pointer;
        white-space: nowrap;
        flex: 0 0 auto;
        width: 100%;
        max-width: vw(106, $s);

        &.btn-primary {
          background-color: #096DD9;
          color: #fff;
          border: vw(1, $s) solid #096DD9;
          max-width: vw(193, $s);
          display: flex;
          justify-content: center;
        }

        &.t-px-8 {
          padding: vw(8, $s);
        }

        &.btn-input2 {
          max-width: vw(111, $s);
        }
      }

      .list-add-filter-content .one-add-filter-content .text {
        padding-left: 0;
      }

      .ant-input-group-wrapper {
        height: 100%;
      }

      .ant-input-wrapper {
        height: 100%;

        .ant-input-outlined,
        .ant-btn-default {
          border: vw(1, $s) solid #F2F4F7;
          height: 100%;
        }

        .ant-input {
          border-start-end-radius: 0;
          border-end-end-radius: 0;
        }
      }

      .ant-input {
        border-radius: vw(8, $s);
        border-width: vw(1, $s);
        font-size: vw(14, $s);
        padding: vw(4, $s) vw(11, $s);
        line-height: vw(22, $s);
      }

      .ant-input-group .ant-input-group-addon {
        font-size: vw(14, $s);
        border-radius: vw(6, $s);
      }

      .ant-btn {
        height: 100%;
        width: vw(32, $s);
        border-start-end-radius: vw(8, $s) !important;
        border-end-end-radius: vw(8, $s) !important;

        .ant-btn-icon {
          color: #8a8a8b;
        }

        .anticon {
          font-size: vw(16, $s);
        }
      }

      .filter-select {
        max-width: vw(158, $s);
        width: 100%;
        height: vw(32, $s);
        position: relative;

        &.filter-select2 {
          max-width: vw(97, $s);

          .list-filter {
            left: 0;
          }
        }

        .btn-select-show {
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #F2F4F7;
          padding: vw(4, $s) vw(11, $s);
          height: vw(32, $s);
          cursor: pointer;

          span {
            color: #344054;
            display: block;
          }
        }

        .icon-down,
        .icon-close {
          font-size: vw(10, $s);
          color: #8a8a8b;
          position: absolute;
          top: 50%;
          right: vw(11, $s);
          transform: translateY(-50%);
        }

        .icon-down {
          &.active {
            display: none;
          }
        }

        .icon-close {
          display: none;

          &.active {
            display: block;
          }

          span {
            color: #ff0000;
          }
        }

        .list-filter {
          position: absolute;
          top: 100%;
          z-index: 2;
          border-radius: vw(6, $s);
          box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
          min-width: vw(160, $s);
          margin-top: vw(8, $s);
          display: none;
          background-color: #fff;
          right: 0;

          &.active {
            display: block;
          }

          input {
            margin-bottom: vw(8, $s);
          }

          .list-item {
            max-height: vw(320, $s);
            overflow-y: auto;
          }

          .ant-input,
          .item-select {
            border-radius: vw(2, $s);
            padding: vw(5, $s) vw(12, $s);
          }

          .item-select {
            margin-bottom: vw(8, $s);
            display: flex;
            color: #667085;
            gap: vw(8, $s);
            align-items: center;
            justify-content: space-between;
            padding: vw(5, $s) vw(12, $s);

            .icon-left {
              font-size: vw(12, $s);
              display: flex;
              gap: vw(8, $s);
            }

            .icon-right {
              font-size: vw(12, $s);
              color: #FF7875;
            }
          }

          .btn-filter {
            padding: vw(8, $s);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btn {
              border-radius: vw(4, $s);
              padding: 0 vw(7, $s);
              height: vw(22, $s);
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              color: #00000040;
              max-width: vw(72, $s);

              &.text-btn-filter {
                background-color: #096DD9;
                color: #fff;
                gap: vw(10, $s);
              }
            }
          }
        }
      }
    }

    .show-access-filter {
      max-width: vw(400, $s);
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      background-color: #fff;
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      display: none;

      &.active {
        display: block;
      }

      .title-access {
        display: flex;
        justify-content: space-between;
        padding: vw(16, $s) vw(24, $s);
        font-size: vw(16, $s);
        font-weight: 500;
        font-family: var(--font-medium);
        border-bottom: vw(1, $s) solid #F0F0F0;
        height: vw(56, $s);
        align-items: center;

        .icon-close {
          color: #00000073;
          font-size: vw(16, $s);
          display: flex;
          align-items: center;
        }
      }

      .input-add-name {
        padding: vw(24, $s);
        border-bottom: vw(1, $s) solid #F0F0F0;
        height: vw(80, $s);

        .ant-input {
          border-radius: vw(8, $s);
          border-width: vw(1, $s);
          font-size: vw(14, $s);
          padding: vw(4, $s) vw(11, $s);
          line-height: vw(22, $s);
          font-family: var(--font-regular);
          border-color: #F2F4F7;

          &::placeholder {
            color: #344054;
          }
        }
      }

      .btn-footer-access {
        padding: vw(8, $s) vw(24, $s);
        display: flex;
        justify-content: flex-end;

        .btn {
          height: vw(32, $s);
          width: vw(55, $s);
          background-color: #096DD9;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: vw(8, $s);
          cursor: pointer;
        }
      }
    }

    .text-area-block {
      textarea {
        height: vw(54, $s) !important;
        min-height: vw(54, $s) !important;
        max-height: vw(142, $s) !important;
        font-family: var(--font-regular);
        font-weight: 400 !important;

        &::placeholder {
          color: #98A2B3 !important;
        }
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      line-height: vw(30, $s);
      padding-inline-end: vw(18, $s);
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(30, $s);
      font-size: vw(14, $s);
    }

    .ant-select-single .ant-select-selector {
      font-size: vw(14, $s);
    }

    input {
      padding-block: vw(1, $s);
      padding-inline: vw(2, $s);
      font-size: vw(14, $s);
    }

    .list-add-filter-content {
      padding: vw(4, $s);
      border-bottom: vw(1, $s) solid #0000000F;

      .ant-checkbox-group {
        display: flex;
        flex-direction: column;

        .ant-checkbox-wrapper {
          gap: vw(8, $s);
          display: flex;
          align-items: center;

          .text {
            white-space: nowrap;
            line-height: 1;
          }
        }
      }

      .ant-checkbox+span {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }

      .ant-checkbox-wrapper {
        font-size: vw(14, $s) !important;
        padding: vw(8, $s) vw(12, $s);
        border-radius: vw(8, $s);
        color: #667085;
        font-family: var(--font-regular);
        align-items: center;

        &:after {
          display: none;
        }
      }

      .ant-checkbox {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        &.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1890FF;
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: none;
          border-radius: vw(2, $s);
          background-color: #f1f5f9;

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }
    }

    .group-customers-block {
      max-width: vw(400, $s);
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      background-color: #fff;
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      color: #344054;

      .title-access {
        display: flex;
        justify-content: space-between;
        padding: vw(16, $s) vw(24, $s);
        font-size: vw(16, $s);
        font-weight: 500;
        font-family: var(--font-medium);
        border-bottom: vw(1, $s) solid #F0F0F0;
        height: vw(56, $s);
        align-items: center;

        .icon-close {
          color: #00000073;
          font-size: vw(16, $s);
          display: flex;
          align-items: center;
        }
      }

      .title-content {
        font-family: var(--font-bold);
      }

      .modal-content {
        padding: vw(24, $s);
        border-bottom: vw(1, $s) solid #F0F0F0;
        display: flex;
        flex-direction: column;
        gap: vw(18, $s);

        .ant-input {
          border-radius: vw(8, $s);
          border-width: vw(1, $s);
          font-size: vw(14, $s);
          padding: vw(4, $s) vw(11, $s);
          line-height: vw(22, $s);
          font-family: var(--font-regular);
          border-color: #F2F4F7;

          &::placeholder {
            color: #344054;
          }
        }
      }

      .btn-footer-access {
        padding: vw(8, $s) vw(24, $s);
        display: flex;
        justify-content: flex-end;

        &.two-btn {
          justify-content: space-between;
          align-items: center;

          .btn-df {
            padding: 0 vw(7, $s);
            color: #98A2B3;
            cursor: pointer;
          }
        }

        .btn {
          height: vw(32, $s);
          width: vw(55, $s);
          background-color: #096DD9;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: vw(8, $s);
          cursor: pointer;
        }
      }

      .link-block {
        display: flex;
        gap: vw(10, $s);
        color: #1890FF;
        padding: vw(0, $s) vw(7, $s);
        color: #1890FF;
      }

      .list-tab {
        display: flex;

        .tab-left,
        .tab-right {
          flex: 0 0 auto;
          display: flex;
          gap: vw(4, $s);
          border: vw(1, $s) solid #D9D9D9;
          padding: vw(5, $s) vw(16, $s);
          align-items: center;
          cursor: pointer;

          .icon-svg {
            font-size: vw(12, $s);
          }

          &.active {
            border: vw(1, $s) solid #1890FF;
            color: #1890FF;
          }
        }

        .tab-left {
          border-radius: vw(6, $s) 0 0 vw(6, $s);
          border-right: none;
        }

        .tab-right {
          border-radius: 0 vw(6, $s) vw(6, $s) 0;
          border-left: none;
        }
      }

      .t-search-block {
        input::placeholder {
          color: #98A2B3 !important;
        }

        .ant-btn-default {
          border-color: #F2F4F7;
        }
      }

      .input-add-name {
        input::placeholder {
          color: #98A2B3 !important;
        }
      }

      .list-tags {
        display: flex;
        gap: 8px;
        margin-top: vw(12, $s);

        .one-tag {
          display: flex;
          gap: vw(3, $s);
          align-items: center;
          padding: 0 vw(8, $s);
          border-radius: 9999px;
          border: vw(1, $s) solid #91D5FF;
          background-color: #E6F7FF;
          color: #1890FF;
          font-size: vw(12, $s);
          min-height: vw(22, $s);

          &:nth-child(2) {
            border-color: #FFE58F;
            background-color: #FFFBE6;
            color: #FAAD14;
          }

          .icon-close {
            font-size: vw(10, $s);
            color: #00000073;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .t-ctrl-popup-select {
    &.ant-select-dropdown {
      padding: vw(4, $s);

      .ant-select-item {
        min-height: vw(32, $s);
        padding: vw(4, $s);
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
      }

      .rc-virtual-list-holder {
        max-height: vw(256, $s) !important;
      }
    }
  }

  .filter-setting {
    position: fixed;
    width: vw(400, $s);
    height: 100vh;
    z-index: 98;
    box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
    top: 0;
    right: 0;
    background-color: #fff;

    .ant-checkbox+span {
      padding-inline-start: vw(0, $s);
      padding-inline-end: vw(0, $s);

      .text {
        padding-left: 0;
      }
    }

    .title-add-filter {
      padding: vw(12, $s) vw(24, $s);
      border-bottom: vw(1, $s) solid #f0f0f0;
      font-size: vw(16, $s);
      font-weight: 500;
      line-height: vw(22, $s);
      color: #344054;
      font-family: var(--font-medium);
      min-height: vw(48, $s);
    }

    .content-filter-setting {
      padding: vw(24, $s);
      height: calc(100vh - 6.6666666667vw);

      .title-content {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
        color: #344054;
        font-weight: 500;
        font-family: var(--font-medium);
        margin-bottom: vw(18, $s);
      }
    }

    .footer-filter-block {
      .btn-block {
        height: vw(48, $s);
        padding: vw(8, $s) vw(24, $s);
        border-top: vw(1, $s) solid #f0f0f0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: vw(17, $s);

        .btn {
          display: flex;
          gap: vw(8, $s);
          align-items: center;
          justify-content: center;
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #EAECF0;
          height: vw(32, $s);
          color: #000000D9;
          line-height: vw(22, $s);
          cursor: pointer;

          img {
            width: vw(14, $s);
            height: vw(14, $s);
            object-fit: contain;
          }

          &.btn-primary {
            color: #fff;
            background-color: #096DD9;
          }
        }
      }
    }
  }

  .ant-message {
    &.ant-message-top {
      top: vw(8, $s) !important;

      .ant-message-notice-wrapper {
        padding: vw(8, $s);

        .t-message {
          font-size: vw(14, $s);

          .ant-message-notice-content {
            padding: vw(9, $s) vw(12, $s);
            border-radius: vw(9, $s);
            box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
          }

          .ant-message-custom-content>.anticon {
            margin-inline-end: vw(8, $s);
            font-size: vw(16, $s);
          }
        }
      }
    }
  }

  .t-align-center {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }

  .t-op-40 {
    opacity: 0.4;
    pointer-events: none;
  }

  .t-table-block {
    td.ant-table-cell.ant-table-cell-with-append{
      display: flex;
    }
    .t-hd-table {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: vw(10, $s);
      &.v2{
        justify-content: flex-start;
      }

      .item-hd-table {
        display: flex;
        width: vw(24, $s);
        height: vw(24, $s);
        border-radius: vw(8, $s);
        border: vw(1, $s) solid #D9D9D9;
        align-items: center;
        justify-content: center;
        box-shadow: 0 vw(2, $s) 0 0 #00000005;
      }

    }

    .ctrl-title-ht {
      display: flex;
      gap: vw(10, $s);
      align-items: center;

      .img-box {
        display: flex;
        align-items: center;
        width: vw(24, $s);
        height: vw(24, $s);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .t-table-block.t-table-accounts-management {
    .ant-table-wrapper {
      .ant-table-column-sorter-inner {

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          color: white;
        }
      }

      .ant-table-filter-trigger {
        color: white;
      }

      table tr th:nth-child(1) {
        width: vw(36, $s);
      }

      table tr th:nth-child(2) {
        width: vw(150, $s);
      }

      table tr th:nth-child(3) {
        width: vw(60, $s);
      }

      table tr th:nth-child(4) {
        width: vw(142.4, $s);
      }

      table tr th:nth-child(5) {
        width: vw(117, $s);
      }

      table tr th:nth-child(6) {
        width: vw(117, $s);
      }

      table tr th:nth-child(7) {
        width: vw(142.4, $s);
      }

      table tr th:nth-child(8) {
        width: vw(142.4, $s);
      }

      table tr th:nth-child(9) {
        width: vw(142.4, $s);
      }

      table tr th:nth-child(10) {
        width: vw(142.4, $s);
      }
    }
  }



}

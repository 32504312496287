@import './_fonts';
@import './_color';
@import './_variables';
@import './_rs-ant';
@import './_rs-slick';
@import './_rs-recharts';
@import './_search-auto';
@import './_text-limit';
@import './_tags-custom';
@import './ARCCard';

@include vwConvert() using ($s) {
  ::-webkit-scrollbar {
    width: vw(5, $s);
    height: vw(5, $s);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: vw(99, $s);
  }
  .fsz {
    &-12 {
      font-size: vw(12, $s);
    }
    &-16 {
      font-size: vw(16, $s);
    }
    &-20 {
      font-size: vw(20, $s);
    }
    &-24 {
      font-size: vw(24, $s);
    }
  }
  .lh {
    &-20 {
      line-height: vw(20, $s);
    }
    &-24 {
      line-height: vw(24, $s);
    }
  }
  .gap {
    &-4px {
      gap: vw(4, $s);
    }
    &-8px {
      gap: vw(8, $s);
    }
    &-10px {
      gap: vw(8, $s);
    }
    &-12px {
      gap: vw(12, $s);
    }
    &-16px {
      gap: vw(16, $s);
    }
    &-18px {
      gap: vw(18, $s);
    }
    &-24px {
      gap: vw(24, $s);
    }
  }
  .maw {
    &-170 {
      max-width: vw(170, $s);
    }
  }
  .h{
    &-24px {
      height: vw(24, $s);
    }
    &-64px {
      height: vw(64, $s);
    }
  }
  .w{
    &-24px {
      width: vw(24, $s);
    }
  }
}

::-webkit-scrollbar {
  border-radius: var(--scrollbar-radius, 0);
  background: var(--scrollbar-bg, var(--neutral-gray-100));
}
::-webkit-scrollbar-thumb {
  // background: var(--scrollbar-thumb-bg, #005DB2);
  background: var(--scrollbar-thumb-bg, var(--neutral-gray-300));
  transition: none !important;
}
::-webkit-scrollbar-thumb:hover{
  // background: var(--scrollbar-thumb-hover-bg, #005DB2);
  background: var(--scrollbar-thumb-hover-bg, #005DB2);
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;

  &-vertical {
    flex-direction: column;
  }
  &-inline {
    display: inline-flex;
  }
}
.align {
  &-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-end {
    align-items: flex-end;
  }
}
.text {
  &-start {
    text-align: start;
  }
  &-center {
    text-align: center;
  }
  &-end {
    text-align: end;
  }
  &-nowrap {
    white-space: nowrap;
  }
}
.d-none{
  display: none !important;
}
.mis {
  &-auto {
    margin-inline-start: auto;
  }
}
.mie {
  &-auto {
    margin-inline-end: auto;
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .user-card-popup__content {
    .rank-detail {
      gap: vw(4, $s);
      font-size: vw(12, $s);

      &-block {
        gap: vw(24, $s);
        display: flex;
        align-items: center;
      }
      &-label {
        font-size: vw(14, $s);     
      }
      &-progress {
        padding-block: vw(16, $s);
        gap: vw(8, $s);

        span {
          white-space: nowrap;
        }

        .ant-progress {
          width: vw(200, $s);
        }
      }
      img {
        height: vw(24, $s);
      }
    }
    .benefit {
      &-block__title {
        font-size: vw(16, $s);
        padding-block: vw(12, $s);
      }
      &-card {
        padding: vw(16, $s) vw(12, $s);
        gap: vw(12, $s);
        border-radius: vw(12, $s);
        border: vw(1, $s) solid #F2F4F7;

        .item {
          gap: vw(8, $s);

          .anticon {
            font-size: vw(10, $s);
          }
        }
        .img-brand {
          height: vw(120, $s);
          width: vw(180, $s);
          inset: vw(29, $s) vw(-20, $s) vw(29, $s) auto;
        }
      }
    }
    .t-message {
      display: flex;
      align-items: center;
      gap: vw(8, $s);
      justify-content: center;
      margin-top: vw(12, $s);
      span {
        width: vw(6, $s);
        height: vw(6, $s);
        border-radius: 50%;
        background-color: #FAAD14;
      }
      .text {
        font-size: vw(16, $s);
        color: #98A2B3;
        line-height: vw(24, $s);
      }
    }
    .avata-block {
      width: vw(64, $s);
      height: vw(64, $s);
      border-radius: 50%;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .name-detail-rank {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .rank {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
        gap: vw(8, $s);
        font-family: var(--font-medium);
        line-height: vw(24, $s);
        .img-box {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: vw(32, $s);
          height: vw(32, $s);
          flex: 0 0 auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.user-card-popup__content {
  .rank-detail {
    display: flex;
    align-items: center;

    &-progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-progress {
      display: flex;
    }
    img {
      aspect-ratio: 1;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
  .benefit {
    &-card {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      // box-shadow: 0px 0px 0px 1px #FFFFFF;
      // background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);

      // &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: -2;
        pointer-events: none;
      }
      // &::before {
      //   inset: 0;
      //   background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
      // }
      &::after {
        inset: 0;
        background: transparent;
      }
      .item {
        display: flex;
        align-items: baseline;
        
        .anticon {
          color: var(--sunset-orange-6);
        }
      }
      .img-brand {
        position: absolute;
        pointer-events: none;
        z-index: -1;
      }
    }
  }
}
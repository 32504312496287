@include vwConvert() using ($s) {
  .ant-input{
    font-family: unset;
  }
  .rs-input {
    height: vw(40, $s);
    border-radius: vw(8, $s);
    padding: vw(8, $s) vw(12, $s);
    font-size: vw(16, $s);
    line-height: vw(24, $s);
    &--textarea {
      padding: vw(12, $s) vw(16, $s);

      &.ant-input {
        min-height: vw(192, $s);
      }
      &-default {
        &.ant-input {
          min-height: vw(60, $s);
          padding-block: vw(4, $s);
        }
      }
    }
    &--32 {
      height: vw(32, $s);
      padding-block: vw(5, $s);
      font-size: vw(14, $s);
      line-height: vw(22, $s);
    }
    &.ant-input-outlined {
      border-width: vw(1, $s);
    }

    // INPUT GROUP
    &-group {
      .ant-input {
        height: vw(40, $s);
        border-radius: vw(8, $s);
        padding: vw(8, $s) vw(12, $s);
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }
      &--32 {
        .ant-input {
          height: vw(32, $s);
          padding-block: vw(5, $s);
          font-size: vw(14, $s);
          line-height: vw(22, $s);
        }
      }
      .ant-input-group-addon {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        padding-inline: vw(12, $s);

        .ant-select {
          margin: 0 vw(-12, $s);

          .ant-select-arrow {
            inset-inline-end: vw(12, $s);
          }
        }
      }
    }

    // INPUT SEARCH
    &-search {
      >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button {
        border-start-end-radius: vw(6, $s);
        border-end-end-radius: vw(6, $s);
        margin-inline-end: vw(-1, $s);
      }
      .ant-input-group {
        font-size: vw(14, $s);

        .ant-input-group-addon {
          font-size: vw(14, $s);
        }
        .ant-input-affix-wrapper {
          padding: vw(4, $s) vw(12, $s);
          font-size: vw(14, $s);

          &:not(:last-child) {
            border-start-start-radius: vw(6, $s);
            border-end-start-radius: vw(6, $s);
          }
        }
      }
      .ant-input-search-button {
        height: vw(32, $s);
        width: vw(32, $s);

        .anticon {
          font-size: vw(14, $s);
        }
        &:not(:hover) {
          border-inline-start-color: rgba(0,0,0,0);
        }
      }
      .ant-input-outlined {
        border-width: vw(1, $s);
      }
      .ant-input-clear-icon {
        font-size: vw(12, $s);
        vertical-align: vw(-1, $s);
      }
    }

    // INPUT NUMBER
    &-number {
      .ant-input-number-group {
        .ant-input-number-group-addon {
          font-size: vw(14, $s);
          line-height: vw(22, $s);
          padding-block: vw(4, $s);
          padding-inline: vw(12, $s);
          border-width: vw(1, $s);
          border-radius: vw(6, $s);
        }
      }
      .ant-input-number {
        border-radius: vw(6, $s);
        border-width: vw(1, $s);

        &:hover {
          border-inline-end-width: vw(1, $s);
        }
      }
      .ant-input-number-input {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        border-radius: vw(6, $s);
        padding-block: vw(4, $s);
        padding-inline: vw(12, $s);
      }
      .ant-input-number-handler {
        border-inline-start-width: vw(1, $s);

        &-up {
          border-start-end-radius: vw( 6, $s);
        }
        &-down {
          border-end-end-radius: vw(6, $s);
          border-block-start-width: vw(1, $s);
        }
        &-wrap {
          width: vw(22, $s);
          border-start-end-radius: vw(6, $s);
          border-end-end-radius: vw(6, $s);

          .ant-input-number-handler .ant-input-number-handler-up-inner,
          .ant-input-number-handler .ant-input-number-handler-down-inner {
            font-size: vw(7, $s);
          }
        }
      }
    }
  }
}

.rs-input {
  font-family: inherit;
  color: inherit;
  background: #FCFCFD;

  &::placeholder {
    font-family: var(--font-regular);
    color: var(--neutral-gray-400);
  }
  // &--textarea {

  // }
  &.ant-input-outlined {
    border-color: var(--neutral-gray-100);

    &:focus-within {
      box-shadow: none;
    }
  }

  &-group {
    color: inherit;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;

    .ant-input-group {
      color: inherit;
      font-family: inherit;
      line-height: inherit;
      font-size: inherit;
    }
    .ant-input-group-addon {
      font-size: inherit;
      border-color: var(--neutral-gray-100);
    }
    .ant-input-outlined {
      border-color: var(--neutral-gray-100);
    }
  }

  // INPUT SEARCH
  &-search {
    .ant-input-group {
      font-family: var(--font-regular);

      >.ant-input-group-addon:last-child {
        inset-inline-start: 0;
      }
    }
    .ant-input-search-button {
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }

  // INPUT NUMBER
  &-number {
    .ant-input-number-group >.ant-input-number:last-child,
    .ant-input-number-group .ant-input-number-group-addon:last-child {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }
  }
}

@import './root.scss';

@include vwConvert() using ($s) {

  .shadow-btn-zns {
    box-shadow: 0px vw(9, $s) vw(24, $s) vw(4, $s) rgba(0, 0, 0, 0.05);

  }

  .ant-breadcrumb {
    font-size: vw(14, $s);
    color: #98A2B3;
    line-height: vw(22, $s);
    font-family: var(--font-regular);

    a {
      color: #98A2B3;
      height: auto;
    }

    li:last-child {
      color: #344054;
    }

    .ant-breadcrumb-separator {
      margin-inline: vw(8, $s);
      color: #98A2B3;
    }
  }

  .title-page {
    font-size: vw(20, $s);
    color: #000;
    line-height: vw(28, $s);
    margin-top: vw(14, $s);
    font-weight: 500;
    margin-bottom: vw(24, $s);
    font-family: var(--font-medium);

    &.title-btn-back {
      .icon-svg {
        display: flex;
        align-items: center;
      }
    }
  }

  .shadow-btn-save {
    box-shadow: 0px vw(2, $s) 0px 0px rgba(0, 0, 0, 0.02);
  }
  .shadow-btn-permission {
    box-shadow: 0px vw(2, $s) vw(10,$s) 0px rgba(0, 0, 0, 0.1);
  }
  .p {
    &-1 {
      padding: vw(4, $s);
    }

    &-2 {
      padding: vw(8, $s);
    }

    &-3 {
      padding: vw(12, $s);
    }

    &-4 {
      padding: vw(16, $s);
    }

    &-5 {
      padding: vw(20, $s);
    }

    &-6 {
      padding: vw(24, $s);
    }

    &-7 {
      padding: vw(28, $s);
    }

    &-8 {
      padding: vw(32, $s);
    }

    &-9 {
      padding: vw(36, $s);
    }

    &-10 {
      padding: vw(40, $s);
    }

    &-5-px {
      padding: vw(5, $s);
    }

    &-7-px {
      padding: vw(7, $s);
    }
  }

  .pr {
    &-1 {
      padding-right: vw(4, $s);
    }

    &-2 {
      padding-right: vw(8, $s);
    }

    &-3 {
      padding-right: vw(12, $s);
    }

    &-4 {
      padding-right: vw(16, $s);
    }

    &-5 {
      padding-right: vw(20, $s);
    }

    &-6 {
      padding-right: vw(24, $s);
    }

    &-7 {
      padding-right: vw(28, $s);
    }

    &-8 {
      padding-right: vw(32, $s);
    }

    &-9 {
      padding-right: vw(36, $s);
    }

    &-10 {
      padding-right: vw(40, $s);
    }

    &-5-px {
      padding-right: vw(5, $s);
    }

    &-6-px {
      padding-right: vw(6, $s);
    }

    &-15-px {
      padding-right: vw(15, $s);
    }
    &-28-px {
      padding-right: vw(28.8, $s);
    }
  }



  .px {
    &-1 {
      padding-left: vw(4, $s);
      padding-right: vw(4, $s);
    }

    &-2 {
      padding-left: vw(8, $s);
      padding-right: vw(8, $s);
    }

    &-3 {
      padding-left: vw(12, $s);
      padding-right: vw(12, $s);
    }

    &-4 {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &-5 {
      padding-left: vw(20, $s);
      padding-right: vw(20, $s);
    }

    &-6 {
      padding-left: vw(24, $s);
      padding-right: vw(24, $s);
    }

    &-7 {
      padding-left: vw(28, $s);
      padding-right: vw(28, $s);
    }

    &-8 {
      padding-left: vw(32, $s);
      padding-right: vw(32, $s);
    }

    &-9 {
      padding-left: vw(36, $s);
      padding-right: vw(36, $s);
    }

    &-10 {
      padding-left: vw(40, $s);
      padding-right: vw(40, $s);
    }

    &-5-px {
      padding-left: vw(5, $s);
      padding-right: vw(5, $s);
    }

    &-9-px {
      padding-left: vw(9, $s);
      padding-right: vw(9, $s);
    }

    &-10-px {
      padding-left: vw(10, $s);
      padding-right: vw(10, $s);
    }

    &-15-px {
      padding-left: vw(15, $s);
      padding-right: vw(15, $s);
    }
  }

  .py {
    &-1 {
      padding-top: vw(4, $s);
      padding-bottom: vw(4, $s);
    }

    &-2 {
      padding-top: vw(8, $s);
      padding-bottom: vw(8, $s);
    }

    &-3 {
      padding-top: vw(12, $s);
      padding-bottom: vw(12, $s);
    }

    &-4 {
      padding-top: vw(16, $s);
      padding-bottom: vw(16, $s);
    }

    &-5 {
      padding-top: vw(20, $s);
      padding-bottom: vw(20, $s);
    }

    &-6 {
      padding-top: vw(24, $s);
      padding-bottom: vw(24, $s);
    }

    &-7 {
      padding-top: vw(28, $s);
      padding-bottom: vw(28, $s);
    }

    &-8 {
      padding-top: vw(32, $s);
      padding-bottom: vw(32, $s);
    }

    &-9 {
      padding-top: vw(36, $s);
      padding-bottom: vw(36, $s);
    }

    &-10 {
      padding-top: vw(40, $s);
      padding-bottom: vw(40, $s);
    }

    &-2-px {
      padding-top: vw(2, $s);
      padding-bottom: vw(2, $s);
    }
    &-10-px {
      padding-top: vw(10, $s);
      padding-bottom: vw(10, $s);
    }
    &-13-px {
      padding-top: vw(13, $s);
      padding-bottom: vw(13, $s);
    }

    &-6-px {
      padding-top: vw(6, $s);
      padding-bottom: vw(6, $s);
    }

    &-18-px {
      padding-top: vw(18, $s);
      padding-bottom: vw(18, $s);
    }

    &-26-px {
      padding-top: vw(26, $s);
      padding-bottom: vw(26, $s);
    }
  }

  .pt {
    &-1 {
      padding-top: vw(4, $s);
    }

    &-2 {
      padding-top: vw(8, $s);
    }

    &-3 {
      padding-top: vw(12, $s);
    }

    &-4 {
      padding-top: vw(16, $s);
    }

    &-5 {
      padding-top: vw(20, $s);
    }

    &-6 {
      padding-top: vw(24, $s);
    }

    &-7 {
      padding-top: vw(28, $s);
    }

    &-8 {
      padding-top: vw(32, $s);
    }

    &-9 {
      padding-top: vw(36, $s);
    }

    &-10 {
      padding-top: vw(40, $s);
    }

    &-20 {
      padding-top: vw(80, $s);
    }

    &-11 {
      padding-top: vw(44, $s);
    }

    &-29-px {
      padding-top: vw(29, $s);
    }
  }

  .pb {
    &-1 {
      padding-bottom: vw(4, $s);
    }

    &-2 {
      padding-bottom: vw(8, $s);
    }

    &-3 {
      padding-bottom: vw(12, $s);
    }

    &-4 {
      padding-bottom: vw(16, $s);
    }

    &-5 {
      padding-bottom: vw(20, $s);
    }

    &-6 {
      padding-bottom: vw(24, $s);
    }

    &-7 {
      padding-bottom: vw(28, $s);
    }

    &-8 {
      padding-bottom: vw(32, $s);
    }

    &-9 {
      padding-bottom: vw(36, $s);
    }

    &-10 {
      padding-bottom: vw(40, $s);
    }

    &-18-px {
      padding-bottom: vw(18, $s);
    }
  }

  .mb {
    &-1 {
      margin-bottom: vw(4, $s);
    }

    &-2 {
      margin-bottom: vw(8, $s);
    }

    &-3 {
      margin-bottom: vw(12, $s);
    }

    &-4 {
      margin-bottom: vw(16, $s);
    }

    &-5 {
      margin-bottom: vw(20, $s);
    }

    &-6 {
      margin-bottom: vw(24, $s);
    }

    &-7 {
      margin-bottom: vw(28, $s);
    }

    &-8 {
      margin-bottom: vw(32, $s);
    }

    &-9 {
      margin-bottom: vw(36, $s);
    }

    &-10 {
      margin-bottom: vw(40, $s);
    }

  }

  .mr {
    &-1 {
      margin-right: vw(4, $s);
    }

    &-2 {
      margin-right: vw(8, $s);
    }

    &-3 {
      margin-right: vw(12, $s);
    }

    &-4 {
      margin-right: vw(16, $s);
    }

    &-5 {
      margin-right: vw(20, $s);
    }

    &-6 {
      margin-right: vw(24, $s);
    }

    &-7 {
      margin-right: vw(28, $s);
    }

    &-8 {
      margin-right: vw(32, $s);
    }

    &-9 {
      margin-right: vw(36, $s);
    }

    &-10 {
      margin-right: vw(40, $s);
    }

  }

  .pl {
    &-none {
      padding-left: none;
    }

    &-1 {
      padding-left: vw(4, $s);
    }

    &-2 {
      padding-left: vw(8, $s);
    }

    &-3 {
      padding-left: vw(12, $s);
    }

    &-4 {
      padding-left: vw(16, $s);
    }

    &-5 {
      padding-left: vw(20, $s);
    }

    &-6 {
      padding-left: vw(24, $s);
    }

    &-7 {
      padding-left: vw(28, $s);
    }

    &-8 {
      padding-left: vw(32, $s);
    }

    &-9 {
      padding-left: vw(36, $s);
    }

    &-10 {
      padding-left: vw(40, $s);
    }

    &-13 {
      padding-left: vw(52, $s);
    }

    &-15 {
      padding-left: vw(60, $s);
    }

    &-20 {
      padding-left: vw(80, $s);
    }

    &-25 {
      padding-left: vw(100, $s);
    }

    &-28 {
      padding-left: vw(112, $s);
    }

    &-32 {
      padding-left: vw(128, $s);
    }

    &-5-px {
      padding-left: vw(5, $s);
    }

    &-6-px {
      padding-left: vw(6, $s);
    }

    &-7-px {
      padding-left: vw(7, $s);
    }

    &-10-px {
      padding-left: vw(10, $s);
    }
  }

  .rounded {
    &-1 {
      border-radius: vw(4, $s);
    }

    &-2 {
      border-radius: vw(8, $s);
    }

    &-3 {
      border-radius: vw(12, $s);
    }

    &-4 {
      border-radius: vw(16, $s);
    }

    &-5 {
      border-radius: vw(20, $s);
    }

    &-2-px {
      border-radius: vw(2, $s);
    }

    &-3-px {
      border-radius: vw(3, $s);
    }

    &-6-px {
      border-radius: vw(6, $s);
    }

    &-7-px {
      border-radius: vw(7, $s);
    }

    &-tl-3 {
      border-top-left-radius: vw(12, $s);
    }

    &-tr-3 {
      border-top-right-radius: vw(12, $s);
    }

    &-100-px {
      border-radius: vw(100, $s);
    }

    &-50-percent {
      border-radius: 50%;
    }
  }

  .gap {
    &-1 {
      gap: vw(4, $s);
    }

    &-2 {
      gap: vw(8, $s);
    }

    &-3 {
      gap: vw(12, $s);
    }

    &-4 {
      gap: vw(16, $s);
    }

    &-5 {
      gap: vw(20, $s);
    }

    &-6 {
      gap: vw(24, $s);
    }

    &-7 {
      gap: vw(28, $s);
    }

    &-8 {
      gap: vw(32, $s);
    }

    &-9 {
      gap: vw(36, $s);
    }

    &-10 {
      gap: vw(40, $s);
    }

    &-3-px {
      gap: vw(3, $s);
    }

    &-6-px {
      gap: vw(6, $s)
    }

    &-10-px {
      gap: vw(10, $s);
    }

    &-14-px {
      gap: vw(14, $s);
    }

    &-15-px {
      gap: vw(15, $s);
    }

    &-18-px {
      gap: vw(18, $s)
    }

    &-19-px {
      gap: vw(19, $s)
    }

    &-29-px {
      gap: vw(29, $s)
    }

    &-34-px {
      gap: vw(34, $s);
    }

  }

  .text {

    &-xs {
      font-size: vw(12, $s);
    }

    &-sm {
      font-size: vw(14, $s);
    }

    &-base {
      font-size: vw(16, $s);
    }

    &-8-px {
      font-size: vw(8, $s);
    }

    &-10-px {
      font-size: vw(10, $s);
    }

    &-18-px {
      font-size: vw(18, $s);
    }

    &-20-px {
      font-size: vw(20, $s);
    }

    &-24-px {
      font-size: vw(24, $s);
    }

    &-30-px {
      font-size: vw(30, $s);
    }

    &-32-px {
      font-size: vw(32, $s);
    }

    &-11 {
      font-size: vw(44, $s);
    }

    &-12 {
      font-size: vw(48, $s);
    }

    &-input .ant-picker-input input::placeholder {
      font-size: vw(14, $s);

    }

    &-input-search-select .ant-select-selection-placeholder {
      font-size: vw(14, $s);
    }

    &-span-select .ant-select-selection-search input::placeholder {
      font-size: vw(14, $s);
      color: #98A2B3 !important;
      font-weight: 400 !important;
      font-family: var(--font-regular) !important;
    }

  }

  .w {
    &-1 {
      width: vw(4, $s);
    }

    &-2 {
      width: vw(8, $s);
    }

    &-3 {
      width: vw(12, $s);
    }

    &-4 {
      width: vw(16, $s);
    }

    &-5 {
      width: vw(20, $s);
    }

    &-6 {
      width: vw(24, $s);
    }

    &-7 {
      width: vw(28, $s);
    }

    &-8 {
      width: vw(32, $s);
    }

    &-9 {
      width: vw(36, $s);
    }

    &-10 {
      width: vw(40, $s);
    }

    &-15 {
      width: vw(60, $s);
    }
    &-20-percent {
      width: 20%;
    }
    &-63-percent {
      width: 63%;
    }

    &-80-percent {
      width: 80%;
    }

    &-half {
      width: 50%;
    }

    &-full {
      width: 100%;
    }

    &-100vw {
      width: 100vw;
    }

    &-6-px {
      width: vw(6, $s);
    }

    &-14-px {
      width: vw(14, $s);
    }

    &-18-px {
      width: vw(18, $s);
    }

    &-36-px {
      width: vw(36, $s);
    }
    &-38-px {
      width: vw(38, $s);
    }
    &-40-px {
      width: vw(40, $s);
    }
    &-45-px {
      width: vw(45, $s);
    }
    &-56-px {
      width: vw(56, $s);
    }

    &-60-px {
      width: vw(60, $s);
    }

    &-64-px {
      width: vw(64, $s);
    }

    &-71-px {
      width: vw(71, $s);
    }

    &-72-px {
      width: vw(72, $s);
    }

    &-74-px {
      width: vw(74, $s);
    }

    &-88-px {
      width: vw(88, $s);
    }

    &-83-px {
      width: vw(83, $s);
    }

    &-93-px {
      width: vw(93, $s);
    }

    &-98-px {
      width: vw(98, $s);
    }

    &-100-px {
      width: vw(100, $s);
    }
    &-101-px {
      width: vw(101, $s);
    }
    &-103-px {
      width: vw(103, $s);
    }

    &-106-px {
      width: vw(106, $s);
    }

    &-107-px {
      width: vw(107, $s);
    }

    &-108-px {
      width: vw(108, $s);
    }

    &-109-px {
      width: vw(109, $s);
    }
    &-110-px {
      width: vw(110, $s);
    }
    &-111-px {
      width: vw(111, $s);
    }

    &-112-px {
      width: vw(112, $s);
    }

    &-114-px {
      width: vw(114, $s);
    }

    &-116-px {
      width: vw(116, $s);
    }

    &-120-px {
      width: vw(120, $s);
    }

    &-121-px {
      width: vw(121, $s);
    }

    &-122-px {
      width: vw(122, $s);
    }

    &-125-px {
      width: vw(125, $s);
    }

    &-126-px {
      width: vw(126, $s);
    }

    &-128-px {
      width: vw(128, $s);
    }

    &-139-px {
      width: vw(139, $s);
    }

    &-140-px {
      width: vw(140, $s);
    }

    &-147-px {
      width: vw(147, $s);
    }
    &-149-px {
      width: vw(149, $s);
    }
    &-156-px {
      width: vw(156, $s);
    }
    &-163-px {
      width: vw(163, $s);
    }
    &-165-px {
      width: vw(165, $s);
    }

    &-166-px {
      width: vw(166, $s);
    }

    &-168-px {
      width: vw(168, $s);
    }

    &-170-px {
      width: vw(170, $s);
    }

    &-174-px {
      width: vw(174, $s);
    }

    &-175-px {
      width: vw(175, $s);
    }

    &-176-px {
      width: vw(176, $s);
    }
    &-177-px {
      width: vw(177, $s);
    }
    &-191-px {
      width: vw(191, $s);
    }

    &-200-px {
      width: vw(200, $s);
    }

    &-208-px {
      width: vw(208, $s);
    }

    &-210-px {
      width: vw(210, $s);
    }
    &-214-px {
      width: vw(214, $s);
    }
    &-215-px {
      width: vw(215, $s);
    }
    &-244-px {
      width: vw(244, $s) !important;
    }
    &-255-px {
      width: vw(255, $s);
    }

    &-259-px {
      width: vw(259, $s);
    }

    &-283-px {
      width: vw(283, $s);
    }

    &-289-px {
      width: vw(289, $s);
    }

    &-295-px {
      width: vw(295, $s);
    }
    &-300-px {
      width: vw(300, $s);
    }
    &-303-px {
      width: vw(303, $s);
    }

    &-319-px {
      width: vw(319, $s);
    }

    &-326-px {
      width: vw(326, $s);
    }

    &-348-px {
      width: vw(348, $s);
    }

    &-352-px {
      width: vw(352, $s);
    }

    &-370-px {
      width: vw(370, $s);
    }

    &-375-px {
      width: vw(375, $s);
    }

    &-378-px {
      width: vw(378, $s);
    }

    &-384-px {
      width: vw(383.8, $s);
    }

    &-385-px {
      width: vw(385.33, $s);
    }

    &-386-px {
      width: vw(386, $s);
    }

    &-395-px {
      width: vw(395, $s);
    }

    &-402-px {
      width: vw(402, $s);
    }

    &-468-px {
      width: vw(468, $s);
    }

    &-494-px {
      width: vw(494, $s);
    }

    &-497-px {
      width: vw(497, $s);
    }

    &-500-px {
      width: vw(500, $s);
    }

    &-501-px {
      width: vw(501.5, $s);
    }

    &-516-px {
      width: vw(516, $s);
    }

    &-555-px {
      width: vw(555, $s);
    }

    &-564-px {
      width: vw(564, $s);
    }

    &-600-px {
      width: vw(600, $s);
    }

    &-630-px {
      width: vw(630, $s);

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-705-px {
      width: vw(705, $s);
    }

    &-730-px {
      width: vw(730, $s);
    }

    &-769-px {
      width: vw(769, $s);
    }

    &-800-px {
      width: vw(800, $s);
    }

    &-809-px {
      width: vw(809, $s);
    }

    &-813-px {
      width: vw(813, $s);
    }

    &-832-px {
      width: vw(832, $s);
    }

    &-932-px {
      width: vw(932, $s);
    }

    &-976-px {
      width: vw(976, $s);
    }

    &-fit {
      width: fit-content;
    }
  }

  .h {
    &-1 {
      height: vw(4, $s);
    }

    &-2 {
      height: vw(8, $s);
    }

    &-3 {
      height: vw(12, $s);
    }

    &-4 {
      height: vw(16, $s);
    }

    &-5 {
      height: vw(20, $s);
    }

    &-6 {
      height: vw(24, $s);
    }

    &-7 {
      height: vw(28, $s);
    }

    &-8 {
      height: vw(32, $s);
    }

    &-9 {
      height: vw(36, $s);
    }

    &-10 {
      height: vw(40, $s);
    }

    &-11 {
      height: vw(44, $s);
    }

    &-12 {
      height: vw(48, $s);
    }

    &-13 {
      height: vw(52, $s);
    }

    &-15 {
      height: vw(60, $s);
    }

    &-auto {
      height: auto;
    }

    &-full {
      height: 100%;
    }

    &-fit-content {
      height: fit-content;
    }


    &-6-px {
      height: vw(6, $s);
    }

    &-14-px {
      height: vw(14, $s);
    }

    &-18-px {
      height: vw(18, $s);
    }

    &-30-px {
      height: vw(30, $s);
    }

    &-100vh {
      height: 100vh;
    }

    &-57-px {
      height: vw(57, $s);
    }

    &-38-px {
      height: vw(38.8, $s);
    }

    &-38 {
      height: vw(38, $s);
    }

    &-40-px {
      height: vw(40, $s);
    }

    &-42-px {
      height: vw(42, $s);
    }

    &-64-px {
      height: vw(64, $s);
    }
    &-80-px {
      height: vw(80, $s) !important;
    }
    &-100-px {
      height: vw(100, $s);
    }
    &-110-px {
      height: vw(110, $s);
    }
    &-120-px {
      height: vw(120, $s);
    }

    &-148-px {
      height: vw(148, $s);
    }
    &-150-px {
      height: vw(150, $s);
    }
    &-176-px {
      height: vw(176.87, $s);
    }

    &-200-px {
      height: vw(200, $s);
    }

    &-300-px {
      height: vw(300, $s);
    }

    &-324-px {
      height: vw(324, $s);
    }
    &-340-px {
      height: vw(340, $s);
    }
    &-355-px {
      height: vw(355, $s);
    }
    &-822-px {
      height: vw(822, $s);
    }
    &-820-px {
      height: vw(820, $s);
    }
  }

  .border {

    &-none {
      border: unset;
    }

    &-left-EAECF0 {
      border-left: vw(1, $s) solid #EAECF0;
    }

    &-right-EEEEEE {
      border-right: vw(1, $s) solid #EEEEEE;
    }

    &-right-F2F4F7 {
      border-right: vw(1, $s) solid #F2F4F7;
    }

    &-top-F2F4F7 {
      border-top: vw(1, $s) solid #F2F4F7;
    }

    &-top-EEEEEE {
      border-top: vw(1, $s) solid #EEEEEE;
    }

    &-bottom-F0F0F0 {
      border-bottom: vw(1, $s) solid #F0F0F0;
    }

    &-bottom-EEEEEE {
      border-bottom: vw(1, $s) solid #EEEEEE;
    }

    &-D0D5DD{
      border: vw(1, $s) solid #D0D5DD;
    }
    &-FFA39E {
      border: vw(1, $s) solid #FFA39E;

    }

    &-EDEDEE {
      border: vw(1, $s) solid #EDEDEE;
    }

    &-039855 {
      border: vw(1, $s) solid #039855;
    }

    &-EB2F96 {
      border: vw(1, $s) solid #EB2F96;
    }

    &-40A9FF {
      border: vw(1, $s) solid #40A9FF;
    }

    &-722ED1 {
      border: vw(1, $s) solid #722ED1;
    }

    &-FA541C {
      border: vw(1, $s) solid #FA541C
    }

    &-E0F1FC {
      border: vw(1, $s) solid #E0F1FC;
    }

    &-E1E1E1 {
      border: vw(1, $s) solid #E1E1E1;
    }

    &-F2F4F7 {
      border: vw(1, $s) solid #F2F4F7;
    }

    &-l-F2F4F7 {
      border-left: vw(1, $s) solid #F2F4F7;
    }

    &-ADC6FF {
      border: vw(1, $s) solid #ADC6FF;
    }

    &-1890FF {
      border: vw(1, $s) solid #1890FF;
    }

    &-f2f4f7 {
      border: vw(1, $s) solid #f2f4f7;
    }

    &-D3ADF7 {
      border: vw(1, $s) solid #D3ADF7
    }

    &-dashed-d9d9d9 {
      border: vw(1, $s) dashed #d9d9d9;
    }

    &-dashed-1890FF {
      border: vw(1, $s) dashed #1890FF;
    }

    &-d9d9d9 {
      border: vw(1, $s) solid #d9d9d9;
    }

    &-EAECF0 {
      border: vw(1, $s) solid #EAECF0;
    }

    &-FFCCD2 {
      border: vw(1, $s) solid #FFCCD2;
    }

    &-F5222D {
      border: vw(1, $s) solid #F5222D;
    }

    &-91D5FF {
      border: vw(1, $s) solid #91D5FF;
    }

    &-FFE58F {
      border: vw(1, $s) solid #FFE58F;
    }

    &-096DD9 {
      border: vw(1, $s) solid #096DD9;
    }

    &-DFF1FC {
      border: vw(1, $s) solid #DFF1FC;
    }

    &-DCF0FC {
      border: vw(1, $s) solid #DCF0FC;
    }

    &-D9D9D9 {
      border: vw(1, $s) solid #D9D9D9;
    }
  }

  .line-height {
    &-22-px {
      line-height: vw(22, $s);
    }

    &-6 {
      line-height: vw(24, $s);
    }

    &-normal {
      line-height: normal;
    }
  }

  .bottom {
    &-0 {
      bottom: 0;
    }

    &_-22-px {
      bottom: vw(-22, $s);
    }
  }

  .right {
    &_-5-px {
      right: vw(-5, $s);
    }

    &-0 {
      right: 0;
    }
  }

  .left {
    &-0 {
      left: 0;
    }
  }

  .h-progress .ant-progress-bg {
    height: vw(8, $s) !important;
  }

  .h-progress-outer div.ant-progress-outer {
    height: vw(8, $s) !important;
  }

  .size-check-circle.ant-progress .ant-progress-text .anticon {
    font-size: vw(14, $s);
  }

  .text-progress .ant-progress-text {
    font-size: vw(14, $s);
    color: #667085;
  }

  .ant-select-item-option-content {
    font-size: vw(14, $s) !important;
  }

  .text-explan.ant-form-item .ant-form-item-explain {
    font-size: vw(14, $s);
    font-family: var(--font-regular) !important;
  }

  .border-start-radio.ant-radio-button-wrapper:first-child {
    border-start-start-radius: vw(8, $s);
    border-end-start-radius: vw(8, $s);
  }

  .border-end-radio.ant-radio-button-wrapper:last-child {
    border-start-end-radius: vw(8, $s);
    border-end-end-radius: vw(8, $s)
  }

  .h-8-select span.ant-select-selection-placeholder {
    height: vw(32, $s);
  }


  .size-selector.ant-select-single .ant-select-selector {
    font-size: vw(14, $s);
    padding: unset !important;
  }

  .table-block-t {
    .ant-table-wrapper {
      .ant-table-column-sorter {
        margin-inline-start: vw(4, $s);
      }

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        font-size: vw(12, $s);
      }

      .ant-table-thead th.ant-table-column-sort {
        background: #096DD9;
      }

      .ant-table-column-sorter-down.active,
      .ant-table-column-sorter-up.active {
        color: #344054;
      }

      table tr td.ant-table-selection-column,
      .ant-table-selection-column {
        padding-inline-end: vw(8, $s);
        padding-inline-start: vw(8, $s);
      }

      table {
        border-radius: vw(8, $s) vw(8, $s) 0 0;
      }

      .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: vw(8, $s);
      }

      .ant-space-gap-col-middle {
        column-gap: vw(10, $s);
      }

      .ant-table-thead>tr>th {
        border-bottom: 0 solid #f0f0f0 !important;
        font-family: var(--font-medium);
        color: #fff;
        background-color: #096DD9;
        font-weight: 500;
        padding: vw(10, $s) vw(8, $s);
        white-space: nowrap;
        line-height: vw(24, $s);
      }

      .ant-table-tbody>tr>td {
        border-bottom: 0 solid #f0f0f0 !important;
        font-family: var(--font-regular);
      }

      .ant-table {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        color: #344054;
        font-family: var(--font-regular);
      }

      .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0;
      }

      .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: #096DD9;
      }

      .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
        background: transparent;
      }

      .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: vw(8, $s);
      }

      table tr th.ant-table-selection-column {
        padding-inline-end: vw(8, $s);
        padding-inline-start: vw(8, $s);
      }

      .ant-table-selection-column {
        .ant-checkbox-wrapper {
          font-size: vw(14, $s);
          align-items: center;
        }
      }

      .ant-checkbox {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        &.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1890FF;
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: vw(1, $s) solid #d9d9d9;
          border-radius: vw(4, $s);

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }

      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            border: none;
          }
        }
      }

      .ant-table-tbody>tr>td {
        padding: vw(9, $s) vw(8, $s);
        white-space: nowrap;
      }

      .ant-space-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        span {
          width: vw(24, $s);
          height: vw(24, $s);
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #D9D9D9;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: vw(14, $s);
        }
      }
    }

    .pagination-block {
      padding: vw(9, $s);

      .ant-pagination {
        text-align: center;
        font-size: vw(14, $s);
        font-family: var(--font-regular);
        color: #344054;

        .ant-select-single .ant-select-selector {
          color: #344054;

          .ant-select-selection-search {
            inset-inline-start: vw(14, $s);
            inset-inline-end: vw(14, $s);
          }
        }

        .ant-pagination-item-active a {
          color: #1890FF !important;
        }

        .ant-pagination-item a {
          color: #344054;
        }

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-prev {
          min-width: vw(32, $s);
          height: vw(32, $s);
          margin-inline-end: vw(8, $s);
          font-family: var(--font-regular);
          line-height: vw(30, $s);
          border: vw(1, $s) solid #F2F4F7;
          border-radius: vw(2, $s);
        }

        .ant-pagination-item-active {
          border-color: #1890FF;
          font-family: var(--font-medium);
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          font-size: vw(12, $s);
        }

        .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
          font-size: vw(12, $s);
        }

        .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
          letter-spacing: vw(2, $s);
        }

        .ant-pagination-jump-next {
          min-width: vw(32, $s);
          height: vw(32, $s);
          line-height: vw(32, $s);
          border-radius: vw(6, $s);
          margin-inline-end: vw(8, $s);

          .ant-pagination-item-container .ant-pagination-item-link-icon {
            font-size: vw(12, $s);
          }
        }

        .ant-select-single {
          font-size: vw(14, $s);
          height: vw(32, $s);

          &.ant-select-show-arrow .ant-select-selection-item {
            padding-inline-end: vw(18, $s);
            line-height: vw(30, $s);
          }

          .ant-select-selector .ant-select-selection-item {
            line-height: vw(30, $s);
          }

          .ant-select-selector {
            font-size: vw(14, $s);
            border-radius: vw(6, $s);
            padding: 0 vw(11, $s) !important;
          }

          .ant-select-dropdown {
            font-size: vw(14, $s);
            padding: vw(4, $s);
            border-radius: vw(8, $s);
            box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);

            .ant-select-item {
              font-size: vw(14, $s);
            }
          }
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
          line-height: vw(30, $s);
          font-size: vw(14, $s);
        }

        .ant-pagination-options {
          margin-inline-start: vw(0, $s);
        }

        :where(.css-dev-only-do-not-override-1k979oh).ant-select .ant-select-arrow {
          inset-inline-end: vw(11, $s);
          height: vw(12, $s);
          margin-top: vw(-6, $s);
          font-size: vw(12, $s);
        }

        .ant-pagination-options-quick-jumper {
          height: vw(32, $s);
          margin-inline-start: vw(8, $s);
          line-height: vw(32, $s);

          input {
            width: vw(50, $s);
            padding: vw(4, $s) vw(11, $s);
            font-size: vw(14, $s);
            border-radius: vw(6, $s);
            border-width: vw(1, $s);
            height: vw(32, $s);
            margin-inline-start: vw(8, $s);
            margin-inline-end: vw(8, $s);
          }
        }
      }
    }
  }
  .custom-checkbox input {
    display: none; /* Ẩn checkbox mặc định */
  }

  .checkmark {
    width: vw(17, $s);
    height: vw(17, $s);
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transition: 0.3s;
    border: vw(1, $s) solid #1677ff;
  }

  .custom-checkbox input:checked + .checkmark {
    background-color: #1677ff; /* Màu khi được chọn */
  }

  .custom-checkbox input:checked + .checkmark::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: vw(8, $s);
    height: vw(8, $s);
    background: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .ct-menu-cycle{
    .menu-trigger {
      padding: vw(12, $s) vw(9, $s);
      height: vw(40, $s);
      box-shadow: 0 vw(1, $s) 0 0 #F0F0F0 inset;
    }
    .ant-menu {
      &-submenu {
        &-arrow {
          inset-inline-end: vw(16, $s);

          &::before,
          &::after {
            width: vw(6, $s);
            height: vw(1.5, $s);
            border-radius: vw(6, $s);
          }
        }
        &-title {
          padding: vw(9, $s) vw(16, $s) !important;
          padding-inline: unset !important;
          .ant-menu-title-content {
            margin-inline-start: vw(10, $s) !important;
          }
        }
        .ant-menu {
          &-item {
            padding-inline: vw(40, $s) vw(2, $s) !important;
          }
        }
        .ant-menu-submenu {
          .ant-menu {
            &-submenu-title {
              padding-inline-start: vw(40, $s) !important;
            }
            &-title-content {
              margin-inline-start: 0 !important;

            }
            &-item {
              padding-inline-start: vw(64, $s) !important;
            }
          }
        }
      }
      &-root {
        row-gap: vw(8, $s);
      }
      &-sub {
        .ant-menu-item {
          margin-block-start: vw(8, $s);
        }
      }
      &-item-selected {
        box-shadow: unset;
        background: #F5F5F5;

      }
      &-title-content{
        a{
          color: #344054 !important;
        }
      }
    }
    .ant-menu-light.ant-menu-root.ant-menu-inline{
      border-inline-end:unset !important
    }
    .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline{
      background: white !important;
    }
    .ant-menu-light .ant-menu-item{
      color:#344054 !important;
      font-weight: 400 !important;
      font-family: var(--font-regular);
    }
    .ant-menu-light .ant-menu-item-selected{
      color:#344054 !important;
      font-weight: 400 !important;
      font-family: var(--font-regular);
    }
    .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
      color:#344054 !important;
      font-weight: 500 !important;
      font-family: var(--font-medium);
    }

    .ant-menu-inline >.ant-menu-item,
    .ant-menu-vertical >.ant-menu-item,
    .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title,
    .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
      height: vw(40, $s);
      line-height: vw(40, $s);
    }
    .ant-menu .ant-menu-item .ant-menu-item-icon,
    .ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu .ant-menu-item .anticon,
    .ant-menu .ant-menu-submenu-title .anticon {
      min-width: vw(16, $s);
    }
    .ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
    .ant-menu-inline .ant-menu-submenu-arrow::before {
      transform: rotate(-45deg) translateX(vw(2.5, $s));
    }
    .ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
    .ant-menu-inline .ant-menu-submenu-arrow::after {
      transform: rotate(45deg) translateX(vw(-2.5, $s));
    }
    .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before {
      transform: rotate(45deg) translateX(vw(2.5, $s));
    }
    .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(vw(-2.5, $s));
    }
    .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
      transform: translateY(vw(-2, $s));
    }
    .ant-menu-inline-collapsed {
      width: vw(63, $s);
    }
    .ant-menu-inline-collapsed >.ant-menu-item .ant-menu-item-icon,
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon,
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu-inline-collapsed >.ant-menu-item .anticon,
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
    .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title .anticon {
      font-size: vw(16, $s);
    }
  }
  .ct-p-table.ant-table-wrapper .ant-table-tbody>tr>td{
    padding: vw(16,$s) vw(4,$s);
  }

  .content {
    display: -webkit-box;
    // height: vw(22,$s);
    width: vw(200,$s);
    overflow: hidden;
    transition: height 0.3s ease-in-out;

  }
  .content.expanded {
    height: auto; /* Mở rộng nội dung */
  }

  .toggle-btn {
    color: #1890FF;
    cursor: pointer;
    background: none;
    border: none;
    font-size: vw(14,$s);
    white-space: nowrap;
  }
.img-account{
  object-fit: cover;
    object-position: center center;
    border-radius: 50%;
    aspect-ratio: 1;
  height: vw(100,$s);
  box-shadow: 0 0 vw(1, $s) var(--neutral-gray-500);
}

}

.white-space {
  &-nowrap {
    white-space: nowrap;
  }
}

.text {
  &-underline {
    text-decoration: underline;
  }

  &-end {
    text-align: end;
  }
  &-FA8C16{
    color: #FA8C16;
  }
  &-8C8C8C {
    color: #8C8C8C;
  }

  &-B8B8B8 {
    color: #B8B8B8;
  }

  &-039855 {
    color: #039855;
  }

  &-FA541C {
    color: #FA541C;
  }

  &-white {
    color: white;
  }

  &-EB2F96 {
    color: #EB2F96;
  }

  &-FF4D4F {
    color: #FF4D4F;
  }

  &-475467 {
    color: #475467;
  }

  &-BFBFBF {
    color: #BFBFBF;
  }

  &-4B4B4B {
    color: #4B4B4B;
  }

  &-F5222D {
    color: #F5222D;
  }

  &-344054 {
    color: #344054;
  }

  &-667085 {
    color: #667085
  }

  &-2F54EB {
    color: #2F54EB;
  }

  &-84878C {
    color: #84878C;
  }

  &-1890FF {
    color: #1890FF;
  }

  &-722ED1 {
    color: #722ED1;
  }

  &-98A2B3 {
    color: #98A2B3;
  }

  &-096DD9 {
    color: #096DD9;
  }

  &-F64C4C {
    color: #F64C4C;
  }

  &-8E8E8E {
    color: #8E8E8E;
  }

  &-1F1F1F {
    color: #1F1F1F;
  }

  &-1E1E1E {
    color: #1E1E1E;
  }

  &-8B8B8B {
    color: #8B8B8B;
  }

  &-252626 {
    color: #252626;
  }

  &-FAAD14 {
    color: #FAAD14;
  }

  &-40A9FF {
    color: #40A9FF;
  }

  &-BBBBBC {
    color: #BBBBBC;
  }

  &-#FF4D4F {
    color: #FF4D4F;
  }
}

.bg {
  &-white {
    background: white;
  }

  &-FFF1F0 {
    background: #FFF1F0;
  }

  &-00539F {
    background: #00539F;
  }

  &-FAFAFA {
    background: #FAFAFA;
  }

  &-F6FEF9 {
    background: #F6FEF9;
  }

  &-F8F8F8 {
    background: #F8F8F8;
  }

  &-FFF0F6 {
    background: #FFF0F6;
  }

  &-FFF2E8 {
    background: #FFF2E8;
  }

  &-FFFFFF {
    background: #FFFFFF;
  }

  &-096DD9 {
    background: #096DD9;
  }

  &-FCFCFD {
    background: #FCFCFD;
  }

  &-F0F5FF {
    background: #F0F5FF;
  }

  &-E6F7FF {
    background: #E6F7FF;
  }

  &-F9F0FF {
    background: #F9F0FF;
  }

  &-F5F5F5 {
    background: #F5F5F5;
  }

  &-1890FF {
    background: #1890FF;
  }

  &-FEF2F2 {
    background: #FEF2F2;
  }

  &-F9FAFB {
    background: #F9FAFB;
  }

  &-FFF1F0 {
    background: #FFF1F0;
  }

  &-FFFBE6 {
    background: #FFFBE6;
  }

  &-EAECF0 {
    background: #EAECF0;
  }

  &-E6F7FF {
    background: #E6F7FF;
  }

  &-F2F4F7 {
    background: #F2F4F7;
  }
  &-FF891A{
    background: #FF891A;
  }
}

.fw {
  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }
}

.none {
  display: none;
}

.rounded-7px {
  border-radius: 7px;
}

.font {
  &-regular {
    font-family: var(--font-regular);
  }

  &-medium {
    font-family: var(--font-medium);
  }

  &-bold {
    font-family: var(--font-bold);
  }

}

.overflow {
  &-hidden {
    overflow: hidden;
  }

  &-auto {
    overflow: auto;
  }

  &-x-scroll {
    overflow-x: scroll;
  }

  &-y-scroll {
    overflow-y: scroll;
  }
}

.outline-none {
  outline: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-none-block-progress.ant-progress .ant-progress-inner {
  display: block !important;
}

.custom-progress.ant-progress-line {
  margin-bottom: unset !important;
  margin-inline-end: unset !important;
}

.ant-progress-success.ant-progress.ant-progress-status-success .ant-progress-bg {
  background: #52c41a !important;
}

.bg-progress-slide .ant-progress-bg {
  background: linear-gradient(90deg, rgba(36, 149, 254, 1) 0%, rgba(198, 224, 247, 1) 100%) !important;
}

.d-none-span .ant-select-selection-item {
  display: none !important;
}

.custom-placehoder.ant-select .ant-select-selection-placeholder {
  flex: unset !important;
}

.m-none-form-item.ant-form-item {
  margin-bottom: unset !important;

}



.bg-FCFCFD-ant-select .ant-select-selector {
  background: #FCFCFD !important;
}



.w-none-progress .ant-progress-text {
  width: unset !important;

}

.custom-input-focus.ant-input-outlined:focus {
  border-color: unset !important;
  box-shadow: unset !important;
  outline: unset !important;
  background: #FCFCFD !important;
}

.none-focus-erro.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: #FCFCFD !important;
  border-width: unset !important;
  border-style: unset !important;
  border-color: unset !important;
}

.m-none.ant-progress .ant-progress-text {
  margin-inline-start: unset !important;
}

.bg-header-table-F8F8F8 .ant-table-thead>tr>th {
  background: #F8F8F8 !important;
}

.word-wrap {
  word-wrap: break-word;
}

.d-none-form-item {
  &.ant-form-item {
    display: none !important;
  }
}

.disabled {
  pointer-events: none !important;
  /* Ngăn chặn tất cả sự kiện click */
  opacity: 0.4 !important;
  /* Làm mờ div để hiển thị nó bị vô hiệu hóa */
}

.event-none-form-item {
  &.ant-form-item {
    display: block !important;
    pointer-events: none !important;
    opacity: 0.4 !important;
  }

}

.d-none-form-item {
  &.ant-form-item {
    display: none !important;
  }
}

.event-none-Flex.ant-flex {
  pointer-events: none !important;
  opacity: 0.4 !important;
}

.d-none-Flex.ant-flex {
  display: none !important;
}

.event-none-radio.ant-radio-group {
  pointer-events: none !important;
  opacity: 0.4 !important;
}

.d-none-radio.ant-radio-group {
  display: none !important;
}
.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
}





@include vwConvert() using ($s) {
  .rs-button {
    padding: vw(4, $s) vw(16, $s);
    font-size: vw(16, $s);
    line-height: vw(24, $s);
    border-radius: vw(7, $s);
    height: vw(32, $s);
    gap: vw(10, $s);

    &.primary-outline {
      box-shadow: 0 vw(2, $s) 0 0 #00000004;
    }
    &--sm,
    &.file-download {
      font-size: vw(14, $s);
      line-height: vw(22, $s);
    }
  }
}

.rs-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-medium);

  > .anticon + span,
  > span + .anticon {
    margin: 0;
  }
  &:not(.ant-btn-icon-only)>.ant-btn-icon.ant-btn-loading-icon,
  &:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child) {
    margin-inline-end: 0;
  }
  &.ant-btn {
    &-primary {
      color: #FFFFFF;
      background: var(--primary-7);

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: #FFFFFF;
        background: #4096ff;
      }
      &.dust-red {
        background: var(--dust-red-6);

        &:not(:disabled):not(.ant-btn-disabled):hover {
          background: var(--dust-red-5);
        }
      }
    }
    &-default {
      color: inherit;
    }
    &-icon-only {
      width: auto;
      aspect-ratio: 1;

      .anticon {
        font-size: inherit;
      }
    }
  }
  &.primary-outline {
    color: var(--primary-7);
    background: #FFFFFF;
    border-color: var(--primary-6);
  }
  &.danger-outline {
    color: var(--dust-red-6);
    background: var(--dust-red-1);
    border-color: var(--dust-red-6);

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--dust-red-1);
      background: var(--dust-red-6);
      border-color: var(--dust-red-6);
    }
  }
  &--sm {
    font-family: var(--font-regular);
  }
  &.file-download {
    border: none;
    padding-inline: 0;
    font-family: var(--font-regular);
    box-shadow: none;
    height: auto;
    color: var(--primary-7);
    text-decoration: underline;
  }
  &.primary-7 {
    color: var(--primary-7);
    border-color: var(--primary-7);
  }
  &.edit {
    color: var(--primary-7);

    &:hover {
      color: var(--primary-7);
      border-color: var(--primary-7);
    }
  }
  &.delete {
    color: var(--dust-red-6);

    &:hover {
      color: var(--dust-red-6) !important;
      border-color: var(--dust-red-6) !important;
    }
  }
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	outline: none;
	text-transform: inherit;
}

html,
body {
	line-height: 1.5;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	font-size: var(--base-size);
	font-weight: 400;
}

dl dl,
dl ol,
dl ul,
ol dl,
ul dl,
ol ol,
ol ul,
ul ol,
ul ul {
	margin: 0;
}

hr {
	box-sizing: content-box;
	color: inherit;
	height: 0;
	overflow: visible;
}

main {
	display: block;
}

button,
input,
select {
	margin: 0;

	&:focus {
		box-shadow: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
ul {
	margin: 0;
}

a,
button {
	transition: all 250ms;
	cursor: pointer;
}

a {
	background-color: transparent;
	text-decoration: none;
	color: inherit;

	&:hover,
	&.active {
		color: var(--color-primary);
	}
}

.btn-link {
	text-decoration: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

select {
	text-transform: none;
}

b,
strong {
	font-weight: 700;
}

small {
	font-size: 80%;
}

audio,
video,
picture,
figure {
	display: inline-block;
}

textarea {
	margin: 0;
	overflow: auto;
	font: inherit;
	resize: vertical;
}

img {
	max-width: 100%;
	border-style: none;
}

input {
	font-family: inherit;
	font-size: inherit;
	color: inherit;

	// &[type="number"] {

	// 	&::-webkit-inner-spin-button,
	// 	&::-webkit-outer-spin-button {
	// 		-webkit-appearance: none;
	// 		margin: 0;
	// 	}
	// }

	&::placeholder {
		color: var(--placeholder-cl);
	}
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

tbody,
td,
tfoot,
th,
thead,
tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

::-webkit-input-placeholder {
	color: var(--placeholder-cl);
}

::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

::-webkit-search-decoration {
	-webkit-appearance: none;
}


[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

[hidden] {
	display: none;
}

[disabled] {
	cursor: not-allowed;
}

a {
	transition: all .25s;

	&:not(.button) {
		&:hover {
			color: var(--color-light-primary);
		}
	}
}

.rs {
	&-link {
		color: inherit;
		text-decoration: none;
	}

	&-btn {
		cursor: pointer;
		border: none;
		outline: none;
		background: transparent;
		font-weight: inherit;
		padding: 0;
	}
}

// .gap {
// 	&-40 {
// 		gap: 40px;
// 	}
// 	&-24 {
// 		gap: 24px;
// 	}
// 	&-16 {
// 		gap: 16px;
// 	}
// 	&-12 {
// 		gap: 12px;
// 	}
// 	&-8 {
// 		gap: 8px;
// 	}
// 	&-4 {
// 		gap: 4px;
// 	}
// }
.font-title {
	font-family: var(--font-title);
}

.text {
	&-left {
		text-align: left;
	}

	&-center {
		text-align: center;
	}

	&-right {
		text-align: right;
	}

	&-start {
		text-align: start;
	}
}

.color {
	&-primary {
		color: var(--color-primary);
	}

	&-light {
		&-gray {
			color: var(--color-light-gray);
		}

		&-primary {
			color: var(--color-light-primary);
		}
	}

	&-danger {
		color: var(--color-danger);
	}
}

.d-flex {
	display: flex;
}

.flex {
	&-column {
		flex-direction: column;
	}

	&-wrap {
		flex-wrap: wrap;
	}
}

.align {
	&-start {
		align-items: flex-start;
	}

	&-center {
		align-items: center;
	}

	&-end {
		align-items: flex-end;
	}

	&-baseline {
		align-items: baseline;
	}
}

.justify {
	&-start {
		justify-content: start;
	}

	&-center {
		justify-content: center;
	}

	&-between {
		justify-content: space-between;
	}

	&-around {
		justify-content: space-around;
	}

	&-end {
		justify-content: end;
	}
}
@include vwConvert() using ($s) {
  .rs-drawer {
    font-size: vw(14, $s);

    &.w-580 {
      .ant-drawer-content-wrapper {
        width: vw(580, $s) !important;
      }
    }
    &.w-400 {
      .ant-drawer-content-wrapper {
        width: vw(400, $s) !important;
      }
    }
    .ant-drawer {
      &-header {
        // padding: vw(16, $s) vw(24, $s);
        padding: vw(12, $s) vw(24, $s);
      }
      &-title {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }
      &-body {
        padding: vw(24, $s);
      }
      &-footer {
        padding: vw(12, $s) vw(24, $s);
        box-shadow: 0 vw(9, $s) vw(28, $s) vw(8, $s) #0000000D, 0 vw(6, $s) vw(16, $s) 0 #00000014, 0 vw(3, $s) vw(6, $s) vw(-4, $s) #0000001F;
      }
    }
  }
}

.rs-drawer {
  font-family: var(--font-regular);
  color: var(--neutral-gray-700);

  .ant-drawer {
    &-title {
      font-family: var(--font-medium);
      font-weight: normal;
      color: var(--neutral-gray-700);
    }
  }
}

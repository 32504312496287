@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .manual-campaign-popup {
    .voucher-add__content {
      .block {
        gap: vw(12, $s);
      }
    }
    .rs-radio {
      padding-inline: vw(16, $s);

      &--button {
        border-radius: vw(8, $s);
        padding: vw(4, $s) vw(10, $s);

        &.ant-radio-button-wrapper {
          line-height: vw(22, $s);
        }

        .anticon {
          font-size: vw(18, $s);
          margin-inline-end: vw(8, $s);
        }
      }
      &-group--button {
        gap: vw(12, $s);
      }
    }
    .drawer-footer-vertical {
      margin-inline: vw(-24, $s);

      .top,
      .bottom {
        padding-inline: vw(24, $s);
      }
      .top {
        padding-block-end: vw(12, $s);
        margin-block-end: vw(12, $s);
        border-bottom: vw(1, $s) solid var(--neutral-gray-100);
        gap: vw(10, $s);
      }
      .bottom {
        gap: vw(10, $s);
      }
      .fee-list {
        gap: vw(10, $s);

        &-item {
          gap: vw(10, $s);

          .icon {
            font-size: vw(14, $s);
            flex: 0 0 vw(22, $s);
            max-width: vw(22, $s);
            border-radius: vw(4, $s);
          }
          &-wrapper {
            gap: vw(10, $s);

            &.total {
              .number {
                font-size: vw(16, $s);
                line-height: vw(24, $s);
              }
            }
          }
        }
        &-detail {
          gap: vw(10, $s);
          padding-inline-start: vw(32, $s);

          .item {
            gap: vw(10, $s);
            font-size: vw(12, $s);
            line-height: vw(20, $s);
          }
        }
      }
    }
    .simple-draggable {
      .ant-tree-list-holder-inner {
        gap: vw(16, $s);
      }
      .ant-tree-draggable-icon {
        height: vw(32, $s);
        width: vw(32, $s);
        border-width: vw(1, $s);
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        margin-inline-start: vw(-10, $s);
      }
      .ant-tree-treenode {
        gap: vw(10, $s);
      }
      .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
        border-width: vw(1, $s);
      }
      &-item {
        gap: vw(10, $s);

        .title {
          border-width: vw(1, $s);
          padding: vw(4, $s) vw(12, $s);
          border-radius: vw(8, $s);
        }
      }
    }
    .grid-2-cols {
      gap: vw(24, $s);
    }
    .receive-customer-item {
      .anticon {
        font-size: vw(24, $s);
      }
    }
    .condition-item {
      grid-template-columns: vw(179, $s) vw(120, $s) 1fr vw(24, $s);
      gap: vw(10, $s);

      .ant-btn-icon-only {
        height: vw(24, $s);
      }
      // .rs-select.ant-select-single .ant-select-selection-item, .rs-select.ant-select-single .ant-select-selection-placeholder {
      //   padding-inline-start: vw(12, $s);
      // }
      &-detail {
        grid-template-columns: vw(206, $s) vw(100, $s) vw(206, $s);
        gap: vw(10, $s);
      }
    }
    .focus-tags {
      .ant-tag {
        padding: vw(4, $s) vw(10, $s);
        gap: vw(8, $s);

        .anticon {
          font-size: vw(18, $s);
        }
      }
    }
  }
}

.manual-campaign-popup {
  .drawer-footer-vertical {
    .top {
      display: flex;
      flex-direction: column;
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .fee-list {
      display: flex;
      flex-direction: column;

      &-item {
        display: flex;
        align-items: center;

        .icon {
          cursor: pointer;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          &:not(.empty) {
            color: var(--primary-6);
            background: var(--primary-1);
          }
        }
        .number {
          margin-inline-start: auto;
        }
        .title {
          font-family: var(--font-medium);
        }
        &-wrapper {
          display: flex;
          flex-direction: column;

          &.total {
            .number {
              font-family: var(--font-bold);
              color: var(--success-600);
            }
          }
        }
      }
      &-detail {
        display: flex;
        flex-direction: column;

        .item {
          display: flex;
          align-items: center;
          color: var(--neutral-gray-500);

          .number {
            margin-inline-start: auto;
          }
        }
      }
    }
  }
  .rs-radio {
    &--button {
      &:not(:hover):not(.ant-radio-button-wrapper-checked) {
        border-color: var(--neutral-gray-200);
      }
    }
    &-group--button {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .rs-checkbox-group.vertical {
    color: var(--neutral-gray-500);
  }
  .simple-draggable {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    color: inherit;

    // .ant-tree-indent,
    .ant-tree-switcher-noop {
      display: none;
    }
    .ant-tree-treenode,
    .ant-tree .ant-tree-treenode {
      padding: 0;
    }
    .ant-tree-draggable-icon {
      opacity: 1;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border-style: solid;
      border-color: var(--neutral-gray-100);
    }
    .ant-tree-node-content-wrapper,
    .ant-tree-checkbox+span {
      min-height: auto;
      line-height: inherit;
      padding: 0;
    }
    .ant-tree-node-content-wrapper:hover,
    .ant-tree-checkbox+span:hover {
      background: transparent;
      cursor: grab;
    }
    .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
      border-color: var(--primary-6);
      bottom: 0;
    }
    &-item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: 1fr;

      .title {
        background: #FCFCFD;
        border-style: solid;
        border-color: var(--neutral-gray-100);
      }
      .ant-select-selector {
        border-color: var(--neutral-gray-100);
      }
    }
  }
  .grid-2-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: 1fr;
  }
  .condition-item,
  .condition-item-detail {
    display: grid;
    grid-auto-flow: column;
  }
}

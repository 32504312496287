@import '../../../styles/root.scss';

@include vwConvert() using($s) {
  .agency-filter-block {
    .ant-input-search {
      flex-grow: 1;
      width: auto;
    }
    .ant-select {
      width: vw(210, $s);
    }
  }
}

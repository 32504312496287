@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .ar-customer-statistical-card {
    padding: vw(9, $s) vw(24, $s);
    border-radius: vw(12, $s);
    
    &::before {
      inset: vw(-1, $s);
      border-radius: vw(12, $s);
    }
    .title {
      font-size: vw(16, $s);
      line-height: vw(24, $s);
      gap: vw(4, $s);
      padding-block: vw(12, $s);
    }
    .number {
      font-size: vw(24, $s);
      line-height: vw(32, $s);
      padding-block: vw(2, $s);
    }
    .percent {
      margin-block-start: vw(4, $s);
      padding-block-end: vw(2, $s);
    }
    .bg-img {
      inset: vw(23, $s) vw(24, $s) vw(23, $s) auto;
    }
  }
}

.ar-customer-statistical-card {
  position: relative;
  color: #FFFFFF;
  background: linear-gradient(94.38deg, #1890FF 0%, #005DB3 99.66%);

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
  }
  .title {
    font-family: var(--font-medium);
    display: flex;
    align-items: center;
  }
  .number {
    font-family: var(--font-medium);
  }
  .bg-img {
    position: absolute;
  }
  &.sunset-orange {
    background: linear-gradient(94.38deg, #F59C23 0%, #F55E09 99.66%);
  }
  &.golden-purple {
    background: linear-gradient(94.38deg, #7B61FF 0%, #4F00B3 99.66%);
  }
}
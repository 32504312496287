@import '../../../../styles/root.scss';
@include vwConvert() using ($s) {
  .import-data-modal {
    &-content {
      padding: vw(24, $s);
      // max-height: calc(100vh - vw(252, $s));
    }
  }

  .upload-file-block {
    gap: vw(8, $s);
    padding: vw(12, $s);
    border-radius: vw(12, $s);

    &__head {
      .icon {
        height: vw(40, $s);
        aspect-ratio: 1;
        font-size: vw(24, $s);
      }
    }
    &__preview {
      gap: vw(8, $s);

      .item {
        gap: vw(8, $s);

        .svg {
          font-size: vw(24, $s);
        }
      }
    }
  }

  .import-data-step {
    &-2 {
      .filter-top {
        .ant-select {
          max-width: vw(210, $s);
        }
        .icon {
          padding-inline-start: vw(12, $s);
          margin-inline-start: vw(-2, $s);
        }
      }
    }
    &-4 {
      .exclamation-block {
        gap: vw(4, $s);

        svg {
          font-size: vw(36, $s);
        }
      }
    }
  }
}

.import-data-modal {
  &.ant-modal {
    .ant-modal-footer {
      justify-content: flex-end;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal {
      &-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 40px);
        overflow: hidden;
      }
      &-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }
  &-content {
    overflow-y: auto;
  }
}

.upload-file-block {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px #00000026;


  &__head {
    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #FE964A;
      background: var(--sunset-orange-1);
    }
  }
  &__preview {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      align-items: center;
      color: var(--neutral-gray-600);
    }
    .anticon-close-circle {
      color: var(--neutral-gray-400);
    }
  }
}

.import-data-step {
  &-2 {
    .filter-top {
      .ant-select {
        width: 100%;
      }
    }
  }
  &-4 {
    .exclamation-block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .w {
    &-630-px {
      width: vw(630, $s);
    }

    &-146-px {
      width: vw(146, $s);
    }

    &-129-px {
      width: vw(129, $s);
    }

    &-135-px {
      width: vw(135, $s);
    }

    &-72-px {
      width: vw(72, $s);
    }

    &-102-px {
      width: vw(102, $s);
    }

    &-132-px {
      width: vw(132, $s);
    }

    &-122-px {
      width: vw(122, $s);
    }

    &-163-px {
      width: vw(163, $s);
    }

    &-125-px {
      width: vw(125, $s);
    }

    &-83-px {
      width: vw(83, $s);
    }
  }

  .zise-filter.ant-table-wrapper .ant-table-filter-trigger {
    font-size: vw(12, $s);
  }

  .ant-dropdown {
    font-size: vw(14, $s);
    width: vw(159, $s);

    .ant-table-filter-dropdown {
      font-size: vw(14, $s);
      min-width: vw(120, $s);
      border-radius: vw(6, $s)
    }

    .ant-table-filter-dropdown .ant-dropdown-menu {
      max-height: vw(264, $s);
    }

    .ant-dropdown-menu {
      padding: vw(4, $s);
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
      padding: vw(5, $s) vw(12, $s);
      font-size: vw(14, $s);
      border-radius: vw(4, $s);
    }

    .ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
      padding: vw(7, $s) vw(8, $s);
      border-top: vw(1, $s) solid #f0f0f0
    }

    .ant-table-filter-dropdown .ant-checkbox-wrapper+span {
      padding-inline-start: vw(8, $s);
    }
  }

  .ant-checkbox-wrapper {
    font-size: vw(14, $s);

  }

  .ant-checkbox {
    font-size: vw(14, $s);
    border-radius: vw(4, $s);
  }

  .ant-checkbox .ant-checkbox-inner {
    width: vw(16, $s);
    height: vw(16, $s);
    border: vw(1, $s) solid #d9d9d9;
    border-radius: vw(4, $s);
  }

  .ant-checkbox .ant-checkbox-inner:after {
    width: vw(5.7142857142857135, $s);
    height: vw(9.142857142857142, $s);
    border: vw(2, $s) solid #fff;
    border-top: vw(0, $s);
    border-inline-start: vw(0, $s);
  }


  .ant-btn-sm {
    font-size: vw(14, $s);
    height: vw(24, $s);
    padding: 0 vw(7, $s);
    border-radius: vw(4, $s);
  }

  .shadow-dropdown-fliter {
    box-shadow: 0px vw(2, $s) vw(8, $s) 0px rgba(0, 0, 0, 0.15);

  }
}


.object-fit {
  &-cover {
    object-fit: cover;
  }

  &-contain {
    object-fit: contain;
  }
}

@include vwConvert() using ($s) {
  .rs-checkbox {
    .ant-checkbox {
      + span {
        padding-inline: vw(8, $s);
      }
      &-inner {
        height: vw(16, $s);
        width: vw(16, $s);
        border-radius: vw(2, $s);

        &::after {
          height: vw(10, $s);
          width: vw(7, $s);
          border-width: vw(2, $s);
          margin-block-start: vw(-1, $s);
        }
      }
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner:after {
          height: vw(8, $s);
          width: vw(8, $s);
          margin-block-start: 0;
          border-width: 0;
          border-radius: vw(1, $s);
        }
      }
    }
    &-group {
      &.vertical {
        gap: vw(12, $s);
      }
      .ant-checkbox-wrapper {
        padding-block: vw(5, $s);
      }
    }
    // &--sm {
    //   font-size: vw(12, $s);
    //   line-height: vw(20, $s);
    // }
  }
}

.rs-checkbox {
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  &:not(.ant-checkbox-check) {
    .ant-checkbox-inner {
      background: var(--neutral-gray-100);
    }
  }
  &.transparent {
    .ant-checkbox-inner {
      background: transparent;
    }
  }
  .ant-checkbox {
    font-family: inherit;
    font-size: inherit;
    color: inherit;

    &-checked {
      .ant-checkbox-inner {
        border-color: var(--primary-6);
        background-color: var(--primary-6);
      }
    }
  }
  &-group {
    font-family: inherit;
    font-size: inherit;
    color: inherit;

    &.vertical {
      display: flex;
      flex-direction: column;
    }
  }
}

@import  '../../../../styles//root.scss';

@include vwConvert() using($s) {
  .account-role-list {
    grid-template-columns: repeat(auto-fill, minmax(vw(385, $s), 1fr));
    gap: vw(18, $s);
  }
}

.account-role-list {
  display: grid;
}

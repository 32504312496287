@include vwConvert() using ($s) {
  .rs-divider {
    border-width: vw(1, $s);
  }
}

.rs-divider {
  &.primary-7 {
    border-color: var(--primary-7);
  }
}

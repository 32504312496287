@include vwConvert() using ($s) {
  .rs-radio {
    height: vw(32, $s);
    padding-inline: vw(4, $s);
    gap: vw(8, $s);
    font-size: vw(14, $s);

    .icon {
      margin-inline-end: vw(8, $s);
      img{
        width: vw(14, $s);
      }
    }
    &.ant-radio-button-wrapper {
      line-height: vw(32, $s);
      border-width: vw(1, $s);

      &:first-child {
        border-start-start-radius: vw(8, $s);
        border-end-start-radius: vw(8, $s);
      }
      &:last-child {
        border-start-end-radius: vw(8, $s);
        border-end-end-radius: vw(8, $s);
      }
    }
    &--button {
      padding: vw(12, $s);
      border-radius: vw(12, $s);
      border-inline-start-width: vw(1, $s);
      line-height: vw(22, $s);

      &:not(:first-child) {
        &::before {
          display: none;
        }
      }
      &.ant-radio-button-wrapper {
        &:not(:first-child) {
          border-inline-start-width: vw(1, $s) !important;
        }
        &:first-child {
          border-start-start-radius: vw(12, $s);
          border-end-start-radius: vw(12, $s);
        }
        &:last-child {
          border-start-end-radius: vw(12, $s);
          border-end-end-radius: vw(12, $s);
        }
      }
      &.vertical {
        height: vw(72, $s);
        padding: vw(8, $s);

        // span {
        //   gap: vw(4, $s);
        // }
        img {
          height: vw(32, $s);
        }
      }
      .anticon {
        margin-inline-end: vw(12, $s);
        font-size: vw(24, $s);

        img {
          width: vw(24, $s);
        }
      }
    }
    &-group {
      &--button {
        gap: vw(8, $s);
      }
      &-button {
        .ant-radio-button-wrapper {
          gap: vw(4, $s);
          padding: vw(5, $s) vw(16, $s);
          font-size: vw(14, $s);
          line-height: vw(22, $s);
          height: vw(32, $s);

          &:first-child {
            border-radius: vw(8, $s) 0 0 vw(8, $s);
          }
          &:last-child {
            border-radius: 0 vw(8, $s) vw(8, $s) 0;
          }
        }
      }
    }
  }
}

.rs-radio {
  font-family: inherit;
  color: var(--neutral-gray-700);
  text-align: center;

  &--button {
    text-align: start;
    height: auto;

    span {
      display: flex;
      align-items: center;
    }

    &.vertical {
      span {
        flex-direction: column;
      }
      img {
        aspect-ratio: 1;
      }
      .active {
        display: none;
      }
      .un-active {
        display: block;
      }
    }
  }
  &-group {
    font-family: inherit;
    color: var(--neutral-gray-700);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    &-button {
      font-family: var(--font-regular);
      text-align: center;

      .ant-radio-button-wrapper {
        font-family: inherit;

        &:not(.ant-radio-button-wrapper-checked) {
          color: var(--neutral-gray-700);
        }
        &-checked:not(.ant-radio-button-wrapper-disabled):hover {
          color: var(--primary-6);
          border-color: var(--primary-6);
        }
      }
    }
  }
  &.ant-radio-button-wrapper {
    &:hover,
    &-checked span {
      color: var(--primary-6);
    }
    &:not(:first-child) {
      border-inline-start-width: 0;
    }
    &-checked {
      .active {
        display: block;
      }
      .un-active {
        display: none;
      }
    }
  }
  .icon {
    vertical-align: middle;
  }
  &.volcano {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--volcano-6);
      background-color: var(--volcano-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--volcano-6);
    }
  }
  &.primary-5 {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--primary-5);
      background-color: var(--primary-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--primary-5);
    }
  }
  &.golden-purple {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--golden-purple-6);
      background-color: var(--golden-purple-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--golden-purple-6);
    }
  }
  &.magenta {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--magenta-6);
      background-color: var(--magenta-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--magenta-6);
    }
  }
}

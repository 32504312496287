@include vwConvert() using ($s) {
  .rs-modal {
    font-size: vw(14, $s);
    line-height: vw(22, $s);
    border-radius: vw(2, $s);

    .ant-modal {
      &-header {
        padding: vw(16, $s) vw(24, $s);
        box-shadow: 0 vw(-1, $s) 0 0 #F0F0F0 inset;
      }
      &-title {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
        padding-inline-end: vw(26, $s);
      }
      &-body {
        padding: vw(24, $s);
      }
      &-footer {
        padding: vw(8, $s) vw(24, $s);
        box-shadow: 0 vw(1, $s) 0 0 #F0F0F0 inset;

        .ant-btn {
          font-size: vw(14, $s);
          line-height: vw(22, $s);
        }
      }
      &-close {
        inset-block-start: vw(20, $s);
        inset-inline-end: vw(24, $s);

        &-x {
          font-size: vw(16, $s);
          line-height: vw(32, $s);
        }
      }
    }
    .ant-form {
      gap: vw(18, $s);
    }
    &.w-400 {
      width: vw(400, $s) !important;
    }
    &.w-824 {
      width: vw(824, $s) !important;
    }
    &.w-769 {
      width: vw(769, $s) !important;
    }
    &.w-1216 {
      width: vw(1216, $s) !important;
    }
    &.w-1110 {
      width: vw(1110, $s) !important;
    }
  }
}

.rs-modal {
  font-family: var(--font-regular);
  color: var(--neutral-gray-700);

  .ant-modal {
    &-body {
      font-size: inherit;
    }
    &-content {
      padding: 0;
      font-size: inherit;
    }
    &-header {
      margin-block-end: 0;
    }
    &-title {
      font-family: var(--font-medium);
      color: var(--neutral-gray-700);
      font-weight: normal;
    }
    &-footer {
      margin-block-start: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-btn {
        font-family: var(--font-regular);
      }
    }
    &-close {
      width: auto;
      height: auto;
      font-weight: normal;

      &:hover {
        color: var(--neutral-gray-700);
        background: transparent;
      }
    }
  }
  .ant-form {
    display: flex;
    flex-direction: column;

    &-item {
      margin-block-end: 0;
    }
  }
  &.footer-content-end {
    .ant-modal-footer {
      justify-content: flex-end;
    }
  }
  &-right {
    .ant-modal-wrap {
      text-align: end;
    }
    .ant-modal-header {
      margin-block-end: 0;
    }
    .ant-modal-content {
      height: 100vh !important;
      display: flex;
      flex-direction: column;
    }
    .ant-modal-body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}

.ant-modal {
  .ant-modal-header,
  .ant-modal-footer {
    margin: 0;
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using($s) {
  .custom-ant-tree {
    &.ant-tree {
      font-size: vw(14, $s);
      border-radius: vw(6, $s);

      .ant-tree-indent-unit {
        width: vw(24, $s);
      }

      .ant-tree-indent-unit:before {
        inset-inline-end: vw(12, $s);
        bottom: vw(-4, $s);
        border-inline-end: vw(1, $s) solid #d9d9d9;
      }

      .ant-tree-treenode {
        padding: 0 0 vw(4, $s) 0;
      }

      .ant-tree-treenode-draggable .ant-tree-draggable-icon {
        width: vw(24, $s);
        line-height: vw(24, $s);

      }

      .ant-tree-switcher {
        width: vw(24, $s);
        line-height: vw(24, $s);
        border-radius: vw(6, $s);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-tree-switcher-leaf-line:before {
        inset-inline-end: vw(12, $s);
        bottom: vw(-4, $s);
        margin-inline-start: vw(-1, $s);
        border-inline-end: vw(1, $s) solid #d9d9d9;
      }

      .ant-tree-switcher-leaf-line:after {
        width: vw(9.600000000000001, $s);
        height: vw(12, $s);
        border-bottom: vw(1, $s) solid #d9d9d9;
      }

      .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
        height: vw(12, $s) !important;
      }

      .ant-tree-switcher .ant-tree-switcher-icon {
        font-size: vw(16, $s);
      }

      .ant-tree-node-content-wrapper {
        line-height: vw(24, $s);
        min-height: vw(24, $s);
        padding: 0 vw(4, $s);
        border-radius: vw(6, $s);
      }
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-detail-table {
    .top {
      gap: vw(12, $s);

      .title {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }
      .control {
        gap: vw(12, $s);
      }
      .ant-input-search {
        width: vw(448, $s);
      }
    }
    .rs-select-flat {
      min-width: vw(68, $s);
    }
  }
}

.voucher-detail-table {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: var(--font-medium);
    }
    .control {
      display: grid;
      grid-auto-flow: column;
    }
  }
}

@import '../../styles/root.scss';
@include vwConvert() using ($s) {
  .warranty-history-vertical {
    gap: vw(12, $s);
  }
}

.warranty-history-vertical {
  display: flex;
  flex-direction: column;
}
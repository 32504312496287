@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-detail-card {
    gap: vw(24, $s);
    padding: vw(16, $s);

    &::before,
    &::after {
      border-radius: vw(12, $s);
    }
    &::after {
      inset: vw(1, $s);
    }
    .ant-switch {
      margin-block-start: vw(4, $s);
    }
    .bg-img {
      inset: vw(29, $s) vw(45, $s) vw(29, $s) auto;
      height: vw(180, $s);
      width: vw(271, $s);
    }
    .content {
      gap: vw(16, $s);
    }
    .top {
      gap: vw(12, $s);

      &-item {
        gap: vw(8, $s);

        &:first-child {
          &::after {
            border-inline-end-width: vw(1, $s);
            height: vw(16, $s);
          }
          .anticon-global {
            font-size: vw(20, $s);
          }
        }
      }
      .title {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }
    }
    .bottom {
      grid-template-columns: vw(348, $s) vw(348, $s);
      gap: vw(52, $s);
      max-width: vw(748, $s);
    }
    .info-list {
      &-item {
        gap: vw(12, $s);
        padding-block: vw(4, $s);
      }
    }
  }
}

.voucher-detail-card {
  position: relative;
  display: flex;

  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }
  &::before {
    background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
    inset: 0;
  }
  &::after {
    background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%), linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);
  }
  .bg-img {
    position: absolute;
    pointer-events: none;
    z-index: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .top {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;

      &:first-child {
        &::after {
          content: "";
          border-inline-end-style: solid;
          border-inline-end-color: var(--neutral-gray-100);
        }
      }
    }
    .title {
      font-weight: var(--font-medium);
    }
  }
  .bottom {
    display: grid;
    grid-auto-columns: 1fr;
  }
  .info-list {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using($s) {
  .add-relative-modal {
    .ant-form.add-relative-form {
      gap: vw(10, $s);

      .search-with-select {
        .ant-select {
          flex-basis: vw(124, $s);
        }
      }
    }
    .gender-group {
      .rs-radio {
        padding-inline: vw(12, $s);
        min-width: vw(98, $s);

        .inner-text {
          gap: vw(8, $s);
        }
      }
    }
  }
}

.add-relative-modal {
  .ant-form.add-relative-form {
    &--2col {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .ant-form-item-vertical .ant-form-item-control {
      flex-basis: auto;
    }
    .ant-select-outlined:not(.ant-select-status-error) .ant-select-selector,
    .ant-input-search .ant-input,
    .ant-input-search .ant-input-search-button,
    .ant-picker {
      border-color: var(--neutral-gray-200);
    }
    .ant-select-selection-placeholder {
      color: var(--neutral-gray-400);
    }
    .search-with-select {
      .ant-select {
        flex-shrink: 0;

        &-selector {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .ant-input-search .ant-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .gender-group {
    display: inline-grid;

    .rs-radio {
      .inner-text {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}

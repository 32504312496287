@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-filter {
    grid-template-columns: vw(564, $s)  vw(256, $s) vw(111, $s) vw(106, $s) vw(107, $s) vw(339, $s);
    gap: vw(12, $s);
  }
}

.voucher-filter {
  display: grid;

  .ant-btn {
    font-family: var(--font-regular);
    font-size: inherit;
  }
}
@import '../../../styles/root.scss';

@include vwConvert() using($s) {
  .bg-items-role {
    background-image: url(../../../../public/images/exportImg/bg-item-accountRole.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.fix-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-columns: 1fr;
}

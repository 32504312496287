@import '../../../styles/root.scss';

@include vwConvert()using($s) {
  .custom-tree-select {
    &.ant-tree-select {
      &.ant-select-single {
        height: vw(32, $s);
      }

      &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #F2F4F7;
        background: #FCFCFD;
      }

      &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
        border: vw(1, $s) solid #ff4d4f !important;
      }

      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 vw(11, $s);
      }

      &.ant-select-single .ant-select-selector {
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
      }

      &.ant-select-single .ant-select-selector .ant-select-selection-search {
        inset-inline-start: vw(11, $s);
        inset-inline-end: vw(11, $s);
      }

      &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-inline-end: vw(18, $s);
      }

      &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: vw(30, $s);
        font-size: vw(14, $s);
      }

      &.ant-select .ant-select-arrow {
        font-size: vw(14, $s);
        inset-inline-end: vw(11, $s);
      }

      &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: vw(18, $s);
      }

      &.ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: vw(30, $s);
      }

      &.ant-select .ant-select-clear {
        font-size: vw(14, $s);
        inset-inline-end: vw(11, $s);
        margin-top: vw(-6, $s);
        width: vw(12, $s);
        height: vw(12, $s);
      }
    }
  }

  .custom-dropdown-tree-select {
    &.ant-tree-select-dropdown {
      padding: vw(8, $s) vw(4, $s);

      &.ant-select-dropdown {
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
      }

      &.ant-select-tree {
        font-size: vw(14, $s);
      }

      .ant-select-tree-list-holder {
        max-height: vw(256, $s) !important;
      }

      &.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-indent-unit {
        width: vw(24, $s);
      }

      &.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode {
        padding: 0 0 vw(4, $s) 0;
      }

      &.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-switcher {
        width: vw(24, $s);
        line-height: vw(24, $s);
        border-radius: vw(6, $s);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon {
        font-size: vw(14, $s);
      }

      &.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-node-content-wrapper {
        line-height: vw(24, $s);
        min-height: vw(24, $s);
        border-radius: vw(6, $s);
        padding: 0 vw(4, $s);
      }
    }
  }
  .label-he-thong {
    height: vw(22,$s);
    padding: 0 vw(8,$s);
    border-radius: vw(8,$s);
    border: vw(1,$s) solid #ADC6FF;
    color: #2F54EB;
    background-color: #F0F5FF;
    display: inline-block;
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {


  .border-end-table.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: vw(8, $s)
  }

  .pr {
    &-10-px {
      padding-right: vw(10, $s);
    }
  }

  .custome-select-item.ant-select-dropdown .ant-select-item {
    padding: vw(5, $s) vw(12, $s);
    font-size: vw(14, $s);
    border-radius: vw(4, $s);
    min-height: vw(32, $s);
  }

  .h-popUp .rc-virtual-list-holder {
    max-height: vw(256, $s) !important;
  }
}

.place-holder-input-667085.ant-input::placeholder {
  color: #667085 !important;
  font-weight: 400 !important;
  font-family: var(--font-regular) !important;
}

.w-fit-table .ant-table-column-sorters {
  width: fit-content;
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .app-header {
    height: vw(48, $s);
    box-shadow: 0 vw(-1, $s) 0 0 #F0F0F0 inset;
    
    &-right {
      padding: vw(12, $s) vw(16, $s);
      gap: vw(24, $s);

      .item {
        padding: 0 vw(12, $s);
      }
      .avatar-trigger {
        gap: vw(8, $s);

        img {
          height: vw(24, $s);
        }
      }
    }
  }
}

.app-header {
  display: flex;
  // box-shadow: 0px -1px 0px 0px #F0F0F0 inset;

  &-left {
    height: 100%;

    img {
      height: 100%;
    }

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    margin-inline-start: auto;
    height: 100%;
    
    .item {
      cursor: pointer;
      padding: 0;
    }
    .avatar-trigger {
      display: flex;
      align-items: center;

      img {
        flex-shrink: 0;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }
      .name {
        display: none;
      }
    }
    .menu-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .anticon {
      font-size: 20px;
    }
    
    @media screen and (min-width: 640px) {
      .anticon {
        font-size: inherit;
      }
    }
    @media screen and (min-width: 1200px) {
      .avatar-trigger {
        .name {
          display: block;
        }
      }
      .menu-trigger {
        display: none;
      }
    }
    @media screen and (max-width: 639px) {
      gap: 16px;
    }
  }
}
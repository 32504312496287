@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .text {
    &-10-px {
      font-size: vw(10, $s);
    }
  }

  .t-table-block {
    .ant-table-wrapper {
      .ant-table-column-sorter {
        margin-inline-start: vw(4, $s);
      }

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        font-size: vw(12, $s);
      }

      .ant-table-thead th.ant-table-column-sort {
        background: #096DD9;
      }

      .ant-table-column-sorter-down.active,
      .ant-table-column-sorter-up.active {
        color: #344054;
      }

      table tr td.ant-table-selection-column,
      .ant-table-selection-column {
        padding-inline-end: vw(8, $s);
        padding-inline-start: vw(8, $s);
      }

      table {
        border-radius: vw(8, $s) vw(8, $s) 0 0;
      }

      .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: vw(8, $s);
      }

      .ant-space-gap-col-middle {
        column-gap: vw(10, $s);
      }

      .ant-table-thead>tr>th {
        border-bottom: 0 solid #f0f0f0 !important;
        font-family: var(--font-medium);
        color: #fff;
        background-color: #096DD9;
        font-weight: 500;
        padding: vw(10, $s) vw(8, $s);
        white-space: nowrap;
        line-height: vw(24, $s);
      }

      .ant-table-tbody>tr>td {
        border-bottom: 0 solid #f0f0f0 !important;
        font-family: var(--font-regular);
      }

      .ant-table {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        color: #344054;
        font-family: var(--font-regular);
      }

      .ant-badge {
        font-size: vw(14, $s);
      }

      .ant-badge.ant-badge-status .ant-badge-status-text {
        margin-inline-start: vw(8, $s);
        font-size: vw(14, $s);
      }

      .ant-badge.ant-badge-status .ant-badge-status-dot {
        position: relative;
        top: vw(-1, $s);
        display: inline-block;
        width: vw(6, $s);
        height: vw(6, $s);
        vertical-align: middle;
        border-radius: 50%;
      }

      .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0;
      }

      .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: #096DD9;
      }

      .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: vw(8, $s);
      }

      table tr th.ant-table-selection-column {
        padding-inline-end: vw(8, $s);
        padding-inline-start: vw(8, $s);
      }

      .ant-table-selection-column {
        .ant-checkbox-wrapper {
          font-size: vw(14, $s);
          align-items: center;
        }
      }

      table tr th:nth-child(1) {
        width: vw(44, $s);
      }

      table tr th:nth-child(2) {
        width: vw(93, $s);
      }

      table tr th:nth-child(3) {
        width: vw(45, $s);
      }

      table tr th:nth-child(4) {
        width: vw(163.67, $s);
      }

      table tr th:nth-child(5) {
        width: vw(163.67, $s);
      }

      table tr th:nth-child(6) {
        width: vw(103, $s);
      }

      table tr th:nth-child(7) {
        width: vw(88, $s);
      }

      table tr th:nth-child(8) {
        width: vw(106, $s);
      }

      table tr th:nth-child(9) {
        width: vw(163.67, $s);
      }

      table tr th:nth-child(10) {
        width: vw(230, $s);
      }

      .ant-checkbox {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        &.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1890FF;
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: vw(1, $s) solid #d9d9d9;
          border-radius: vw(4, $s);

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }

      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            border: none;
          }
        }
      }

      .ant-table-tbody>tr>td {
        padding: vw(9, $s) vw(8, $s);
        // white-space: nowrap;
      }

      .ant-space-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        span {
          width: vw(24, $s);
          height: vw(24, $s);
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #D9D9D9;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: vw(14, $s);
        }
      }
    }

    .pagination-block {
      padding: vw(9, $s);

      .ant-pagination {
        text-align: center;
        font-size: vw(14, $s);
        font-family: var(--font-regular);
        color: #344054;

        .ant-select-single .ant-select-selector {
          color: #344054;

          .ant-select-selection-search {
            inset-inline-start: vw(14, $s);
            inset-inline-end: vw(14, $s);
          }
        }

        .ant-pagination-item-active a {
          color: #1890FF !important;
        }

        .ant-pagination-item a {
          color: #344054;
        }

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-prev {
          min-width: vw(32, $s);
          height: vw(32, $s);
          margin-inline-end: vw(8, $s);
          font-family: var(--font-regular);
          line-height: vw(30, $s);
          border: vw(1, $s) solid #F2F4F7;
          border-radius: vw(2, $s);
        }

        .ant-pagination-item-active {
          border-color: #1890FF;
          font-family: var(--font-medium);
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          font-size: vw(12, $s);
        }

        .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
          font-size: vw(12, $s);
        }

        .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
          letter-spacing: vw(2, $s);
        }

        .ant-pagination-jump-next {
          min-width: vw(32, $s);
          height: vw(32, $s);
          line-height: vw(32, $s);
          border-radius: vw(6, $s);
          margin-inline-end: vw(8, $s);

          .ant-pagination-item-container .ant-pagination-item-link-icon {
            font-size: vw(12, $s);
          }
        }

        .ant-select-single {
          font-size: vw(14, $s);
          height: vw(32, $s);

          &.ant-select-show-arrow .ant-select-selection-item {
            padding-inline-end: vw(18, $s);
            line-height: vw(30, $s);
          }

          .ant-select-selector .ant-select-selection-item {
            line-height: vw(30, $s);
          }

          .ant-select-selector {
            font-size: vw(14, $s);
            border-radius: vw(6, $s);
            padding: 0 vw(11, $s) !important;
          }

          .ant-select-dropdown {
            font-size: vw(14, $s);
            padding: vw(4, $s);
            border-radius: vw(8, $s);
            box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);

            .ant-select-item {
              font-size: vw(14, $s);
            }
          }
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
          line-height: vw(30, $s);
          font-size: vw(14, $s);
        }

        .ant-pagination-options {
          margin-inline-start: vw(0, $s);
        }

        :where(.css-dev-only-do-not-override-1k979oh).ant-select .ant-select-arrow {
          inset-inline-end: vw(11, $s);
          height: vw(12, $s);
          margin-top: vw(-6, $s);
          font-size: vw(12, $s);
        }

        .ant-pagination-options-quick-jumper {
          height: vw(32, $s);
          margin-inline-start: vw(8, $s);
          line-height: vw(32, $s);

          input {
            width: vw(50, $s);
            padding: vw(4, $s) vw(11, $s);
            font-size: vw(14, $s);
            border-radius: vw(6, $s);
            border-width: vw(1, $s);
            height: vw(32, $s);
            margin-inline-start: vw(8, $s);
            margin-inline-end: vw(8, $s);
          }
        }
      }
    }
  }

  .position-dropdow-filter.ant-select-dropdown {
    --arrow-x: vw(88.3359375, $s) !important;
    --arrow-y: vw(-2, $s) !important;
    width: vw(352, $s) !important;
    inset: vw(185, $s) auto auto vw(703, $s) !important;
    border-radius: vw(8, $s);
    padding: vw(4, $s);
    line-height: vw(1.5714285714285714, $s);
    font-size: vw(14, $s);
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
  }
}

.place-holder-344054.ant-select .ant-select-selection-placeholder {
  color: #344054;
  font-family: var(--font-regular) !important;
  font-weight: 400;
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .marketing-campaigns-content {
    margin: vw(24, $s) 0;
    .overview {
      display: flex;
      .total {
        background: linear-gradient(94.38deg, #1890FF 0%, #005DB3 99.66%);
        padding: vw(21, $s) vw(24, $s);
        border-radius: vw(12, $s);
        position: relative;
        flex: 0 0 auto;
        max-width: vw(576, $s);
        width: 100%;

        .bg-img-total {
          width: vw(126, $s);
          height: vw(84, $s);
          position: absolute;
          top: vw(24, $s);
          right: vw(24, $s);
          z-index: 1;
          pointer-events: none;
          &.ctrl-img-vouche {
            top: 50%;
            transform: translateY(-50%);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .title-block {
          display: flex;
          align-items: center;
          gap: vw(4, $s);
          .title {
            font-size: vw(16, $s);
            font-family: var(--font-medium);
            color: #fff;
            font-weight: 500;
            line-height: vw(24, $s);
          }
          .icon-svg {
            font-size: vw(16, $s);
            display: flex;
            align-items: center;
          }
        }
        .list-item {
          display: grid;
          grid-template-columns: repeat(3, vw(164.4, $s));
          margin-top: vw(15, $s);
          .one-item {
            color: #fff;
            display: flex;
            flex-direction: column;
            gap: vw(4, $s);
            .title {
              color: #F9FAFB;
            }
            .number {
              color: #fff;
              font-size: vw(24, $s);
              line-height: vw(32, $s);
              font-family: var(--font-medium);
              padding-top: vw(5, $s);
              padding-bottom: vw(5, $s);
            }
          }
        }
      }
      .chart {
        flex: 0 0 auto;
        max-width: calc(100% - vw(576, $s));
        width: 100%;
        >div>div {
          height: vw(222, $s) !important;
          font-size: vw(14, $s);
          color: red;
        }
      }
    }
    .title-date {
      margin-top: vw(48, $s);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: vw(22, $s);
      .title-block {
        font-size: vw(16, $s);
        font-weight: 500;
        line-height: vw(24, $s);
        span {
          font-family: var(--font-bold);
          color: #344054;
          font-weight: 600;
          margin-left: vw(4, $s);
          margin-right: vw(4, $s);
        }
      }
    }
    .ctrl-table-nkh {
      display: flex;
      justify-content: space-between;
      a {
        color: #1890FF;
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        font-family: var(--font-regular);
        font-weight: 400;
        padding: 0 vw(15, $s);
      }
    }
  }
  .t-chart-block {
    .canvasjs-chart-credit {
      display: none;
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-form {
    .ant-form-item-label {
      padding: 0 0 vw(16, $s);
      label {
        font-size: vw(16, $s);
        color: #344054;
        font-family: var(--font-medium);
        line-height: vw(24, $s);
        &::before {
          display: none !important;
        }
      }
    }
  }

  .t-textarea {
    min-height: vw(130, $s) !important;
    font-size: vw(14, $s);
    font-family: var(--font-regular);
    padding: vw(5, $s) vw(12, $s);
  }

  .d-none-rq {
    .ant-form-item {
      font-size: vw(14, $s);
      margin-bottom: vw(16, $s);

      .ant-form-item-label>label {
        font-size: vw(16, $s);
        color: #344054;
        font-family: var(--font-medium);
        line-height: vw(24, $s);
        &::before {
          display: none !important;
        }
      }
    }
    &.ant-form-vertical {
      .ant-form-item-label {
        padding-bottom: vw(18, $s);
      }
      .ant-form-item-control {
        input, textarea {
          background-color: #FCFCFD;
          display: flex;
          align-items: center;
          border-color: #F2F4F7;
          border-width: vw(1, $s);
          border-radius: vw(8, $s);
        }
        input {
          height: vw(40, $s);
          padding: 0 vw(12, $s);
        }
        textarea {
          min-height: vw(32, $s);
          font-size: vw(14, $s);
          font-family: var(--font-regular);
          padding: vw(5, $s) vw(12, $s);
        }
      }
    }
  }

  .form-block {
    &.form-benefit {
      gap: vw(18, $s);
    }
  }

  .filter-setting {
    .footer-filter-block.ctrl-btn-ft-benefit {
      .btn-block {
        height: vw(48, $s);
        padding: vw(8, $s) vw(24, $s);
        border-top: vw(1, $s) solid #f0f0f0;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        gap: vw(17, $s);

        .btn {
          display: flex;
          gap: vw(8, $s);
          align-items: center;
          justify-content: center;
          border-radius: vw(8, $s);
          border: vw(1, $s) solid transparent;
          height: vw(32, $s);
          color: #98A2B3;
          line-height: vw(22, $s);
          cursor: pointer;

          img {
            width: vw(14, $s);
            height: vw(14, $s);
            object-fit: contain;
          }

          &.btn-primary {
            color: #fff;
            background-color: #096DD9;
            padding: vw(4, $s) vw(15, $s);
          }
        }
      }
    }
  }
}

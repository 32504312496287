@import '../../../styles/root.scss';

@include vwConvert() using($s) {
  .create-owned-car-modal {
    .ant-form.create-owned-car-form {
      gap: vw(10, $s);

      .search-with-select {
        .ant-select {
          flex-basis: vw(124, $s);
        }
      }
    }
    .car-img {
      height: vw(104, $s);
      width: vw(104, $s);
    }
  }
}

.w-100 {
  width: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.br-left-none {
  input {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

.br-right-none {
  .ant-select-selector {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: none;
  }
}

.create-owned-car-modal {
  .ant-form.create-owned-car-form {
    &--2col {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .ant-form-item-vertical .ant-form-item-control {
      flex-basis: auto;
    }
    .ant-select-selector,
    .ant-input-search .ant-input,
    .ant-input-search .ant-input-search-button,
    .ant-picker {
      border-color: var(--neutral-gray-200);
    }
    .ant-select-selection-placeholder {
      color: var(--neutral-gray-400);
    }
    .search-with-select {
      .ant-select {
        flex-shrink: 0;

        &-selector {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .ant-input-search .ant-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .car-img {
    object-fit: contain;
  }
}

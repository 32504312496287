@import '../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .customer-add-content {
    .mw-1062 {
      max-width: vw(1062, $s);
    }
  }
}

.customer-add-content {
  .order-history-card {
    margin-block-start: 0;
  }
  .mw-1062 {
    width: 100%;
    margin-inline: auto;
  }
  .btn-add-relative {
    color: var(--primary-7) !important;
    background: var(--primary-1);
    border-color: var(--primary-1);
    flex-direction: row-reverse;
  }
    .rs-select.show-tags-on-bottom .ant-select-selection-overflow {
      top: auto;
    }
}

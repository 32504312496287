@import '../../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .double-circle-chart {
    /* transform: rotate(-90deg); */

  }

  .radius-chart-1 {
    height: vw(239.67, $s);
    width: vw(240, $s);
  }

  .radius-chart-2 {
    height: vw(162.28, $s);
    width: vw(162.06, $s);
  }

  .double-circle-chart-background {
    position: relative;
    background-color: rgba(24, 144, 255, 0.3);
    border-radius: 100%;
    border: 1px solid #1890FF;
    left: 0;
  }

  .double-circle-chart-background-2 {
    background-color: rgba(47, 194, 91, 0.3);
    border-radius: 100%;
    border: 1px solid #2FC25B;
    position: absolute;
    top: 50%;
    left: 100%;
  }

  .group-1 {
    height: vw(8, $s);
    width: vw(8, $s);
    border-radius: 100%;
    background-color: #1890FF;
  }

  .group-2 {
    height: vw(8, $s);
    width: vw(8, $s);
    border-radius: 100%;
    background-color: #2FC25B;
  }


}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .information-tab {
    gap: vw(12, $s);

    &__head {
      gap: vw(12, $s);

      .avatar {
        &-block {
          gap: vw(24, $s);

          &__avatar {
            height: vw(64, $s);
          }

          &__content {
            gap: vw(4, $s);

            .name {
              font-size: vw(16, $s);
              line-height: vw(24, $s);
            }
          }
        }
      }

      .rank {
        gap: vw(4, $s);
        font-size: vw(12, $s);
        line-height: vw(20, $s);

        &-block {
          height: vw(24, $s);

          &::after {
            border-inline-end: vw(1, $s) solid var(--neutral-gray-100);
            margin-inline-start: vw(8, $s);
            height: vw(16, $s);
          }
          .item {
            gap: vw(4, $s);

            &:not(:last-child) {
              &::after {
                height: vw(16, $s);
                width: vw(1, $s);
                margin-inline: vw(24, $s);
              }
            }
          }
        }

        &-img {
          height: vw(24, $s);
        }
      }
    }

    &__content {
      // gap: vw(24, $s);

      &-left {
        // width: vw(400, $s);
        // width: vw(712, $s);

        .block {
          padding-block: vw(4, $s);
        }

        .vip-block {
          gap: vw(12, $s);
          padding-inline: vw(24, $s);

          &__img {
            height: vw(24, $s);
          }
        }
      }

      &-right {
        // width: vw(768, $s);
        // width: vw(400, $s);
        gap: vw(18, $s);
      }

      .information-table-show {
        // gap: vw(24, $s);
        padding: vw(12, $s);
        border-radius: vw(12, $s);

        .item {
          min-height: vw(38, $s);
          padding-block: vw(8, $s);

          &-left {
            max-width: vw(120, $s);
          }

          &-right {
            max-width: vw(183, $s);

            &.flex-column {
              gap: vw(12, $s);
            }

            .first-item-of-list {
              gap: vw(12, $s);
            }
          }
        }
      }

      .card-block {
        gap: vw(12, $s);
      }
    }
  }
}

.mt-12 {
  margin-top: 12px;
}

.information-tab {
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    justify-content: space-between;

    .avatar {
      &-block {
        display: flex;
        align-items: center;

        &__avatar {
          aspect-ratio: 1;
        }

        &__content {
          display: flex;
          flex-direction: column;

          .name {
            color: var(--neutral-gray-700);
            font-family: var(--font-medium);
          }
        }
      }
    }

    .rank {
      display: flex;
      align-items: center;

      &-block {
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: block;
        }
        &.hidden {
          display: none;
        }
        .item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            &::after {
              content: "";
              display: block;
              background: var(--neutral-gray-200);
            }
          }
        }
      }

      &-img {
        aspect-ratio: 1;
      }
    }
  }

  &__content {
    display: flex;
    // justify-content: space-between;
    // gap: #{calc((80 * 100%) / 1192)};
    gap: clamp(40px, 5.6vw, 80px);

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
    }

    &-left {
      // width: #{calc((712 * 100%) / 1192)};
      flex-grow: 1;

      .vip-block {
        display: flex;
        align-items: center;

        &__img {
          aspect-ratio: 1;
        }

        .ant-switch {
          margin-inline-start: auto;
        }
      }
    }

    &-right {
      width: #{calc((400 * 100%) / 1192)};
    }

    .information-table-show {
      display: flex;
      flex-direction: column;
      background: #F9FAFB;

      .item {
        display: flex;
        justify-content: space-between;
        // align-items: center;

        &-right {
          text-align: right;

          &.flex-column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .first-item-of-list {
            display: flex;
          }
        }
      }
    }

    .card-block {
      // display: flex;
      // flex-direction: column;
      display: grid;

      &__content {
        overflow: hidden;
      }
    }

    @media screen and (max-width: 1199px) {
      flex-wrap: wrap;

      &-left,
      &-right {
        width: 100%;
      }
    }
  }
}

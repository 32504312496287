@include vwConvert() using ($s) {
  .rs-progress {
    .ant {
      &-progress {
        &-bg {
          height: vw(8, $s) !important;
        }
      }
    }
  }
}

.rs-progress {
  .ant {
    &-progress {
      &-inner {
        background: #F5F5F5;
      }
      &-outer {
        height: auto;
      }
    }
  }
  &.ant-progress {
    &-status-normal {
      font-size: 0;
      line-height: 1;

      .ant-progress-inner {
        display: block;
      }
    }
  }
  &.primary {
    .ant-progress {
      &-bg,
      &-success-bg {
        background: linear-gradient(to right, #2094ff, #cbe2f7);
      }
    }
    &.ant-progress-status-success .ant-progress-bg {
      background: linear-gradient(90deg, #15A245 50%, #52C41A 87.81%);
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .filter-setting {
    .list-add-setting-show {
      .t-pl-8 {
        padding-left: vw(8,$s);
      }
      .ant-checkbox-group {
        .ant-checkbox-wrapper {
          gap: 0 !important;
        }
      }

      .ant-checkbox-wrapper {
        display: flex;
      }
      .ant-checkbox-wrapper {
        padding: vw(0, $s) vw(12, $s);
        height: vw(32, $s);
        display: flex;
        align-items: center;
        span:last-child {
          margin-left: vw(8, $s);
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .ant-checkbox-group {
        gap: 0;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        border-bottom: none !important;
        border-right: none !important;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: #f1f5f9 !important;
      }
      .ant-checkbox-inner {
        margin-left: 0 !important;
      }
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .arcc {
    &-content {
      gap: vw(24, $s);

      .app-breadcrumb {
        &-block {
          margin-block-end: vw(-8, $s);
        }
      }
      .arcc-filter {
        margin-block-end: vw(-6, $s);
      }
      .statistical-pie-chart {
        margin-block-end: vw(37, $s);

        .recharts {
          &-surface {
            .number {
              font-size: vw(24, $s);
              line-height: vw(29, $s);
            }
            .text {
              font-size: vw(14, $s);
              line-height: vw(22, $s);
            }
          }
          &-legend-wrapper {
            bottom: vw(-30, $s) !important;
          }
          &-default-legend {
            gap: vw(12, $s);
          }
        }
      }
      .quantity-request-chart {
        .recharts {
          &-legend-wrapper {
            transform: translateY(vw(-4, $s));
          }
        }
      }
      .arcc-support-pie-chart {
        .recharts-responsive-container {
          max-width: vw(600, $s);
        }
        // .recharts-legend-wrapper {
        //   right: 0;
        //   transform: translateX(vw(57, $s));
        // }
        .recharts-default-legend {
          gap: vw(6, $s);
        }
      }
    }
    &-grid-93 {
      gap: vw(24, $s);
      grid-template-columns: vw(805, $s) vw(363, $s);
    }
  }
}

.arcc {
  &-content {
    display: flex;
    flex-direction: column;

    .app-breadcrumb {
      &-title {
        padding-block-end: 0;
      }
    }
    .statistical-pie-chart {
      .recharts { 
        &-surface {
          .number {
            fill: var(--neutral-gray-700);
          }
          .text {
            fill: var(--neutral-gray-400);
          }
        }
        &-legend-wrapper {
          left: 50% !important;
          transform: translateX(-50%);
        }
        &-default-legend {
          display: flex;
          flex-direction: column;
        }
        &-legend-item {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-inline-end: 0 !important;
        }
      }
    }
    .quantity-request-chart {
      .recharts {
        &-text {
          fill: var(--neutral-gray-400);
        }
        &-legend-wrapper {
          top: 100%;
          bottom: auto !important;;
        }
      }
    }
    .arcc-support-pie-chart {
      .recharts-responsive-container {
        margin: 0 auto;
      }
      .recharts-legend-wrapper {
        right: 0 !important;
      }
      .recharts-default-legend {
        display: flex;
        flex-direction: column;
      }
    }
    .ant-tabs-top >.ant-tabs-nav::before, 
    .ant-tabs-top >div>.ant-tabs-nav::before {
      display: none;
    }
  }
  &-grid-93 {
    display: grid;
    grid-auto-flow: column;
  }
}
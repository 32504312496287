@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-result-card {
    border-radius: vw(12, $s);
    padding: vw(16, $s);
    gap: vw(12, $s);

    &::before {
      inset: vw(-1, $s);
      border-radius: vw(12, $s);
    }
    .block {
      &:not(:last-child) {
        padding-block-end: vw(12, $s);
        border-block-end-width: vw(1, $s);
      }
      .list {
        gap: vw(12, $s);
      }
      .item {
        gap: vw(12, $s);
        // padding-block: vw(4, $s);
        min-height: vw(30, $s);
      }
    }
    .title {
      font-size: vw(16, $s);
      line-height: vw(24, $s);
    }
    .discount-price {
      font-size: vw(20, $s);
      line-height: vw(28, $s);
    }
    .ant-btn {
      padding-block: vw(4, $s);
      font-size: vw(14, $s);
      line-height: vw(22, $s);
    }
  }
}

.voucher-result-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%),linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);

  &::before {
    content: "";
    background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
    position: absolute;
    z-index: -1;
  }
  .block {
    &:not(:last-child) {
      border-block-end-color: var(--primary-3);
      border-block-end-style: dashed;
    }
    .list {
      display: flex;
      flex-direction: column;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.justify-center {
        justify-content: center;
      }
    }
  }
  .title {
    font-family: var(--font-bold);
    padding-block-end: 0;
  }
  .discount-price {
    font-family: var(--font-bold);
    background: linear-gradient(94.38deg, #F59C23 0%, #F55E09 99.66%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
}

@include vwConvert() using ($s) {
  .rs-pagination {
    gap: vw(8, $s);
    font-size: vw(14, $s);

    .ant-pagination {
      &-total-text {
        font-size: var(--name);
        height: vw(32, $s);
        line-height: vw(32, $s);
      }
      &-prev, 
      &-next, 
      &-jump-prev,
      &-jump-next {
        height: vw(32, $s);
        min-width: vw(32, $s);
        line-height: vw(32, $s);
      }
      &-prev,
      &-next {
        .ant-pagination-item-link {
          font-size: vw(12, $s);
          border-color: var(--neutral-gray-100);
        }
      }
      &-jump-prev,
      &-jump-next {
        border-width: vw(1, $s);

        .ant-pagination-item-container .ant-pagination-item-link-icon {
          font-size: vw(12, $s);
        }
      }
      &-item {
        height: vw(32, $s);
        min-width: vw(32, $s);
        line-height: vw(32, $s);
        border-radius: vw(2, $s);

        a {
          padding-inline: vw(4, $s);
        }
      }
      &-options {
        margin-inline-start: vw(16, $s);

        &-quick-jumper {
          height: vw(32, $s);
          margin-inline-start: vw(8, $s);
          line-height: vw(32, $s);
          display: inline-flex;
          align-items: center;

          input {
            height: vw(32, $s);
            width: vw(50, $s);
            padding: vw(5, $s) vw(12, $s);
            border: vw(1, $s) solid var(--neutral-gray-100);
            border-radius: vw(2, $s);
            margin-inline: vw(8, $s);
            font-size: vw(14, $s);
          }
        }
        .ant-select-selection-search {
          inset-inline: vw(11, $s);
        }
        .rc-virtual-list-holder {
          max-height: vw(256, $s) !important;
        }
      }
    }
    &.ant-pagination-simple {
      .ant-pagination {
        &-prev,
        &-next {
          height: vw(24, $s);
          min-width: vw(32, $s);

          .ant-pagination-item-link {
            height: vw(24, $s);
            font-size: vw(12, $s);
          }
        }
        &-simple-pager {
          height: vw(24, $s);

          input {
            border-width: vw(1, $s);
            width: vw(48, $s);
            border-radius: vw(2, $s);
          }
        }
        &-slash {
          margin-inline: vw(13, $s) vw(10, $s);
        }
      }
    }
    .ant-select {
      &-single {
        height: vw(32, $s);

        .ant-select {
          &-selector {
            border-width: vw(1, $s);
            border-radius: vw(2, $s);
            font-size: vw(14, $s);
          }
          &-selection-item,
          &-selection-placeholder {
            padding: vw(4, $s) vw(28, $s) vw(4, $s) vw(12, $s);
          }
          &-arrow {
            font-size: vw(12, $s);
            height: vw(12, $s);
            margin-top: vw(-6, $s);
            inset-inline-end: vw(12, $s);
          }
        }
      }
      // &-dropdown {
      //   .rc-virtual-list-holder {
      //     max-height: vw(256, $s) !important;
      //   }
      // }
    }
  }
}

.rs-pagination {
  color: var(--neutral-gray-700);
  font-family: var(--font-regular);
  line-height: inherit;
  // display: inline-flex;
  // align-items: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;

  .ant-pagination {
    &-total-text {
      margin-inline-end: 0;
    }
    &-prev, 
    &-next, 
    &-jump-prev,
    &-jump-next {
      font-family: inherit;
      color: inherit;
      margin: 0;
    }
    &-prev,
    &-next {
      .ant-pagination-item-link {
        border-color: var(--neutral-gray-100);
      }
    }
    &-jump-prev,
    &-jump-next {
      border-color: var(--neutral-gray-100);
      border-style: solid;
      
      .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: var(--primary-6);
      }
    }
    &-item {
      font-family: inherit;
      border-color: var(--neutral-gray-100);
      background-color: #FCFCFD;
      margin-inline-end: 0;

      &-active {
        color: var(--primary-6);
        border-color: var(--primary-6);
        font-family: var(--font-medium);
        font-weight: normal;
      }
      a {
        color: inherit;
      }
    }
    &-quick-jumper {
      input {
        background-color: #FCFCFD;
      }
    }
  }
  &.ant-pagination-simple {
    .ant-pagination {
      &-prev,
      &-next {
        color: inherit;
        margin-inline-end: 0;
      }
      &-simple-pager {
        margin-inline-end: 0;

        input {
          padding: 0;
          margin-inline-end: 0;
        }
      }
    }
  }
  .ant-select {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
  
    .ant-select {
      &-selection {
        &-placeholder {
          color: inherit;
        }
      }
      &-arrow {
        color:  var(--neutral-gray-700);
      }
      &-selector {
        padding: 0;
        border-color: var(--neutral-gray-100);
      }
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {

  .custom-input-card {
    .ant-input {
      font-family: var(--font-regular);

    }

    &.ant-input-affix-wrapper {
      padding: vw(4, $s) vw(11, $s);

      .ant-input-prefix {
        margin-inline-end: vw(4, $s);
      }
    }
  }

  .custome-select-h-10 {
    &.ant-select-single {
      font-size: vw(14, $s);
      height: vw(40, $s);
      width: 100%;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #ff4d4f;
      background: white;
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      background: #FCFCFD;
      border-color: #F2F4F7;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &.ant-select-single .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(32, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      font-size: vw(12, $s);
      color: #98A2B3;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-arrow {
      color: #ff4d4f !important;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: vw(30, $s);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: vw(30, $s);
    }
  }

  .t-ctrl-date-picker-bg-FCFCFD {
    .ant-picker {
      border-width: vw(1, $s);
      border-color: #F2F4F7;
      padding: vw(4, $s) vw(11, $s) vw(4, $s);
      font-size: vw(14, $s);
      border-radius: vw(8, $s);
      font-family: var(--font-regular);
      width: 100%;
      color: #000000D9;
      opacity: 1 !important;
      background: #FCFCFD;
      height: vw(40, $s);

      .anticon,
      .ant-picker-separator {
        color: #00000040;
      }

      .ant-picker-separator {
        height: vw(16, $s);
        font-size: vw(16, $s);
      }

      .ant-picker-input>input {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        min-width: vw(1, $s);
      }
    }

    .ant-picker-range {
      .ant-picker-range-separator {
        padding: 0 vw(8, $s);
      }

      .ant-picker-clear {
        inset-inline-end: vw(11, $s);
      }
    }
  }

}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .arc-content {
    gap: vw(18, $s);

    .grid-2-cols {
      gap: vw(18, $s);

      > div {
        flex: 0 0 calc(50% - vw(9, $s));
        max-width: calc(50% - vw(9, $s));
      }
    }
  }
}

.arc {
  &-content {
    display: flex;
    flex-direction: column;

    .grid-2-cols {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-auto-columns: 1fr;
      display: flex;
    }
  }
}
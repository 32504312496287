@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .my-custom-tooltip {
    .ant-tooltip-inner {
      background-color: #E6F7FF;
      color: #667085;
      border-radius: vw(8, $s);
      width: vw(200, $s);
      height: auto;
      font-size: vw(14, $s);
      min-height: vw(32, $s);
      padding: vw(6, $s) vw(8, $s);
    }
    .ant-tooltip-arrow:before {
      background-color: #E6F7FF;
      width: vw(16, $s);
      height: vw(8, $s);
    }
  }

  .t-title-back-button-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: vw(-54, $s);
    margin-bottom: vw(12, $s);
    .btn-input {
      display: flex;
      gap: vw(8, $s);
      border: vw(1, $s) solid #EAECF0;
      height: vw(32, $s);
      padding: vw(0, $s) vw(15, $s);
      align-items: center;
      border-radius: vw(6, $s);
      cursor: pointer;
      white-space: nowrap;
      &.btn-primary {
        background-color: #096DD9;
        color: #fff;
        border: vw(1, $s) solid #096DD9;
      }
      &.t-px-8{
        padding: vw(8, $s);
      }
    }
  }
  .colum2-1fr-400 {
    display: grid;
    grid-template-columns: 1fr vw(400, $s);
    gap: 32px;

    .right-content {
      width: vw(400, $s);
      z-index: 98;
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      top: 0;
      right: 0;
      background-color: #fff;

      .ant-form {
        font-size: vw(14, $s);
      }

      label {
        font-size: vw(16, $s);
        color: #344054;
        font-family: var(--font-medium);
        line-height: vw(24, $s);
        &::before {
          display: none !important;
        }
      }

      .status-add {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: vw(18, $s);
      }

      .title-tooltips {
        display: flex;
        gap: vw(8, $s);
        align-items: center;
        margin-bottom: vw(4, $s);
        .anticon {
          color: #98A2B3;
        }
      }

      .ant-checkbox+span {
        padding-inline-start: vw(0, $s);
        padding-inline-end: vw(0, $s);

        .text {
          padding-left: 0;
        }
      }

      .title-add-filter {
        padding: vw(12, $s) vw(24, $s);
        border-bottom: vw(1, $s) solid #f0f0f0;
        font-size: vw(16, $s);
        font-weight: 500;
        line-height: vw(22, $s);
        color: #344054;
        font-family: var(--font-medium);
        min-height: vw(48, $s);
      }

      .content-filter-setting {
        padding: vw(24, $s);

        .title-content {
          font-size: vw(16, $s);
          line-height: vw(24, $s);
          color: #344054;
          font-weight: 500;
          font-family: var(--font-medium);
          margin-bottom: vw(18, $s);
        }
      }

      .footer-filter-block {
        .btn-block {
          height: vw(48, $s);
          padding: vw(8, $s) vw(24, $s);
          border-top: vw(1, $s) solid #f0f0f0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: vw(16, $s);
        }
      }
    }

    .btn {
      display: flex;
      gap: vw(8, $s);
      align-items: center;
      justify-content: center;
      border-radius: vw(8, $s);
      border: vw(1, $s) solid #EAECF0;
      height: vw(32, $s);
      color: #000000D9;
      line-height: vw(22, $s);
      cursor: pointer;

      &.btn-primary-none-bg {
        color: #096DD9;
        border-color: #096DD9;
        border-style: dashed;
        font-size: vw(14, $s);
      }

      img {
        width: vw(14, $s);
        height: vw(14, $s);
        object-fit: contain;
      }

      &.btn-primary {
        color: #fff;
        background-color: #096DD9;
      }
    }

    .form-benefit {
      margin-bottom: vw(12, $s);
    }

    .filter-select {
      max-width: vw(363, $s);
      width: 100%;
      height: vw(32, $s);
      position: relative;
      .btn-select-show {
        border-radius: vw(8, $s);
        border: vw(1, $s) solid #F2F4F7;
        padding: vw(4, $s) vw(11, $s);
        height: vw(32, $s);
        font-size: vw(14, $s);
        cursor: pointer;
        span {
          color: #344054;
          display: block;
        }
      }

      .icon-down, .icon-close {
        font-size: vw(10, $s);
        color: #8a8a8b;
        position: absolute;
        top: 50%;
        right: vw(11, $s);
        transform: translateY(-50%);
      }

      .icon-down {
        &.active {
          display: none;
        }
      }

      .icon-close {
        display: none;
        &.active {
          display: block;
        }
        span {
          color: #ff0000;
        }
      }

      .list-filter {
        position: absolute;
        top: 100%;
        z-index: 5;
        padding: vw(8, $s);
        border-radius: vw(6, $s);
        box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
        width: 100%;
        margin-top: vw(12, $s);
        display: none;
        background-color: #fff;
        &.active {
          display: block;
        }
        input {
          margin-bottom: vw(8, $s);
        }
        .list-item {
          max-height: vw(320, $s);
          overflow-y: auto;
        }
        .ant-input, .item-select {
          border-radius: vw(2, $s);
          padding: vw(5, $s) vw(12, $s);
        }
        .item-select {
          margin-bottom: vw(8, $s);
          display: flex;
          color: #667085;
          gap: vw(8, $s);
          align-items: center;
          justify-content: space-between;
          padding: vw(5, $s) vw(12, $s);
          font-size: vw(14, $s);

          .icon-left {
            font-size: vw(12, $s);
            display: flex;
            gap: vw(8, $s);
          }
          .icon-right {
            font-size: vw(12, $s);
            color: #FF7875;
          }
        }
        .btn-filter {
          border-radius: vw(6, $s);
          border: vw(1, $s) solid #F2F4F7;
          padding: vw(4, $s) vw(11, $s);
          height: vw(32, $s);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: #096DD9;
          color: #fff;
          gap: vw(10, $s);
          font-size: vw(14, $s);
          .icon-btn-filter {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  #dynamic_form_add_seclect {
    .w-100 {
      width: 100%;
    }
    .ant-space {
      font-size: vw(14, $s);
      margin-bottom: vw(12, $s);
      display: flex;
      align-items: center;
      gap: vw(8,$s);
      .ant-space-item {
        margin-bottom: 0;
        &:first-child {
          flex: 0 0 auto;
          max-width: vw(32, $s);
          width: 100%;
        }
        &:last-child {
          flex-grow: 1;
        }
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .anticon-delete {
        width: vw(32, $s);
        height: vw(32, $s);
        border-radius: vw(6, $s);
        border: vw(1, $s) solid #F2F4F7;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F5222D;
        font-size: vw(16, $s);
      }
    }
  }

  .t-hd-table.v2{
    justify-content: flex-start;
  }
}

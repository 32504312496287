@include vwConvert() using ($s) {
  .rs-date-picker {
    padding: vw(4, $s) vw(12, $s);
    border-radius: vw(6, $s);
    border-width: vw(1, $s);
    height: vw(32, $s);
    font-size: vw(14, $s);
    line-height: vw(22, $s);

    &-dropdown {
      .ant-picker {
        &-header {
          button {
            line-height: vw(40, $s);

            &:not(:first-child) {
              margin-inline-start: vw(8, $s);
            }
          }
        }
        &-next-icon,
        &-prev-icon,
        &-super-next-icon,
        &-super-prev-icon {
          width: vw(7, $s);
          height: vw(7, $s);

          &::before,
          &::after {
            width: vw(7, $s);
            height: vw(7, $s);
          }
        }
        &-content th {
          height: vw(36, $s);
          width: vw(36, $s);
        }
        &-date-panel {
          width: vw(288, $s);

          .ant-picker-body {
            padding: vw(8, $s) vw(18, $s);
          }
        }
        &-cell {
          padding-block: vw(6, $s);

          &::before {
            height: vw(24, $s);
          }
        }
        &-cell .ant-picker-cell-inner,
        &-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
          height: vw(24, $s);
          width: vw(24, $s);
          line-height: vw(24, $s);

          &::before {
            border-width: vw(1, $s);
          }
        }
        &-prev-icon::before,
        &-next-icon::before,
        &-super-prev-icon::before,
        &-super-next-icon::before {
          border-block-start-width: vw(1.5, $s);
          border-inline-start-width: vw(1.5, $s);
        }
        &-super-prev-icon::after,
        &-super-next-icon::after {
          top: vw(4, $s);
          inset-inline-start: vw(4, $s);
        }
        &-footer {
          border-inline-start-width: vw(1, $s);

          .ant-picker-ranges {
            padding-inline-start: vw(12, $s);

            > li {
              line-height: vw(38, $s);
            }
          }
        }
        &-container {
          border-radius: vw(6, $s);
          box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08),0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12),0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
        }
      }

      // MONTH PICKER
      .ant-picker-month-panel-container {
        box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08),0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12),0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
        // box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);

        .ant-picker {
          &-cell .ant-picker-cell-inner,
          &-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
            width: vw(60, $s);
          }
          &-prev-icon::before,
          &-next-icon::before,
          &-super-prev-icon::before,
          &-super-next-icon::before {
            border-block-start-width: vw(1.5, $s);
            border-inline-start-width: vw(1.5, $s);
          }
          &-super-prev-icon::after,
          &-super-next-icon::after {
            top: vw(4, $s);
            inset-inline-start: vw(4, $s);
          }
        }
        .ant-picker-cell .ant-picker-cell-inner,
        .ant-picker-content th,
        .ant-picker-content td {
          min-width: vw(24, $s);
        }
        .ant-picker-month-panel {
          width: vw(288, $s);

          .ant-picker-cell-inner {
            padding-inline: vw(8, $s);
            height: vw(24, $s);
            line-height: vw(24, $s);
            border-radius: vw(4, $s);
          }
        }
      }

      // TIME PICKER
      .ant-picker-time-panel-container {
        .ant-picker-time-panel {
          padding-block-start: vw(4, $s);
        }
        .ant-picker-time-panel-column {
          width: vw(56, $s);
          margin-block: vw(4, $s);
        }
        .ant-picker-time-panel-cell {
          min-width: vw(4, $s);
        }
        .ant-picker-time-panel-column >li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
          width: vw(48, $s);
          height: vw(28, $s);
          line-height: vw(28, $s);
          padding-inline-start: vw(14, $s);
        }
        .ant-picker-content {
          height: vw(224, $s);
        }
      }
    }
    &.icon-start {
      .ant-picker-input {
        flex-direction: row-reverse;
        gap: vw(8, $s);
      }
    }
  }
}

.rs-date-picker {
  font-family: var(--font-regular);
  color: var(--neutral-gray-700);
  background: #FCFCFD;
  border-color: var(--neutral-gray-100);

  &-dropdown {
    font-size: inherit;
    font-family: inherit;

    .ant-picker {
      &-header {
        > button {
          font-size: inherit;
        }
      }
    }
  }
  .ant-picker {
    &-input {
      > input {
        font-size: inherit;
        line-height: inherit;
      }
    }
    &-separator {
      color: var(--neutral-gray-300);
    }
    &-suffix {
      color: var(--neutral-gray-300)
    }
    &-active-bar {
      background: var(--primary-7);
    }
  }
  &.icon-start {
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-suffix {
      margin-inline-start: 0;
    }
  }

  // TIME PICKER
  .ant-picker-time-panel-container {
    .ant-picker-time-panel-column >li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      color: var(--neutral-gray-700);
    }
  }
}

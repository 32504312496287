@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-detail-content {
    gap: vw(24, $s);
  }
}

.voucher-detail-content {
  display: flex;
  flex-direction: column;

  .app-breadcrumb-title {
    padding-block-end: 0;
  }
}

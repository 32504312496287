@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-add {
    &-wrapper {
      padding-block: vw(48, $s);
      gap: vw(35, $s);
    }
    &__content {
      max-width: vw(768, $s);
      gap: vw(24, $s);

      .block {
        gap: vw(18, $s);

        &-flex {
          gap: vw(18, $s);

          .rs-input-number {
            min-width: vw(300, $s);
          }
          &__content {
            gap: vw(24, $s);
          }
        }
        &-grid {
          gap: vw(16, $s);

          .block-grid {
            gap: vw(32, $s);
          }
          &__item {
            gap: vw(12, $s);
          }
        }
        &-gap-12 {
          gap: vw(12, $s);
        }
      }
      .ant-picker {
        width: 100%;
      }
      .voucher-type-group {
        gap: vw(12, $s);

        .ant-radio-button-wrapper {
          padding: vw(24, $s);

          &-checked {
            background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%),linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);
          }
        }
        .anticon {
          font-size: vw(32, $s);
          margin-inline-end: vw(24, $s);
        }
        .content {
          gap: vw(8, $s);
        }
        .title {
          line-height: vw(22, $s);
        }
        .description {
          font-size: vw(13, $s);
          line-height: vw(17, $s);
        }
      }
      .voucher-condition-group {
        gap: vw(12, $s);

        .ant-radio-button-wrapper {
          border-radius: vw(8, $s);

          &:not(:first-child) {
            border-inline-start-width: vw(1, $s);

            &::before {
              display: none;
            }
          }
        }
      }
    }
    &__footer {
      bottom: vw(35, $s);
      padding: vw(12, $s);
      border-radius: vw(12, $s);
      gap: vw(12, $s);
      border-block-start-width: vw(1, $s);

      .ant-btn {
        box-shadow: 0 vw(6, $s) vw(8, $s) 0 #00000026;
      }
    }
  }
}

.voucher-add {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .ant-form-item {
      margin-block-end: 0 !important;
    }
    .fix-rv{
      flex-direction: row-reverse;
    }
    .block {
      display: flex;
      flex-direction: column;

      &-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__content {
          display: flex;
          align-items: center;
        }
      }
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-columns: 1fr;

        &__item {
          display: grid;
        }
      }
    }
    .voucher-type-group {
      span {
        align-items: flex-start;
      }
      .content {
        display: flex;
        flex-direction: column;
      }
      .title {
        color: var(--neutral-gray-700);
      }
      .description {
        color: var(--neutral-gray-500);
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    position: sticky;
    left: 50%;
    z-index: 5;
    transform: translateX(-50%);
    width: fit-content;
    background: var(--neutral-gray-100);
    border-block-start-color: var(--neutral-gray-100);
    border-block-start-style: solid;

    .ant-btn-default {
      border-color: #FFFFFF;
    }
  }
}

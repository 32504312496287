@import './styles/reset.scss';
@import './styles/root.scss';
@import './assets/styles/styles.scss';
@import './styles/custom.scss';

body {
  font-family: var(--font-regular);
  color: var(--neutral-gray-700);
}

.app {
  &-widget {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1199px) {
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      transform: translateX(-100%);
      transition: all .25s;
      z-index: 99;

      &.app-widget-show {
        transform: translateX(0);
      }
    }
  }
}

@include vwConvert() using ($s) {
  .manual-campaign-table .channel-img {
    width: vw(15, $s);
    height: vw(15, $s);
  }
  .t-item-notification .type-content{
    font-size: unset;
  }
  .fix-size-icon-del{
    font-size: vw(14, $s);
  }
  body {
    font-size: vw(14, $s);
  }

  .app {
    display: flex;
    // grid-template-columns: vw(200, $s) 1fr;
    height: 100vh;
    overflow: hidden;

    // &-widget,
    // &-main {
    //   transition: all .3s;
    // }
  }

  // .app-header{
  //   height: vw(40, $s);
  // }
  .app-widget {
    flex: 0 0 vw(200, $s);
    box-shadow: 0px vw(2, $s) vw(8, $s) 0px #00000026;

    &__logo {
      height: vw(48, $s);
      border-bottom: thin solid var(--neutral-gray-100);
      display: inline-flex;

      img {
        width: 100%;
      }
      .default-logo {
        width: vw(140, $s);
      }
    }
  }

  .app-main {
    height: 100%;
    width: calc(100% - vw(200, $s));
    @media screen and ( max-width: 1199px ) {
      width: 100%;
    }
  }

  .app-content {
    padding: vw(12, $s) vw(24, $s);
    height: calc(100% - vw(40, $s));
    overflow-y: auto;
  }

  .menu-collapsed {
    .app {
      &-widget {
        flex: 0 0 vw(63, $s);

        &__logo {
          .collapsed-logo {
            height: vw(12, $s);
          }
        }
      }

      &-main {
        width: calc(100% - vw(63, $s));
      }
    }
  }

  .layout-full {
    position: relative;

    .logo-full {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: vw(200, $s);
      height: vw(48, $s);

      img {
        height: 100%;
        width: 100%;
      }
    }

    .app-main {
      width: 100%;
    }
  }

  .departmental-unit-filter{
    display: grid;
    grid-template-columns: auto 1fr vw(100, $s) vw(100, $s);
  }

  .departments-filter{
    display: grid;
    grid-template-columns: auto 1fr vw(150, $s) vw(160, $s);

  }
  .issuing-unit-filter{
    display: grid;
    grid-template-columns: auto 1fr vw(120, $s);

  }
  .plac-color{
    &::placeholder{
      color: #98A2B3 !important;
      font-size: vw(12, $s);
    }
  }

}

.app-widget {
  &__logo {
    .collapsed-logo {
      display: none;
    }
    .default-logo {
      align-self: center;
      margin-inline: auto;
    }
  }
}

.menu-collapsed {
  .app {
    &-widget__logo {
      display: flex;
      align-items: center;
      justify-content: center;

      .default-logo {
        display: none;
      }

      .collapsed-logo {
        display: block;
      }
    }
  }
}

.table-scroll-x{
  overflow-x: auto;
}

@function vx($n) {
  @return #{calc(($n * 100) / 1440)}vw;
}
@media screen and (min-width: 1441px) {
  .arcc-filter__body .default-filters{
    grid-template-columns: 34.375vw 6.1875vw 5.125vw 5.8125vw 7.75vw 21.1875vw;
  }
  .card-issuance-list .filter-block .t-search-block{
    max-width: 43.8125vw;
  }
  .vehicle-list-filter{
    .w-630-px{
      width: 49.375vw;
    }
  }
  .brand-management-filter{
    .w-564-px{
      width: 45.25vw;
    }
  }
  .vehicle-type-management-filter,
  .vehicle-version-management-filter,
  .vehicle-category-management-filter{
    .w-468-px{
      width: 39.25vw;
    }
  }
  .voucher-filter.fixw{
    grid-template-columns: 1fr 16vw 6.9375vw 6.625vw 6.6875vw;
  }
}
.ob-cover{
  object-fit: cover;
}
.fr-wrapper a[href="https://www.froala.com/wysiwyg-editor?k=u"] {
  display: none !important;
}
.fr-second-toolbar{display: none;}
.c-table-scoll-x .ant-table-content{
  overflow-y: hidden;
}

@include vwConvert() using ($s) {
  .rs-range-picker {
    padding: vw(4, $s) vw(12, $s);
    border-radius: vw(8, $s);
    border-width: vw(1, $s);
    height: vw(32, $s);

    &-dropdown {
      .ant-picker {
        &-header {
          button {
            line-height: vw(40, $s);

            &:not(:first-child) {
              margin-inline-start: vw(8, $s);
            }
          }
        }
        &-next-icon,
        &-prev-icon,
        &-super-next-icon,
        &-super-prev-icon {
          width: vw(7, $s);
          height: vw(7, $s);

          &::before,
          &::after {
            width: vw(7, $s);
            height: vw(7, $s);
          }
        }
        &-content th {
          height: vw(36, $s);
          width: vw(36, $s);
        }
        &-date-panel {
          width: vw(288, $s);

          .ant-picker-body {
            padding: vw(8, $s) vw(18, $s);
          }
        }
        &-cell {
          padding-block: vw(6, $s);

          &::before {
            height: vw(24, $s);
          }
        }
        &-cell .ant-picker-cell-inner,
        &-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
          height: vw(24, $s);
          width: vw(24, $s);
          line-height: vw(24, $s);

          &::before {
            border-width: vw(1, $s);
          }
        } 
        &-prev-icon::before, 
        &-next-icon::before, 
        &-super-prev-icon::before, 
        &-super-next-icon::before {
          border-block-start-width: vw(1.5, $s);
          border-inline-start-width: vw(1.5, $s);
        }
        &-super-prev-icon::after, 
        &-super-next-icon::after {
          top: vw(4, $s);
          inset-inline-start: vw(4, $s);
        }
      }
    }
    .ant-picker {
      &-input {
        width: vw(91, $s);
        text-align: center;
      }
      &-range-separator {
        padding-inline: vw(8, $s);
        height: vw(24, $s);
        width: vw(32, $s);
      }
      &-separator {
        font-size: vw(16, $s);
        height: auto;
      }
      &-suffix {
        margin-inline-start: vw(4, $s);
      }
      &-active-bar {
        height: vw(2, $s);
        bottom: vw(-1, $s)
      }
    }
  }
}

.rs-range-picker {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  justify-content: center;

  &-dropdown {
    font-size: inherit;
    font-family: inherit;

    .ant-picker {
      &-header {
        > button {
          font-size: inherit;
        }
      }
    }
  }
  .ant-picker {
    &-input {
      > input {
        font-size: inherit;
        line-height: inherit;
        text-align: center;
      }
    }
    &-range-separator {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &-separator {
      color: var(--neutral-gray-300);
    }
    &-suffix {
      color: var(--neutral-gray-300)
    }
    &-active-bar {
      background: var(--primary-7);
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .customer-head-list-setting{
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    .one-op{
      position: relative;
      margin-bottom: vw(8, $s);
      &.active{
        background: #E6F7FF;
      }
    }
    .text{
      width: 100%;
    }
    .icon{
      display: flex;
      position: absolute;
      left: vw(2, $s);;
      top: 50%;
      transform: translateY(-50%);
    }
    .label{
      padding-left: vw(8, $s);
    }
    .ant-checkbox{
      margin-left: vw(8, $s);
    }
  }
}

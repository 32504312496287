@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-management-content {
    .voucher-cards {
      gap: vw(18, $s);
      margin-block-start: vw(18, $s);
    }
  }
}

.voucher-management-content {
  display: flex;
  flex-direction: column;

  .voucher-cards {
    // flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: 1fr;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .custom-froala {

    .fr-box.fr-basic {
      -webkit-border-radius: vw(10, $s);

      .fr-toolbar {
        color: #344054;
        font-family: var(--font-regular);
        border: vw(1, $s) solid #F2F4F7;

        .fr-btn-grp {
          margin: 0 vw(17, $s) 0 vw(12, $s);
        }

        .fr-popup {
          box-shadow: 0 vw(3, $s) vw(1, $s) vw(-2, $s) rgba(0, 0, 0, 0.2), 0 vw(2, $s) vw(2, $s) 0 rgba(0, 0, 0, 0.14), 0 vw(1, $s) vw(5, $s) 0 rgba(0, 0, 0, 0.12);
          margin-top: vw(10, $s);
          -webkit-border-radius: vw(6, $s);

          &.fr-buttons:not(.fr-tabs) {}

          .fr-buttons.fr-tabs {
            -webkit-border-radius: vw(6, $s) vw(6, $s) 0 0;

            .fr-command.fr-btn {
              width: vw(46, $s);
            }
          }

          .fr-image-upload-layer {
            border: dashed vw(2, $s) #bdbdbd;
            padding: vw(25, $s) 0;
            margin: vw(20, $s);
            font-size: vw(14, $s);
            letter-spacing: vw(1, $s);

            .fr-form input {
              font-size: vw(400, $s);
            }
          }

          .fr-layer {
            width: vw(260, $s);
            margin: vw(20, $s);

            &.fr-image-progress-bar-layer>h3 {
              font-size: vw(16, $s);
              margin: vw(10, $s) 0;
            }

            &.fr-image-progress-bar-layer>div.fr-loader {
              margin-top: vw(20, $s);
              height: vw(10, $s);
            }

            &.fr-action-buttons {
              height: vw(36, $s);

              button.fr-command {
                height: vw(36, $s);
                padding: vw(10, $s);
                font-size: vw(16, $s);
                -webkit-border-radius: vw(2, $s);
              }
            }


          }
        }

        .fr-more-toolbar.fr-expanded {
          height: vw(48, $s);
        }

        .fr-separator.fr-vs {
          height: vw(32, $s);
          width: vw(1, $s);
        }

        .fr-command.fr-btn {
          margin: vw(4, $s) vw(2, $s);
          -webkit-border-radius: vw(4, $s);
          height: vw(40, $s);

          svg {
            margin: vw(8, $s) vw(7, $s);
            width: vw(24, $s);
          }

          svg.fr-svg {
            height: vw(24, $s)
          }



          +.fr-dropdown-menu {
            box-shadow: 0 vw(3, $s) vw(1, $s) vw(-2, $s) rgba(0, 0, 0, 0.2), 0 vw(2, $s) vw(2, $s) 0 rgba(0, 0, 0, 0.14), 0 vw(1, $s) vw(5, $s) 0 rgba(0, 0, 0, 0.12);
          }

          &.fr-active+.fr-dropdown-menu {
            box-shadow: 0 vw(3, $s) vw(1, $s) vw(-2, $s) rgba(0, 0, 0, 0.2), 0 vw(2, $s) vw(2, $s) 0 rgba(0, 0, 0, 0.14), 0 vw(1, $s) vw(5, $s) 0 rgba(0, 0, 0, 0.12);
            -webkit-border-radius: vw(4, $s);

            .fr-dropdown-wrapper {
              height: vw(136, $s) !important;

              .fr-dropdown-content ul.fr-dropdown-list {
                padding: vw(8, $s) 0;
                min-width: vw(72, $s);

                li {
                  font-size: vw(15, $s);

                  a {
                    padding: 0 vw(20, $s);
                  }
                }
              }
            }
          }

        }

        .fr-command.fr-btn.fr-options {
          width: vw(16, $s);
        }

        .fr-command.fr-btn.fr-dropdown.fr-active:after {
          border-bottom: vw(4, $s) solid #222222;
        }

        .fr-command.fr-btn.fr-dropdown:after {
          border-left: vw(4, $s) solid transparent;
          border-right: vw(4, $s) solid transparent;
          border-top: vw(4, $s) solid #333333;
          right: vw(2, $s);
          top: vw(18, $s);
        }
      }

      .fr-wrapper {
        border-bottom: vw(1, $s) solid #F2F4F7;
        border-left: vw(1, $s) solid #F2F4F7;
        border-right: vw(1, $s) solid #F2F4F7;
        height: vw(90, $s) !important;

        .fr-element {
          font-family: var(--font-regular);
          font-size: vw(14, $s);
          padding: vw(20, $s);
          min-height: vw(90, $s) !important;

          img {
            padding: 0 vw(1, $s);
          }
        }

        .fr-placeholder {
          font-size: vw(14, $s) !important;
          line-height: vw(22.4, $s) !important;
          margin-top: vw(0, $s) !important;
          padding-top: vw(20, $s) !important;
          padding-left: vw(20, $s) !important;
          margin-left: vw(0, $s) !important;
          padding-right: vw(20, $s) !important;
          margin-right: vw(0, $s) !important;
        }
      }

      .fr-second-toolbar {
        border-bottom: vw(1, $s) solid #F2F4F7;
        border-left: vw(1, $s) solid #F2F4F7;
        border-right: vw(1, $s) solid #F2F4F7;
        border-radius: 0 0 vw(10, $s) vw(10, $s);
        -moz-border-radius: 0 0 vw(10, $s) vw(10, $s);
        -webkit-border-radius: 0 0 vw(10, $s) vw(10, $s);
      }

      .fr-second-toolbar:empty {
        min-height: calc(vw(10, $s));
        margin-top: calc(vw(-1, $s));
      }

      .fr-toolbar.fr-top {
        -webkit-border-radius: vw(10, $s) vw(10, $s) 0 0;
      }
    }
  }
}

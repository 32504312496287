@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .custom-loading {
    &.ant-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10000;

      &.ant-spin-lg .ant-spin-dot {
        font-size: vw(32, $s);

        i {
          width: vw(14, $s);
          height: vw(14, $s);
        }
      }
    }
  }
}

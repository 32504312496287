@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .w-fit-table-filter.ant-table-wrapper .ant-table-filter-column {
    display: flex;
    align-items: center;
    gap: vw(4, $s);
    width: fit-content;
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-title-btn-back {
    display: flex;
    gap: vw(16, $s);
  }

  .t-search-block {
    flex-grow: 1;
    max-width: 100%;
    width: auto;
    height: vw(32, $s);

    input {
      background-color: #FCFCFD;
      height: vw(32, $s);
      font-size: vw(14, $s);
      line-height: 1;
      color: #344054;
    }

    .ant-input-wrapper .ant-input {
      border-radius: vw(8, $s);
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-color: #F2F4F7;
    }

    .ant-btn {
      height: vw(32, $s);
      width: vw(32, $s);
      border-start-end-radius: vw(8, $s) !important;
      border-end-end-radius: vw(8, $s) !important;
      &:hover {
        border-color: #F2F4F7 !important;
        color: #F2F4F7 !important;
      }

      .ant-btn-icon {
        color: #8a8a8b;
      }

      .anticon {
        font-size: vw(16, $s);
      }
    }
  }

  .mw-100 {
    max-width: 100% !important;
  }

  .t-table-block.t-table-pooling-data-auto {
    .ant-table-wrapper {
      table tr th:nth-child(1) {
        width: vw(36, $s);
      }

      table tr th:nth-child(2) {
        width: vw(112, $s);
      }

      table tr th:nth-child(3) {
        width: vw(261, $s);
      }

      table tr th:nth-child(4) {
        width: vw(261, $s);
      }

      table tr th:nth-child(5) {
        width: vw(261, $s);
      }

      table tr th:nth-child(6) {
        width: vw(261, $s);
      }
    }
  }

  .pooling-data-auto {
    .t-table-list-tags {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: vw(10, $s);
      .item-tag {
        height: vw(22, $s);
        padding: 0 vw(8, $s);
        border-radius: vw(8, $s);
        border: vw(1, $s) solid #ADC6FF;
        background-color: #F0F5FF;
        color: #2F54EB;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }
    }
  }

  .filter-select {
    width: 100%;
    height: vw(32, $s);
    position: relative;
    .btn-select-show {
      border-radius: vw(8, $s);
      border: vw(1, $s) solid #F2F4F7;
      padding: vw(4, $s) vw(20, $s) vw(4, $s) vw(11, $s) ;
      height: vw(32, $s);
      font-size: vw(14, $s);
      cursor: pointer;
      .content-select {
        display: flex;
        gap: vw(4,$s);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .cl-blue {
          color: #1890FF;
        }
      }
      span {
        color: #344054;
        display: block;
      }
    }

    .icon-down, .icon-close {
      font-size: vw(10, $s);
      color: #8a8a8b;
      position: absolute;
      top: 50%;
      right: vw(11, $s);
      transform: translateY(-50%);
    }

    .icon-down {
      &.active {
        display: none;
      }
    }

    .icon-close {
      display: none;
      &.active {
        display: block;
      }
      span {
        color: #ff0000;
      }
    }

    .list-filter {
      position: absolute;
      top: 100%;
      z-index: 5;
      padding: vw(8, $s);
      border-radius: vw(6, $s);
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      width: 100%;
      margin-top: vw(12, $s);
      display: none;
      background-color: #fff;
      &.active {
        display: block;
      }
      input {
        margin-bottom: vw(8, $s);
      }
      .list-item {
        max-height: vw(320, $s);
        overflow-y: auto;
      }
      .ant-input, .item-select {
        border-radius: vw(2, $s);
        padding: vw(5, $s) vw(12, $s);
      }
      .item-select {
        margin-bottom: vw(8, $s);
        display: flex;
        color: #667085;
        gap: vw(8, $s);
        align-items: center;
        justify-content: space-between;
        padding: vw(5, $s) vw(12, $s);
        font-size: vw(14, $s);

        .icon-left {
          font-size: vw(12, $s);
          display: flex;
          gap: vw(8, $s);
        }
        .icon-right {
          font-size: vw(12, $s);
          color: #FF7875;
        }
      }
      .btn-filter {
        border-radius: vw(6, $s);
        border: vw(1, $s) solid #F2F4F7;
        padding: vw(4, $s) vw(11, $s);
        height: vw(32, $s);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #096DD9;
        color: #fff;
        gap: vw(10, $s);
        font-size: vw(14, $s);
        .icon-btn-filter {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .filter-label-left {
    display: flex;
    gap: vw( 12, $s );
    align-items: center;
    .title-dk {
      white-space: nowrap;
      color: #344054;
    }
  }

  .empty-data {
    padding-top: vw(80, $s);
    padding-bottom: vw(80, $s);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-box {
      aspect-ratio: 326/200;
      width:  vw(326, $s);
      margin-bottom: vw(8, $s);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sum {
      color: #667085;
      margin-bottom: vw(24, $s);
    }
    .des {
      max-width: vw(395, $s);
      padding: vw(9, $s) vw(16, $s);
      border-radius: vw( 8,$s );
      border: vw(1,$s) solid #91D5FF;
      background-color: #E6F7FF;
      margin-bottom: vw( 24,$s );
      display: flex;
      gap: vw( 14,$s );
      align-items: center;
    }
    .btn-kttl {
      max-width: vw( 143,$s );
      height: vw( 32,$s );
      border-radius: vw( 6,$s );
      border: vw(1,$s) solid #096DD9;
      background-color: #096DD9;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color:#fff;
    }
  }

  .load-check-data {
    padding-top: vw(80, $s);
    padding-bottom: vw(80, $s);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: vw( 240,$s );
    margin-left: auto;
    margin-right: auto;
    &.mw-395 {
      max-width: vw( 395,$s );
      .percent-block {
        width: 100%;
        margin-bottom: vw( 38,$s );
        .load-percent {
          display: flex;
          align-items: center;
          gap: vw(8,$s);
          flex-grow: 1;
          .line-load {
            width: 100%;
          }
        }
      }
    }
    .percent-block {
      display: flex;
      align-items: center;
      gap: vw(24, $s);
      margin-top: vw(24, $s);
      margin-bottom: vw(8, $s);
    }
    .line-load {
      width: vw(100, $s);
      margin-left: auto;
      margin-right: auto;
      height: vw(8, $s);
      border-radius: 9999px;
      position: relative;
      background-color: #F5F5F5;
      .percent {
        top: 0;
        left: 0;
        width: 30%;
        // background-color: #096DD9;
        background: linear-gradient(90deg, #1890FF 55.55%, rgba(24, 144, 255, 0) 101.92%);
        border-radius: 9999px;
        height: 100%;
        position: absolute;
      }
    }
    .sum {
      text-align: center;
      font-size: vw(14, $s);
      color: #344054;
    }
  }

  .data-pooling-auto-list {
    padding-top: vw(80, $s);
    .chart-pooling-block {
      margin-bottom: vw(48, $s);
      display: flex;
      align-items: center;
      gap: vw(24,$s);
      .img-box {
        flex: 0 0 auto;
        max-width: vw(352, $s);
        aspect-ratio: 352/275;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .total-data-pooling {
        display: flex;
        align-items: center;
        .total-number {
          height: vw(83, $s);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:first-child {
            padding-right: 32px;
            margin-right: 32px;
            border-right: vw(1,$s) solid #F2F4F7;
          }
          .title {
            font-size: vw(14,$s);
            color: #344054;
          }
          .count {
            font-size: vw(30,$s);
            font-weight: 500;
            line-height: 1;
          }
          .sum {
            font-size: vw(12,$s);
            color: #98A2B3;
          }
        }
      }
    }
  }
  .ctrl-select-table {
    display: flex;
    align-items: center;
    gap: vw(12,$s);
    margin-bottom: vw(8,$s);
    .count-check-all {
      font-size: vw(14,$s);
      color: #344054;
      gap: vw(4,$s);
      display: flex;
      flex-wrap: nowrap;
    }
    .delete-all {
      width: vw(60,$s);
      height: vw(38,$s);
      background-color: #F5222D;
      border-radius: vw(8,$s);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: vw(16,$s);
      span {
        line-height: 1;
      }
    }
  }
}

@include vwConvert() using ($s) {
  .rs-tabs {
    .ant-tabs {
      &-tab {
        padding-block: vw(12, $s);
      }
      &-nav {
        margin-block-end: vw(18, $s);

        &-list {
          gap: vw(32, $s);
        }
      }
      &-ink-bar {
        height: vw(2, $s) !important;
      }
      &-btn {
        .ant-tabs-tab-icon:not(:last-child) {
          margin-inline-end: vw(12, $s);
        }
      }
    }
  }
}

.rs-tabs {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;

  .ant-tabs {
    &-tab {
      font-size: inherit;
      color: var(--neutral-gray-700);

      + .ant-tabs-tab {
        margin: 0;
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1890FF;
      }
    }
    &-ink-bar {
      background: #1890FF;
    }
  }
}

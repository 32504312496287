@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .arcc-statistical-cards {
    gap: vw(24, $s);
  }
}

.arcc-statistical-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: 1fr;
}
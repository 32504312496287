@import '../../styles/root.scss';
@include vwConvert() using ($s) {
  .warranty-item {
    gap: vw(12, $s);
    padding: vw(12, $s) vw(16, $s) vw(18, $s) vw(16, $s);
    border-width: vw(1, $s);
    border-radius: vw(12, $s);

    &__head {
      gap: vw(8, $s);

      .title {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }
    }
    .item {
      gap: vw(8, $s);
      line-height: vw(22, $s);

      .right {
        gap: vw(8, $s);
      }
    }
  }
}

.warranty-item {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: var(--neutral-gray-100);

  &__head {
    display: flex;
    align-items: center;

    .title {
      font-family: var(--font-medium);
    }
  }
  .item {
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
    }
  }
}
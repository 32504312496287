@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .w {
    &-334-px {
      width: vw(334.33, $s);
    }
  }
  .vehicle-category-management-filter {
    .w-468-px {
      // width: vw(591, $s);
      flex-grow: 1;
    }
  }
}

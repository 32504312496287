@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .relative-card {
    &__title {
      border-radius: vw(8, $s) vw(8, $s) 0 0;
      gap: vw(10, $s);
      padding: vw(8, $s);

      .ant-btn {
        border-radius: vw(8, $s);
      }
    }

    .relative-collapse-with-control {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #D0D5DD !important;
    }
    .order-history-card {
      border-radius: vw(12, $s) vw(12, $s) 0 0;
      box-shadow: none;
    }
  }
  .title-ds {
    background: linear-gradient(90deg, #1890FF 0%, #096DD9 100%);
    padding: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: vw(14, $s);
      font-weight: 700;
      line-height: vw(22, $s);
      color: #fff;
    }
  }
  .t-ml-auto {
    .ant-radio-group {
      margin-left: auto !important;
    }
  }
}

.relative-card {
  &__title {
    color: #FFFFFF;
    background: linear-gradient(90deg, #1890FF 0%, #096DD9 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn {
      &:not(:hover) {
        color: var(--primary-7);
        background: var(--primary-1);
      }
    }
  }
}

.t-icon-setting {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  cursor: pointer;
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .arcc-filter {
    gap: vw(8, $s);

    &__head {
      gap: vw(8, $s) vw(12, $s);

      .control {
        gap: vw(12, $s);
      }
      .ant-btn {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        padding-inline-end: vw(9, $s);
      }
    }
    &__body {
      gap: vw(8, $s);

      .default-filters,
      .advance-filters {
        gap: vw(8, $s) vw(12, $s);
      }
      .default-filters {
        grid-template-columns: vw(390, $s)  vw(99, $s) vw(82, $s) vw(93, $s) vw(124, $s) vw(339, $s);
      }
      .advance-filters {
        grid-template-columns: vw(126, $s)  vw(124, $s) vw(130, $s) vw(112, $s) vw(99, $s) vw(87, $s);
      }
    }
  }
}

.arcc-filter {
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .control {
      display: flex;
      align-items: center;
    }
    .ant-btn {
      font-family: var(--font-regular);
    }
  }
  &__body {
    display: flex;
    flex-direction: column;

    .default-filters,
    .advance-filters {
      display: grid;
    }
  }
}
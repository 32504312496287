@include vwConvert() using ($s) {
  .tags-custom{
    gap: vw(8, $s) 0 !important;
    font-size: vw(14, $s);
    .ant-tag{
      font-size: vw(14, $s);
      line-height: vw(24, $s);
      height: vw(24, $s) !important;
      margin-inline-end: vw(8, $s);
      padding-inline: vw(7, $s);
      border-radius: vw(4, $s);
      border: vw(1, $s) solid #d9d9d9;
    }
    .ant-tag .ant-tag-close-icon{
      font-size: vw(12, $s);
    }
    .ant-input{
      font-size: vw(14, $s);
      height: vw(24, $s) !important;
      padding: vw(0, $s) vw(4, $s);
      width: vw(125, $s) !important;
      border-radius: vw(4, $s);
    }
    .ctr-add{
      font-size: vw(14, $s);
      height: vw(24, $s) !important;
      padding: vw(0, $s) vw(4, $s);
      width: vw(125, $s);
    }
  }
}

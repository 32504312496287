@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .app-menu-vertical {
    .menu-trigger {
      padding: vw(12, $s) vw(9, $s);
      height: vw(40, $s);
      box-shadow: 0 vw(1, $s) 0 0 #F0F0F0 inset;
    }
    .ant-menu {
      &-submenu {
        &-arrow {
          inset-inline-end: vw(16, $s);
          
          &::before,
          &::after {
            width: vw(6, $s);
            height: vw(1.5, $s);
            border-radius: vw(6, $s);
          }
        }
        &-title {
          padding: vw(9, $s) vw(16, $s) !important;

          .ant-menu-title-content {
            margin-inline-start: vw(10, $s) !important;
          }
        }
        .ant-menu {
          &-item {
            padding-inline: vw(40, $s) vw(2, $s) !important;
          }
        }
        .ant-menu-submenu {
          .ant-menu {
            &-submenu-title {
              padding-inline-start: vw(40, $s) !important;
            }
            &-title-content {
              margin-inline-start: 0 !important;
            }
            &-item {
              padding-inline-start: vw(64, $s) !important;
            }
          }
        }
      }
      &-root {
        row-gap: vw(8, $s);
      }
      &-sub {
        .ant-menu-item {
          margin-block-start: vw(8, $s);
          // &:not(:last-of-type) {
          // }
        }
      }
      &-item-selected {
        box-shadow: vw(-3, $s) 0px 0px 0px #1890FF inset;
      }
    }
    .ant-menu-inline >.ant-menu-item, 
    .ant-menu-vertical >.ant-menu-item, 
    .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, 
    .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
      height: vw(40, $s);
      line-height: vw(40, $s);
    }
    .ant-menu .ant-menu-item .ant-menu-item-icon, 
    .ant-menu .ant-menu-submenu-title .ant-menu-item-icon, 
    .ant-menu .ant-menu-item .anticon, 
    .ant-menu .ant-menu-submenu-title .anticon {
      min-width: vw(16, $s);
    }
    .ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
    .ant-menu-inline .ant-menu-submenu-arrow::before {
      transform: rotate(-45deg) translateX(vw(2.5, $s));
    }
    .ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
    .ant-menu-inline .ant-menu-submenu-arrow::after {
      transform: rotate(45deg) translateX(vw(-2.5, $s));
    }
    .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before {
      transform: rotate(45deg) translateX(vw(2.5, $s));
    }
    .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(vw(-2.5, $s));
    }
    .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
      transform: translateY(vw(-2, $s));
    }
    .ant-menu-inline-collapsed {
      width: vw(63, $s);
    }
    .ant-menu-inline-collapsed >.ant-menu-item .ant-menu-item-icon, 
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon, 
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon, 
    .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon, 
    .ant-menu-inline-collapsed >.ant-menu-item .anticon, 
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon, 
    .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon, 
    .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title .anticon {
      font-size: vw(16, $s);
    }
  }
}

.app-menu-vertical {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ant-menu {
    font-family: var(--font-regular);
    font-weight: 400;
    border-inline-end: none;
    font-size: inherit;

    &-root {
      flex-grow: 1;
      overflow-y: auto;
      border-inline-end-width: 0 !important;
      display: flex;
      flex-direction: column;

      &::before,
      &::after {
        display: none;
      }
    }
    &-item,
    &-submenu-title {
      margin: 0;
      width: auto;
    }
    &-item {
      border-radius: 0;
      color: var(--neutral-gray-700);

      &-selected {
        // border-radius: 0;
        // box-shadow: -3px 0px 0px 0px #1890FF inset;
        color: var(--primary-7);
      }
    }
    &-submenu {
      &-arrow {
        width: 0;
      }
      &-title {
        border-radius: 0;
      }
      &-selected {
        > .ant-menu-submenu-title {
          color: var(--primary-7);
        }
      }
    }
    .ant-menu-item-icon {
      font-size: inherit;
    }
    &.ant-menu-sub.ant-menu-inline {
      background: none;
    }
  }
  .menu-trigger {
    display: none;

    .anticon {
      cursor: pointer;
    }

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
}

.menu-collapsed {     
  .menu-trigger {
    text-align: center;
  }
  .ant-menu-inline-collapsed,
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    // .ant-menu-sub,
    .ant-menu-title-content,
    .ant-menu-submenu-arrow,
    >.ant-menu-submenu>.ant-menu-submenu-title .anticon +span,
    >.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon +span {
      display: none;
    }
  }
}

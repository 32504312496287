@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .w-256 {
    width: vw(256, $s);
  }
  .status-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .status-block {
      .ctrl-status {
        padding-left: vw(14, $s);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: vw(6, $s);
          height: vw(6, $s);
          border-radius: 50%;
        }
        &.type-1 {
          &::after {
            background-color: #52C41A;
          }
        }
        &.type-2 {
          &::after {
            background-color: #FF4D4F;
          }
        }
      }
    }
    a {
      margin: 0 vw(15, $s);
      color: #1890FF;
    }
  }

  .t-table-block.t-table-campaign-sends-notifications-detail {
    .ant-table-wrapper {
      table tr th:nth-child(1) {
        width: vw(60, $s);
      }

      table tr th:nth-child(2) {
        width: vw(479, $s);
      }

      table tr th:nth-child(3) {
        width: vw(479, $s);
      }

      table tr th:nth-child(4) {
        width: vw(169, $s);
      }
    }
  }
}


@include vwConvert() using ($s) {
  .rs-steps {
    font-size: vw(14, $s);
    line-height: vw(22, $s);

    .ant-steps-item-icon {
      height: vw(24, $s);
      width: vw(24, $s);
      margin-inline-end: vw(8, $s);
    }
    &.ant-steps-navigation {
      padding-block-start: vw(8, $s);

      .ant-steps-item-container {
        padding-block-end: vw(12, $s);
        margin-inline-start: vw(-16, $s);
      }
    }
    &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      padding-inline-start: vw(16, $s);
    }
  }
}

.rs-steps {
  font-family: inherit;

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--neutral-gray-700);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--neutral-gray-400);
  }
  .ant-steps-item-title,
  .ant-steps-item-icon {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .ant-steps-item-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .ant-steps-icon {
      top: 0;
    }
  }
}

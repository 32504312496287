@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .notification-templates-management-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
    gap: vw(16, $s);
  }

  .t-pl-24 {
    padding-left: vw(24, $s) !important;
  }

  .t-px-24 {
    padding-left: vw(24, $s);
    padding-right: vw(24, $s);
  }

  .t-mt-18 {
  margin-top: vw(18, $s);
  }

  .t-input {
    padding: 0 vw(12, $s);
    background-color: #FCFCFD;
    display: flex;
    align-items: center;
    border-color: #F2F4F7;
    border-width: vw(1, $s);
    border-radius: vw(8, $s);
    font-size: vw(14, $s);
  }

  .t-h-40 {
    height: vw(40, $s) !important;
  }

  .t-border-rd-2 {
    border-radius: vw(2, $s) !important;
  }

  .filter-setting {
    &.show-noti {
      .content-filter-setting {
        height: calc(100vh - vw(186, $s));
        .title-content {
          margin-bottom: vw(12, $s);
        }
      }

      .inner-mod {
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(12, $s);
        min-height: vw(388, $s);
        .img-block {
          padding: vw(12, $s) vw(24, $s);
          background-color: #F9FAFB;
          display: flex;
          align-items: center;
          gap: vw(12, $s);

          img {
            object-fit: contain;
            width: vw(40, $s);
            height: vw(40, $s);
          }

          .title {
            font-family: var(--font-bold);
            font-size: vw(16, $s);
            line-height: vw(24, $s);
          }
        }
        .content-block {
          margin-top: vw(40, $s);
          margin-bottom: vw(12, $s);
          background-color: #F2F4F7;
          padding: vw(8, $s);
          border-radius: vw(8, $s);
          a {
            margin-left: vw(4, $s);
            margin-right: vw(4, $s);
            color: #1890FF;
          }
        }
        .time {
          color: #98A2B3;
          font-size: vw(12, $s);
          line-height: vw(20, $s);
        }
      }

      .footer-filter-block {
        .btn-block {
          display: flex;
          gap: vw(8, $s);
          justify-content: flex-end;
          .btn {
            padding: 0 vw(15, $s);
            &.btn-delete {
              color: #F5222D;
              border-color: #F5222D;
            }
          }
        }
      }
    }
  }

  .t-step-block {
    padding: vw(24, $s);
    .ant-steps {
      margin-left: vw(-45, $s);
      &.ant-steps-dot {
        .ant-steps-item-tail,
        &.ant-steps-small .ant-steps-item-tail {
          top: vw(2.5, $s);
          margin-inline: vw(70, $s) 0;
        }

        .ant-steps-item-tail::after,
        &.ant-steps-small .ant-steps-item-tail::after {
          width: calc(100% - vw(24, $s));
          height: vw(3, $s);
          margin-inline-start: vw(12, $s);
        }

        .ant-steps-item-icon,
        &.ant-steps-small .ant-steps-item-icon {
          width: vw(8, $s);
          height: vw(8, $s);
          margin-inline-start: vw(66, $s);
          line-height: vw(8, $s);
        }
        .ant-steps-item-icon .ant-steps-icon {
          position: relative;
          top: vw(-0.5, $s);
        }

        .ant-steps-item-icon .ant-steps-icon-dot::after,
        &.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
          top: vw(-12, $s);
          inset-inline-start: vw(-26, $s);
          width: vw(60, $s);
          height: vw(32, $s);
        }

        .ant-steps-item-content,
        &.ant-steps-small .ant-steps-item-content {
          width: vw(140, $s);
        }
      }

      &.ant-steps-label-vertical .ant-steps-item-content {
        // width: vw(112, $s);
        margin-top: vw(12, $s);
      }

      .ant-steps-item-icon {
        font-size: vw(14, $s);
        border-radius: vw(32, $s);
        margin-inline-end: vw(8, $s);
      }

      .ant-steps-item-tail::after {
        height: vw(1, $s);
        border-radius: vw(1, $s);
      }

      .ant-steps-item-title {
        font-size: vw(14, $s);
        font-family: var(--font-regular);
        line-height: vw(22, $s);
        color: #000000D9;
      }

      .ant-steps-item-active {
        .ant-steps-item-title {
          font-family: var(--font-bold);
          color: #344054;
        }
      }

      .ant-steps-item-wait {
        .ant-steps-item-title {
          color: #98A2B3;
        }
      }
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-title-btn-back {
    display: flex;
    gap: vw(16, $s);
  }

  .t-search-block {
    flex-grow: 1;
    max-width: 100%;
    width: auto;
    height: vw(32, $s);

    input {
      background-color: #FCFCFD;
      height: vw(32, $s);
      font-size: vw(14, $s);
      line-height: 1;
      color: #344054;
    }

    .ant-input-wrapper .ant-input {
      border-radius: vw(8, $s);
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-color: #F2F4F7;
    }

    .ant-btn {
      height: vw(32, $s);
      width: vw(32, $s);
      border-start-end-radius: vw(8, $s) !important;
      border-end-end-radius: vw(8, $s) !important;
      &:hover {
        border-color: #F2F4F7 !important;
        color: #F2F4F7 !important;
      }

      .ant-btn-icon {
        color: #8a8a8b;
      }

      .anticon {
        font-size: vw(16, $s);
      }
    }
  }

  .mw-100 {
    max-width: 100% !important;
  }

  .t-table-block.t-table-pooling-data-auto-detail {
    .ant-table-wrapper {
      table tr th:nth-child(1) {
        width: vw(36, $s);
      }

      table tr th:nth-child(2) {
        width: vw(112, $s);
      }

      table tr th:nth-child(3) {
        width: vw(261, $s);
      }

      table tr th:nth-child(4) {
        width: vw(261, $s);
      }

      table tr th:nth-child(5) {
        width: vw(261, $s);
      }

      table tr th:nth-child(6) {
        width: vw(261, $s);
      }
    }
  }

  .pooling-data-auto-detail {
    .t-table-list-tags {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: vw(10, $s);
      .item-tag {
        height: vw(22, $s);
        padding: 0 vw(8, $s);
        border-radius: vw(8, $s);
        border: vw(1, $s) solid #ADC6FF;
        background-color: #F0F5FF;
        color: #2F54EB;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }
    }
  }
}

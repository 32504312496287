@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .personal-information {
    gap: vw(32, $s);

    &__left {
      flex-grow: 1;
      gap: vw(32, $s);

      .info {
        &-left,
        &-right {
          gap: vw(10, $s);
        }
        &-left {
          flex: 0 0 calc(530 * 100% / 962);
        }
        &-right {
          flex: 0 0 calc(400 * 100% / 962);
        }
      }
      .gender-marry-block {
        gap: vw(10, $s);
      }
      .phone-block {
        gap: vw(10, $s);
        grid-template-columns: 1fr vw(125, $s);

        + .ant-btn-text {
          padding-inline: vw(7, $s);

          &:not(:hover) {
            color: var(--primary-6);
          }
          &:hover {
            background: transparent;
          }
        }
      }
      .rs-select.ant-select-single,
      .ant-picker,
      .rs-select.ant-select-multiple {
        height: vw(40, $s);
      }
      // .select-tags-show {

      // }
      .ant-picker {
        .ant-picker-suffix {
          font-size: vw(16, $s);
        }
      }
    }
    &__right {
      flex: 0 0 vw(64, $s);
    }
  }
}

.personal-information {
  display: flex;

  &__left {
    display: flex;

    .info {
      &-left,
      &-right {
        display: flex;
        flex-direction: column;
      }
    }
    .gender-marry-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: column;
    }
    .phone-block {
      display: grid;

      + .ant-btn {
        align-self: flex-start;
        color: var(--primary-7);
        padding-block: 0;
        height: auto;
      }
    }
    .select-tags-show {
      opacity: 0;
      pointer-events: none;
    }
    .ant-form-item {
      margin-block-end: 0 !important;
    }
    .ant-picker {
      .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.88);
      }
    }
    .ant-select {
      .ant-select-selection-placeholder {
        color: var(--neutral-gray-400);
      }
      .ant-select-selector {
        border-color: var(--neutral-gray-100);
      }
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .empty-component {
    &__inner {
      gap: vw(8, $s);
    }    
    img {
      height: vw(150, $s);
      width: vw(245, $s);
    }
    .title {
      line-height: vw(22, $s);
    }
  }
}

.empty-component {
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    object-fit: cover;
  }
  .title {
    color: var(--neutral-gray-500);
  }
}
@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .notification-fees-setting-content {
    gap: vw(24, $s);

    .table-top {
      gap: vw(24, $s);

      .ant-btn.primary-outline {
        font-size: vw(14, $s);
      }
    }
    .notification-fees-table {
      .network {
        gap: vw(8, $s);

        img {
          height: vw(24, $s);
          width: vw(64, $s);
        }
      }
    }
  }
}

.notification-fees-setting-content {
  display: flex;
  flex-direction: column;

  .table-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-btn.primary-outline {
      font-family: var(--font-regular);
      color: var(--primary-6);
      border-color: var(--primary-6);
    }
  }
  .app-breadcrumb-title {
    padding-block-end: 0;
  }
  .notification-fees-table {
    .network {
      display: flex;
      align-items: center;
    }
  }
}

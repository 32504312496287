@include vwConvert() using ($s) {
  .rs-table {
    .ant-table {
      font-size: vw(14, $s);
      line-height: vw(22, $s);
      box-shadow: 0 vw(2, $s) vw(8, $s) 0 #00000014;
      border-end-start-radius: vw(8, $s);
      border-end-end-radius: vw(8, $s);

      &-thead {
        > tr > th,
        >tr > td {
          padding: vw(10, $s) vw(16, $s);
          white-space: nowrap;
        }
      }
      &-column-sorter {
        &-up,
        &-down {
          font-size: vw(12, $s);
        }
      }
      &-filter-trigger {
        font-size: vw(12, $s);
        margin-block: vw(-4, $s);
        margin-inline: vw(4, $s) vw(-8, $s);
        border-radius: vw(6, $s);
        padding-inline: vw(4, $s);
      }
    }
    .ant-table-cell,
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>th,
    .ant-table-tbody>tr>td,
    tfoot>tr>th,
    tfoot>tr>td {
      // padding: vw(10, $s) vw(16, $s);
      padding-inline: vw(16, $s);
      height: vw(44, $s);
      &.actions {
        padding-inline: 10px;
      }
    }
    .ant-table-container table>thead>tr:first-child >*:first-child {
      border-start-start-radius: vw(8, $s);
    }
    .ant-pagination.ant-table-pagination {
      margin-block: vw(4, $s) 0;
      padding-block: vw(4, $s);
      box-shadow: 0 vw(2, $s) vw(8, $s) 0 #00000014;
      border-radius: 0 0 vw(8, $s) vw(8, $s);
    }
    .ant-checkbox .ant-checkbox-inner {
      border-width: vw(1, $s);
      height: vw(16, $s);
      width: vw(16, $s);
      border-radius: vw(4, $s);
    }
    .rs-button--icon-only {
      height: vw(24, $s);
      width: vw(24, $s);
      border-radius: vw(8, $s);
      border-width: vw(1, $s);
      box-shadow: 0 vw(2, $s) 0 0 #00000005;
      padding: 0;
      font-size: vw(14, $s);
    }
    table tr th.ant-table-selection-column,
    table tr td.ant-table-selection-column,
    .ant-table-selection-column {
      padding-inline: vw(10, $s);
    }
    .rc-virtual-list {
      .ant-select-item {
        font-size: vw(14, $s);
        border-radius: vw(4, $s);
        padding: vw(6, $s) vw(12, $s);
        min-height: vw(32, $s);
      }
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      height: vw(8, $s);
      width: vw(8, $s);
    }
  }

  // .ant-table-filter-dropdown {
  //   font-size: vw(14, $s);
  //   line-height: vw(22, $s);
  //   min-width: vw(120, $s);
  // }
}

.rs-table {
  .ant-pagination-options {
    margin-inline-start: 0;
  }
  .ant-table {
    font-family: var(--font-regular);
    color: inherit;
    scrollbar-color: inherit;

    &-thead {
      > tr > th,
      >tr > td {
        font-weight: normal;
        color: #FFFFFF;
        background: var(--primary-7);
      }
      th.ant-table-column-sort,
      th.ant-table-column-has-sorters:hover {
        background: var(--primary-7);
      }
      >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
      >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }
      .ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }
    &-column-sorter,
    &-filter-trigger,
    &-column-sorters:hover .ant-table-column-sorter {
      color: #00000040;
    }
    &-column-sorter {
      &-up,
      &-down {
        &.active {
          color: #FFFFFF;
        }
      }
    }
    &-filter-trigger {
      &:hover {
        color: #FFFFFF;
        background: transparent;
      }
    }
    &-tbody .ant-table-row:nth-child(odd) .ant-table-cell {
      background: #F8F8F8;
    }
    &-tbody .ant-table-row >.ant-table-cell-row-hover {
      background-color: var(--primary-1) !important;
    }
  }
  .ant-table-tbody >tr >th,
  .ant-table-tbody >tr >td {
    border-block-end-width: 0;
  }
  .ant-pagination-options-quick-jumper {
    position: relative;

    &::after {
      content: "Page";
      background: #FFF;
      color: transparent;
      position: absolute;
      right: 0;
      text-align: 0;
      top: 0;
      bottom: 0;
    }
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    border: none;
  }
  &--cell-0-padding-block {
    .ant-table-tbody >tr >th,
    .ant-table-tbody >tr >td {
      padding-block: 0;
    }
  }
}

.ant-table-filter-dropdown {
  color: var(--neutral-gray-700);
  font-family: var(--font-regular);
}
// .ant-table-wrapper{
//   overflow: auto hidden;
// }

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-select-ctrl-bg.ant-select-single .ant-select-selection-item {
    line-height: vw(30, $s);
    padding-inline-end: vw(10, $s);
  }

  .mw-400 {
    width: vw(400, $s);
  }

  .hidden-select {
    .ant-select-selector, .ant-picker {
      background-color: #F2F4F7 !important;
      pointer-events: none;
      .ant-select-selection-item {
        color: #00000040;
      }
    }
  }

  .t-ctrl-date-picker-block{
    &.t-ctrl-date-picker-bg {
      .ant-picker {
        background-color: #F2F4F7;
      }
    }

    .ant-picker {
      border-width: vw(1, $s);
      // border-color: #F2F4F7;
      padding: vw(4, $s) vw(11, $s) vw(4, $s);
      font-size: vw(14, $s);
      border-radius: vw(8, $s);
      font-family: var(--font-regular);
      width: 100%;
      color: #000000D9;
      opacity: 1 !important;
      .anticon, .ant-picker-separator {
        color: #00000040;
      }
      .ant-picker-separator {
        height: vw(16, $s);
        font-size: vw(16, $s);
      }
      .ant-picker-input >input {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        min-width: vw(1, $s);
      }
    }
    .ant-picker-range {
      .ant-picker-range-separator {
        padding: 0 vw(8, $s);
      }
      .ant-picker-clear {
        inset-inline-end: vw(11, $s);
      }
    }
  }

  .t-select-ctrl-bg {
    &.ant-select {
      min-width: vw(154, $s);
      font-size: vw(14, $s);
      height: vw(32, $s);
      .ant-select-selector {
        color: #344054;
        padding: 0 vw(16, $s) !important;
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
        font-size: vw(14, $s);
        .ant-select-selection-search {
          inset-inline-start: vw(11, $s);
          inset-inline-end: vw(11, $s);
        }
      }
      .ant-select-arrow {
        inset-inline-end: vw(11, $s);
        height: vw(12, $s);
        margin-top: vw(-6, $s);
        font-size: vw(12, $s);
      }
    }
  }

  .marketing-campaigns {
    .ant-breadcrumb{
      font-size: vw(14, $s);
      color: #98A2B3;
      line-height: vw(22, $s);
      font-family: var(--font-regular);
      margin-top: 0;
      a {
        color: #98A2B3;
        height: auto;
      }
      li:last-child {
        color: #344054;
      }
      .ant-breadcrumb-separator {
        margin-inline: vw(8, $s);
        color: #98A2B3;
      }
    }
    .title-page {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(14, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
    }

    .filter-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        gap: vw(12, $s);
        align-items: center;
        .title {
          font-size: vw(16, $s);
          color: #344054;
          line-height: vw(24, $s);
          font-weight: 500;
          font-family: var(--font-medium);
        }
      }
    }

    .list-tabs {
      display: flex;
      gap: vw(32, $s);
      .one-tab {
        display: flex;
        align-items: center;
        gap: vw(12, $s);
        padding-bottom: vw(12, $s);
        padding-top: vw(12, $s);
        border-bottom: vw(2, $s) solid transparent;
        cursor: pointer;
        &.active {
          color: #096DD9;
          border-bottom: vw(2, $s) solid #096DD9;
        }
        .icon-svg {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .t-btn-df {
    display: flex;
    align-items: center;
    height: vw(32, $s);
    justify-content: center;
    border-radius: vw(6, $s);
    padding: vw(8, $s) vw(15, $s);
    color: #344054;
    cursor: pointer;
    gap: vw(10, $s);
    border: vw(1, $s) solid #EAECF0;
  }

  .marketing-campaigns-detail {
    .title-btn-back {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(26, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
      display: flex;
      align-items: center;
      gap: vw(16, $s);
      color: #344054;
      .icon-svg {
        color: #000000E0;
        font-size: vw(16, $s);
        display: flex;
        align-items: center;
      }
    }

    .line-center {
      color: #D0D5DD;
      display: flex;
      align-items: center;
    }

    .detail-block {
      margin: vw(24, $s) 0;
      .overview-block {
        display: flex;
        .total {
          background: linear-gradient(94.38deg, #1890FF 0%, #005DB3 99.66%);
          padding: vw(21, $s) vw(24, $s);
          border-radius: vw(12, $s);
          position: relative;
          flex: 0 0 auto;
          max-width: vw(676, $s);
          width: 100%;

          .bg-img-total {
            width: vw(126, $s);
            height: vw(84, $s);
            position: absolute;
            top: vw(24, $s);
            right: vw(24, $s);
            z-index: 1;
            pointer-events: none;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .title-block {
            display: flex;
            align-items: center;
            gap: vw(4, $s);
            .title {
              font-size: vw(16, $s);
              font-family: var(--font-medium);
              color: #fff;
              font-weight: 500;
              line-height: vw(24, $s);
            }
            .icon-svg {
              font-size: vw(16, $s);
              display: flex;
              align-items: center;
            }
          }
          .list-item {
            display: grid;
            grid-template-columns: repeat(3, vw(164.4, $s));
            margin-top: vw(15, $s);
            .one-item {
              color: #fff;
              display: flex;
              flex-direction: column;
              gap: vw(4, $s);
              .title {
                color: #F9FAFB;
              }
              .number {
                color: #fff;
                font-size: vw(24, $s);
                line-height: vw(32, $s);
                font-family: var(--font-medium);
                padding-top: vw(5, $s);
                padding-bottom: vw(5, $s);
              }
            }
          }
        }
        .chart {
          flex: 0 0 auto;
          max-width: calc(100% - vw(676, $s));
          width: 100%;
          >div>div {
            height: vw(222, $s) !important;
            font-size: vw(14, $s);
            color: red;
          }
        }
      }

      .title-date {
        margin-top: vw(24, $s);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: vw(18, $s);
        .title-block {
          font-size: vw(16, $s);
          font-weight: 500;
          line-height: vw(24, $s);
          span {
            font-family: var(--font-bold);
            color: #344054;
            font-weight: 600;
            margin-left: vw(4, $s);
            margin-right: vw(4, $s);
          }
        }
      }
      .ctrl-table-nkh {
        display: flex;
        justify-content: space-between;
        a {
          color: #1890FF;
          font-size: vw(14, $s);
          line-height: vw(22, $s);
          font-family: var(--font-regular);
          font-weight: 400;
          padding: 0 vw(15, $s);
        }
      }
    }

    .filter-search-block {
      margin: vw(24, $s) 0 vw(18, $s) 0;
      display: flex;
      align-items: center;
      gap: vw(12, $s);
      .btn-icon {
        width: vw(32, $s);
        height: vw(32, $s);
        border: vw(1, $s) solid #D9D9D9;
        border-radius: vw(8, $s);
        color: #344054;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .t-search-block {
      // max-width: vw(1154, $s);
      // width: 100%;
      width: auto;
      max-width: 100%;
      flex-grow: 1;
      height: vw(32, $s);

      .ant-input-wrapper .ant-input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        height: vw(32, $s);
        border-radius: vw(8, $s);
        border-width: vw(1, $s);
        font-size: vw(14, $s);
        padding: vw(4, $s) vw(11, $s);
        line-height: vw(22, $s);
      }

      .ant-btn {
        height: vw(32, $s);
        width: vw(32, $s);
        border-start-end-radius: vw(8, $s) !important;
        border-end-end-radius: vw(8, $s) !important;
        .ant-btn-icon {
          color: #8a8a8b;
        }
        .anticon {
          font-size: vw(16, $s);
        }
      }

      .ant-input-wrapper {
        height: 100%;
        .ant-input-outlined, .ant-btn-default {
          border: vw(1, $s) solid #F2F4F7;
          height: vw(32, $s);
        }
        .ant-input {
          border-start-end-radius: 0;
          border-end-end-radius: 0;
          font-size: vw(14, $s);
        }
      }

      input::placeholder {
        color:  #667085 !important;
        font-size: vw(14, $s);
      }
      .ant-btn-default {
        border-color: #F2F4F7;
      }
    }
    .t-table-detail {
      .ant-table-wrapper {
        table tr th:nth-child(1) {
          width: vw(60, $s);
        }

        table tr th:nth-child(2) {
          width: vw(694, $s);
        }

        table tr th:nth-child(3) {
          width: vw(144, $s);
        }

        table tr th:nth-child(4) {
          width: vw(125, $s);
        }

        table tr th:nth-child(5) {
          width: vw(129, $s);
        }

        table tr th:nth-child(6) {
          width: vw(98, $s);
        }

        table tr th:nth-child(7) {
          width: vw(142, $s);
        }
      }
      .status {
        border: vw(1, $s) solid #ffa39e;
        background-color: #fff1f0;
        color: #F5222D;
        border-radius: vw(8, $s);
        padding: 0 vw(8, $s);
        font-size: vw(12, $s);
        font-family: var(--font-regular);
        line-height: vw(20, $s);
        height: vw(20, $s);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &.status-tc {
          border: vw(1, $s) solid #ADC6FF;
          background-color: #f0f5ff;
          color: #2F54EB;
        }
        &.status-tc-ac {
          border: vw(1, $s) solid #B7EB8F;
          background-color: #f6ffed;
          color: #52C41A;
        }
      }
    }
  }
}

@include vwConvert() using ($s) {
  .rs-popover {
    font-size: vw(12, $s);
    line-height: vw(20, $s);
    max-width: vw(200, $s);
    width: 100vw;

    .ant-popover-inner {
      padding: vw(6, $s) vw(8, $s);
      border-radius: vw(2, $s);
    }
    .ant-popover-arrow {
      width: vw(16, $s);
      height: vw(16, $s);

      &::before {
        border-width: vw(8, $s);
        clip-path: none;
      }
      &::after {
        display: none;
        height: vw(8, $s);
        width: vw(8, $s);
        box-shadow: vw(2, $s) vw(2, $s) vw(5, $s) rgba(0, 0, 0, 0.05);
        border-bottom-right-radius: vw(2, $s);
        border-radius: vw(2, $s);
      }
    }
    &.ant-popover-placement-topLeft {
      $arrowOffsetHorizontal: vw(12, $s);
      --arrow-offset-horizontal: #{$arrowOffsetHorizontal};

      > .ant-popover-arrow {
        left: vw(12, $s);
      }
    }
  }
}

.rs-popover {
  font-family: var(--font-regular);

  .ant-popover-inner {
    background: var(--primary-1);

    &-content {
      color: var(--neutral-gray-500);
    }
  }
  .ant-popover-arrow {
    &::before {
      height: auto;
      width: auto;
      border-style: solid;
      border-color: transparent transparent var(--primary-1) transparent;
    }
  }
}

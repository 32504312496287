@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .bg-login {
    // background-image: url(../../../public/images/exportImg/bg_img_login.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    width: vw(960, $s);
  }

  .bg-form-login {
    // background-image: url(../../../public/images/exportImg/bg_form_login.jpg);
    // background-repeat: no-repeat;
    // background-size: contain;
    width: vw(422, $s);
    height: vw(645, $s);
  }

  .w {
    &-544-px {
      width: vw(544.44, $s);
    }

    &-364-px {
      width: vw(364, $s);
    }
  }

  .pl {
    &-110-px {
      padding-left: vw(110, $s);
    }

    &-58-px {
      padding-left: vw(58, $s);
    }
  }

  .b {
    &-8 {
      bottom: vw(32, $s);
    }
  }

  .size-check-box .ant-checkbox {
    font-size: vw(14, $s);
  }

  .size-check-box-inner .ant-checkbox .ant-checkbox-inner {
    width: vw(16, $s);
    height: vw(16, $s);
    border-radius: vw(4, $s);
    border: vw(1, $s) solid #d9d9d9;
  }

  .icon-check-box .ant-checkbox .ant-checkbox-inner:after {
    width: vw(5.7142857142857135, $s);
    height: vw(9.142857142857142, $s);
    border: vw(2, $s) solid #fff;
    border-top: vw(0, $s);
    border-inline-start: vw(0, $s);
  }

  .shadow-login {
    box-shadow: 0px vw(2, $s) 0px 0px rgba(0, 0, 0, 0.02);

  }

  .shadow-active {
    box-shadow: 0px vw(2, $s) 0px 0px rgba(0, 0, 0, 0.04);
  }

  .p-checkbox .ant-checkbox+span {
    padding-inline-start: vw(8, $s);
    padding-inline-end: vw(8, $s);
  }
}

.bg-login {
  background: linear-gradient(180deg, rgba(0, 32, 83, 0.4136) 0%, rgba(0, 83, 215, 0.94) 73.74%);
  // background: linear-gradient(180deg, rgba(0, 32, 83, 0.4136) 0%, #323333f0 73.74%);
  background-color: #002766;
}


.font-input .ant-input {
  font-family: var(--font-regular) !important;
}

.absolute {
  position: absolute;
}

.input-password-none.ant-input-outlined:focus-within {
  border-color: unset !important;
  box-shadow: unset !important;
  outline: unset !important;
  background: #FCFCFD !important;
}

.hover-none.ant-input-outlined:hover {
  border-color: unset !important;
  background: #FCFCFD !important;
}

.p-none-input-password.ant-input-affix-wrapper {
  padding: unset !important;
}

.place-holder-98A2B3 .ant-input-affix-wrapper input::placeholder {
  color: #98A2B3 !important;
  font-weight: 400 !important;
  font-family: var(--font-regular) !important;
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-ctrl-date-picker-bg {
    .ant-picker {
      border-width: vw(1, $s);
      border-color: #F2F4F7;
      padding: vw(4, $s) vw(11, $s) vw(4, $s);
      font-size: vw(14, $s);
      border-radius: vw(8, $s);
      font-family: var(--font-regular);
      width: 100%;
      color: #000000D9;
      opacity: 1 !important;

      .anticon,
      .ant-picker-separator {
        color: #00000040;
      }

      .ant-picker-separator {
        height: vw(16, $s);
        font-size: vw(16, $s);
      }

      .ant-picker-input>input {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        min-width: vw(1, $s);
      }
    }

    .ant-picker-range {
      .ant-picker-range-separator {
        padding: 0 vw(8, $s);
      }

      .ant-picker-clear {
        inset-inline-end: vw(11, $s);
      }
    }
  }

  .t-select-ctrl-bg {
    &.ant-select {
      min-width: vw(154, $s);
      font-size: vw(14, $s);
      height: vw(32, $s);

      .ant-select-selector {
        color: #344054;
        padding: 0 vw(16, $s) !important;
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
        font-size: vw(14, $s);

        .ant-select-selection-search {
          inset-inline-start: vw(11, $s);
          inset-inline-end: vw(11, $s);
        }
      }

      .ant-select-arrow {
        inset-inline-end: vw(11, $s);
        height: vw(12, $s);
        margin-top: vw(-6, $s);
        font-size: vw(12, $s);
      }
    }
  }

  .w {
    &-256-px {
      width: vw(256, $s);
    }

    &-155-px {
      width: vw(155, $s);
    }

    &-122-px {
      width: vw(122, $s);
    }

    &-121-px {
      width: vw(121, $s);
    }

    &-286-px {
      width: vw(286, $s);
    }

    &-326-px {
      width: vw(326.71, $s);
    }

    &-313-px {
      width: vw(313, $s);
    }

    &-138-px {
      width: vw(138, $s);
    }

    &-176-px {
      width: vw(176, $s);
    }
  }

  .h {
    &-22-px {
      height: vw(22, $s);
    }

    &-200-px {
      height: vw(200, $s);
    }
  }

  .radius-select-2-px.ant-select-single .ant-select-selector {
    border-radius: vw(2, $s) !important;
  }

  .custome-select {
    &.ant-select-single {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: 100%;
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border: vw(1, $s) solid #F2F4F7;
      background: #FCFCFD;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &.ant-select-single .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);

      .ant-select-selection-search {
        inset-inline-start: vw(11, $s);
        inset-inline-end: vw(11, $s);
      }
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(32, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      font-size: vw(12, $s);
      color: #344054;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: vw(30, $s);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      color: #344054;
      font-family: var(--font-regular) !important;
      font-weight: 400;
      line-height: vw(30, $s);
    }
  }

  .popup-select {
    &.ant-select-dropdown {
      padding: vw(4, $s);
      font-size: vw(14, $s);
      border-radius: vw(8, $s);

      .ant-select-item {
        min-height: vw(32, $s);
        padding: vw(4, $s);
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
      }

      .rc-virtual-list-holder {
        max-height: vw(256, $s) !important;
      }
    }
  }

}

.bg-linear-group-customer {
  background: linear-gradient(100deg, #FFF 7.42%, #EEF7FF 100%), linear-gradient(100deg, #FFF 7.42%, #EEF7FF 100%);
}

.fix-list-customer-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
}

@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .pl {
    &-9-px {
      padding-left: vw(9, $s);
    }
  }

  .px {
    &-7-px {
      padding-left: vw(7, $s);
      padding-right: vw(7, $s);
    }
  }

  .h {
    &-22-px {
      height: vw(22, $s);
    }
  }

  .modal-content-addCustomer.ant-modal .ant-modal-content {
    border-radius: vw(2, $s) !important;
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05) !important;
    padding: unset !important;
    height: auto;
  }

  .h-popUp-168-px .rc-virtual-list-holder {
    max-height: vw(168, $s) !important;
    overflow-y: scroll !important;
  }

  .modal-content-update-group.ant-modal .ant-modal-content {
    border-radius: vw(2, $s) !important;
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05) !important;
    padding: unset !important;
    height: auto;
  }

  .text-area-hover-none.ant-input-outlined:hover {
    border-color: #F2F4F7;
    background-color: #FCFCFD !important;
  }

  .text-area-hover.ant-input-outlined:hover {
    border-color: #4096ff;
    background-color: #FCFCFD !important;
  }

  .custom-text-area textarea.ant-input {
    max-width: 100%;
    height: vw(54, $s);
    min-height: vw(32, $s);
    line-height: 1.5714285714285714;
  }

  .text-area-input.ant-input {
    padding: vw(4, $s) vw(11, $s);
    font-size: vw(14, $s);
    border-radius: vw(8, $s);
    font-family: var(--font-regular);
  }

  .custom-radius-text-area.ant-input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: vw(12, $s);
    border-bottom-right-radius: vw(12, $s);
    font-family: var(--font-regular);
    padding: vw(4, $s) vw(11, $s);
    font-size: vw(14, $s);
  }

  .custom-select-mutiple {
    &.ant-select-multiple {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: 100%;
    }

    &.ant-select-multiple .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
      padding-inline-end: vw(24, $s);
      padding-inline: vw(4, $s);
      padding-block: vw(1, $s);
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border: vw(1, $s) solid #F2F4F7;
      background: #FCFCFD;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
      border: vw(1, $s) solid #ff4d4f;
    }

    &.ant-select-multiple .ant-select-selection-search {
      margin-inline-start: vw(8, $s)
    }

    &.ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
      margin-inline-start: vw(8, $s)
    }

    &.ant-select-multiple .ant-select-selection-placeholder {
      inset-inline-start: vw(11, $s);
      inset-inline-end: vw(11, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(14, $s);
      margin-top: vw(-6, $s);
      font-size: vw(14, $s);
      color: #8B8B8B;
    }
  }

  .custome-select-dv {
    &.ant-select-single {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: 100%;
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border: vw(1, $s) solid #F2F4F7;
      background: #FCFCFD;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &.ant-select-single .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(32, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      font-size: vw(12, $s);
      color: #98A2B3;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: vw(30, $s);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-family: var(--font-regular) !important;
      font-weight: 400;
      line-height: vw(30, $s);
    }
  }

  .popup-select {
    &.ant-select-dropdown {
      padding: vw(4, $s);
      font-size: vw(14, $s);
      border-radius: vw(8, $s);

      .ant-select-item {
        min-height: vw(32, $s);
        padding: vw(4, $s);
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
      }

      .rc-virtual-list-holder {
        max-height: vw(256, $s) !important;
      }
    }
  }

  .custom-select-mutiple-dv {
    &.ant-select-multiple {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: 100%;
    }

    &.ant-select-multiple .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
      // padding: unset !important;
      padding-inline-end: vw(24, $s);
      padding-inline: vw(4, $s);
      padding-block: vw(1, $s);
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border: vw(1, $s) solid #F2F4F7;
      background: #FCFCFD;
    }

    &.ant-select-multiple .ant-select-selection-search {
      margin-inline-start: vw(8, $s)
    }

    &.ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
      margin-inline-start: vw(8, $s)
    }

    &.ant-select-multiple .ant-select-selection-placeholder {
      inset-inline-start: vw(11, $s);
      inset-inline-end: vw(11, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(14, $s);
      margin-top: vw(-6, $s);
      font-size: vw(14, $s);
      color: #8B8B8B;
    }
  }

  .placehoder-667085.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: #667085;
    font-family: var(--font-regular) !important;
    font-weight: 400;
    line-height: vw(30, $s);
  }
}

.d-none-checkbox .ant-checkbox {
  display: none !important;
}

.p-none-checkbox .ant-checkbox+span {
  padding: unset !important;
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-search {
    &-block {
      padding-block-start: vw(97, $s);
      width: vw(600, $s);
      gap: vw(20, $s);

      .title {
        font-size: vw(38, $s);
        line-height: vw(46, $s);
        padding-block-end: vw(12, $s);
      }
      .no-result-img {
        height: vw(240, $s);
        width: vw(240, $s);
      }
      .search-loading-img {
        width: vw(320, $s);
      }
    }
  }
  .voucher-update-form {
    .ant-input,
    .rs-select .ant-select-arrow {
      font-size: vw(14, $s);
    }
  }
}

.voucher-search {
  &-content {
    display: flex;
    flex-direction: column;
  }
  &-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: auto;

    .title {
      font-family: var(--font-medium);
    }
    .voucher-result-card {
      width: 100%;
    }
  }
}

.voucher-update-form {
  .ant-picker-outlined,
  &:not(.ant-select-status-error) .ant-select-selector {
    border-color: var(--neutral-gray-100);
  }
  .ant-input::placeholder,
  .ant-picker-input > input::placeholder,
  .ant-picker .ant-picker-suffix,
  .ant-select .ant-select-arrow,
  .ant-select .ant-select-selection-placeholder {
    color: var(--neutral-gray-500);
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .car-card {
    border-radius: vw(12, $s);
    padding: vw(24, $s) vw(16, $s);
    gap: vw(12, $s);
    box-shadow: 0 0 0 vw(1, $s) #FFFFFF;

    &__avatar {
      height: vw(130, $s);
    }
    .brand {
      height: vw(166, $s);
      width: vw(250, $s);
      top: vw(24, $s);
      right: vw(24, $s);
    }
  }
}

.car-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(99.66deg, #EBF2F3 7.42%, #EEF7FF 100%);

  &__name,
  &__avatar {
    position: relative;
  }
  &__avatar {
    object-fit: contain;
  }
  .brand {
    position: absolute;
    pointer-events: none;
    z-index: 0;
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-list-label {
    .label-block {
      display: flex;
      gap: vw(12, $s);
    }

    .label {
      display: inline-flex;
      gap: vw(8, $s);
      height: vw(30, $s);
      align-items: center;
      padding: 0 vw(10, $s);
      border-radius: vw(8, $s);
      border: vw(1, $s) solid #EAECF0;
      background-color: #fff;
      color: #344054;
      cursor: pointer;

      &.type-1 {
        border: vw(1, $s) solid #FA541C;
        background-color: #FFF2E8;
        color: #FA541C;
      }

      &.type-2 {
        border: vw(1, $s) solid #40A9FF;
        background-color: #E6F7FF;
        color: #1890FF;
      }

      &.type-3 {
        border: vw(1, $s) solid #722ED1;
        background-color: #F9F0FF;
        color: #722ED1;
      }

      &.type-4 {
        border: vw(1, $s) solid #EB2F96;
        background-color: #FFF0F6;
        color: #EB2F96;
      }

      .icon-svg {
        display: flex;
        align-items: center;
        font-size: vw(16, $s);
      }

      .text {
        font-size: vw(14, $s);
        font-family: var(--font-medium);
      }
    }
  }

  .cl-1890FF {
    color: #1890FF;
  }

  .t-px-4 {
    padding-left: vw(4, $s);
    padding-right: vw(4, $s);
  }

  .t-mb-24 {
    margin-bottom: vw(24, $s);
  }

  .t-mb-16 {
    margin-bottom: vw(16, $s) !important;
  }

  .t-mb-12 {
    margin-bottom: vw(12, $s);
  }

  .t-bg-FCFCFD {
    background-color: #FCFCFD;
  }

  .t-title-16-bold {
    font-size: vw(16, $s);
    line-height: vw(24, $s);
    font-family: var(--font-bold);
  }

  .form-add-notification {
    display: flex;
    margin-top: vw(24, $s);
    .left {
      flex: 0 0 auto;
      max-width: vw(769, $s);
      width: 100%;

      .t-form {
        .ant-form-item {
          margin-bottom: vw(24, $s);
        }
        .ant-form-item-explain {
          font-size: vw(14, $s);
          line-height: vw(22, $s);
          font-family: var(--font-regular);
        }
        .input-gruop {
          .label-mod {
            font-size: vw(16, $s);
            font-family: var(--font-medium);
            margin-bottom: vw(16, $s);
            color: #344054;
          }
        }
        .t-textarea {
          border: vw(1, $s) solid #F2F4F7;
          border-radius: vw(8, $s);
          background-color: #FCFCFD;
        }
      }

      .list-add-filter-content {
        .ant-checkbox+span {
          padding-inline-start: vw(0, $s);
          padding-inline-end: vw(0, $s);
          color: #344054;
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #1890FF;
          }
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: none;
          border-radius: vw(2, $s);
          background-color: #f1f5f9;

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }

      .ant-checkbox-group {
        column-gap: vw(16, $s);
      }

      .input-name-btn-sumit {
        display: flex;
        .ctrl-w-input, .ctrl-w-btn {
          flex: 0 0 auto;
          width: 100%;
        }

        .ctrl-w-input {
          max-width: calc(100% - vw(118, $s));
          padding-right: vw(24, $s);
        }

        .ctrl-w-input.ctrl-w-btn {
          max-width: vw(118, $s);
        }

        .btn {
          display: flex;
          align-items: center;
          gap: vw(10, $s);
          height: vw(40, $s);
          padding: 0 vw(15, $s);
          border-radius: vw(8, $s);
          background-color: #096DD9;
          .icon-svg {
            display: flex;
            align-items: center;
            font-size: vw(14, $s);
            line-height: vw(22, $s);
          }
          .text {
            font-size: vw(16, $s);
            line-height: 1;
            display: flex;
            align-items: center;
          }
        }
      }

      .note {
        color: #98A2B3;
        display: flex;
        gap: vw(6, $s);
        justify-content: flex-end;
        .text-block {
          color: #98A2B3;
          font-size: vw(12, $s);
          line-height: vw(20, $s);
        }
        .icon-svg {
          font-size: vw(12, $s);
        }
      }
    }
    .right {
      flex: 0 0 auto;
      max-width: calc(100% - vw(769, $s));
      width: 100%;
      padding-left: vw(48, $s);
      display: block;

      .inner-mod {
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(12, $s);
        min-height: vw(700, $s);
        box-shadow: 0px vw(2, $s) 0px 0px #0000000B;

        .img-block {
          padding: vw(12, $s) vw(24, $s);
          background-color: #F9FAFB;
          display: flex;
          align-items: center;
          gap: vw(12, $s);

          img {
            object-fit: contain;
            width: vw(40, $s);
            height: vw(40, $s);
          }

          .title {
            font-family: var(--font-bold);
            font-size: vw(16, $s);
            line-height: vw(24, $s);
          }
        }

        .content-block {
          margin-top: vw(40, $s);
          margin-bottom: vw(12, $s);
          background-color: #F2F4F7;
          padding: vw(8, $s);
          border-radius: vw(8, $s);
          display: inline-block;

          a {
            margin-left: vw(4, $s);
            margin-right: vw(4, $s);
            color: #1890FF;
          }
        }

        .time {
          color: #98A2B3;
          font-size: vw(12, $s);
          line-height: vw(20, $s);
        }
      }

      .btn-reset {
        display: flex;
        justify-content: center;
        margin-top: vw(12, $s);
        .btn {
          display: inline-flex;
          height: vw(32, $s);
          gap: vw(8, $s);
          border-radius: vw(8, $s);
          align-items: center;
          color: #344054;
          padding: 0 vw(16, $s);
          cursor: pointer;
          border: vw(1, $s) solid #F2F4F7;
          background-color: #FCFCFD;
          .icon-svg {
            display: flex;
            align-items: center;
            font-size: vw(14, $s);
          }
        }
      }
    }
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .automated-campaign-table {
    .channel-img {
      height: vw(24, $s);
      width: vw(24, $s);
    }
    .ant-table-cell,
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > th,
    .ant-table-tbody > tr > td,
    tfoot > tr > th,
    tfoot > tr > td {
      padding-inline: vw(10, $s);
    }
    .action {
      gap: vw(10, $s);
    }
    .status {
      gap: vw(8, $s);

      &::before {
        height: vw(6, $s);
      }
    }
  }
}

.automated-campaign-table {
  .channel-img {
    line-height: 1;
  }
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-tbody > tr > td,
  tfoot > tr > th,
  tfoot > tr > td {
    line-height: 1;
  }
  .status {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      aspect-ratio: 1;
      border-radius: 50%;
      display: block;
    }
    &-sent {
      &::before {
        background: #52C41A;
      }
    }
    &-sending {
      &::before {
        background: #1890FF;
      }
    }
    &-pending {
      &::before {
        background: #FAAD14;
      }
    }
    span:last-child {
      margin-inline-start: auto;
    }
  }
}

@import  '../../../../styles//root.scss';

@include vwConvert() using($s) {
  .account-role-item {
    padding: vw(16, $s);
    gap: vw(12, $s);
    display: flex;
    flex-direction: column;

    &::before {
      border-radius: vw(12, $s);
      border-width: vw(1, $s);
    }
    &::after {
      border-radius: vw(12, $s);
    }
    &__title {
      gap: vw(12, $s);
      padding-block-start: vw(4, $s);
    }
    .action {
      box-shadow: 0 vw(2, $s) 0 0 #00000004;
      height: vw(40, $s);
      border-radius: vw(8, $s);
      font-size: vw(16, $s);

      &s {
        gap: vw(8, $s);
      }
    }
    .item {
      gap: vw(8, $s);
    }
    .bg-img {
      bottom: vw(9, $s);
      right: vw(-15, $s);
      height: vw(100, $s);
    }
  }
}

.account-role-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 1;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
    -webkit-mask:
      linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%) padding-box,
      linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);
    pointer-events: none;
    z-index: -1;
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .action {
    background: #FFFFFF;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &s {
      display: flex;
      align-items: center;
    }
    &--delete {
      color: var(--dust-red-6);
    }
  }
  .item {
    display: flex;
    align-items: center;
  }
  .bg-img {
    position: absolute;
    pointer-events: none;
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .vehicle-info-popup__content {
    .avatar {
      height: vw(198, $s);
      width: vw(352, $s);
    }
    .block {
      padding: vw(18, $s) vw(16, $s);
      gap: vw(12, $s);

      &:not(:last-child) {
        border-bottom: vw(1, $s) solid var(--neutral-gray-200);
      }
      .ant-btn.primary-outline {
        margin-block-start: vw(6, $s);
        font-size: vw(14, $s);
      }
      .item {
        &-right {
          gap: vw(12, $s);

          &.flag {
            img {
              width: vw(21, $s);
              height: vw(15, $s);
            }
          }
          &.brand {
            img {
              width: vw(24, $s);
              height: vw(24, $s);
            }
          }
        }
      }
    }
  }
}

.vehicle-info-popup__content {
  display: flex;
  flex-direction: column;

  .avatar {
    object-fit: cover;
    aspect-ratio: 1;
  }
  .block {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      justify-content: space-between;

      &-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .ant-btn.primary-outline {
      font-family: var(--font-medium);
    }
  }
}
@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .card-issuance-list .add-card-issuance .t-search-block .ant-input-wrapper .ant-input {
    border: vw(1, $s) solid #F2F4F7;
    &::placeholder {
      color: #667085;
    }
  }

  .add-card-issuance {
    .title-or {
      font-family: var(--font-medium);
      margin: vw(12, $s) 0;
    }
    .form-block {
      display: flex;
      flex-direction: column;
      gap: vw(10, $s);
      .ant-input-affix-wrapper-lg {
        padding: vw(7, $s) vw(11, $s);
        font-size: vw(16, $s);
        border-radius: vw(8, $s);
        background-color: #fcfcfd;
        border-color: #F2F4F7;
        border-width: vw(1, $s);
        color: #98A2B3;
        &::placeholder {
          color: #98A2B3;
          opacity: 1;
        }
        .ant-input-prefix {
          margin-inline-end: vw(8, $s);
          color: #000000E0;
        }
      }
      .t-select-ctrl.ant-select {
        height: vw(40, $s);
        .ant-select-selector {
          background-color: #fcfcfd;
          color: #98A2B3;
        }
        .ant-select-selection-search-input {
          font-size: vw(16, $s) !important;
        }
      }
      .ant-form-item {
        font-size: vw(14, $s);
        margin-bottom: 0;
        .ant-form-item-explain {
          font-size: vw(14, $s);
        }
      }
    }
    .ant-btn {
      font-size: vw(14, $s);
    }
  }
}
@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  @keyframes progress {
    0% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

  div.loading {
    position: relative;
    width: vw(100, $s);
    height: vw(8, $s);
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 23;
    border-radius: vw(100, $s);
    overflow: hidden;
  }

  div.bar {
    position: relative;
    height: vw(8, $s);
    width: vw(100, $s);
    background-color: transparent;
    border-radius: vw(100, $s);

  }

  div.bar::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left;
    background-color: #1890FF;
    animation: progress 2s infinite;
  }

  div.bar::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '';
    text-align: center;
    mix-blend-mode: difference;
    color: white;
    text-transform: uppercase;
    font-weight: 800;
  }
}

.hidden {
  display: none;
}
@include vwConvert() using ($s) {
  .ant-notification {
    margin-block-end: vw(24, $s);
    margin-inline-end: vw(24, $s);

    .ant-notification-notice-wrapper{
      border-radius: vw(6, $s);
      box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08),0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12),0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);

      .ant-notification-notice {
        padding: vw(9, $s) vw(16, $s);
        border-radius: vw(6, $s);
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        width: vw(384, $s);
        max-width: calc(100vw - vw(48, $s));

        &-icon {
          font-size: vw(18, $s);
        }
        &-success {
          border-width: vw(1, $s);
        }
        &.single-message {
          .ant-notification-notice {
            &-with-icon {
              gap: vw(10, $s);
            }
            &-message {
              font-size: vw(16, $s);
            }
          }
        }
      }
    }
  }
}

.ant-notification {
  bottom: 0 !important;

  .ant-notification-notice-wrapper {
    margin: 0;
    background: transparent;

    .ant-notification-notice {
      font-family: var(--font-regular);

      &-icon {
        position: static;

        &-success {
          &.anticon {
            color: var(--success-600);
          }
        }
      }
      &-success {
        background: var(--success-25);
        color: var(--neutral-gray-700);
        border-style: solid;
        border-color: var(--success-600);
      }
      &-error {
        background: var(--success-25);
        color: var(--neutral-gray-700);
        border-style: solid;
        border-color: #ff4d4f;
      }
      &-warning {
        background: var(--success-25);
        color: var(--neutral-gray-700);
        border-style: solid;
        border-color: #faad14;
      }
      &.single-message {
        display: flex;
        align-items: center;

        .ant-notification-notice {
          &-message {
            margin: 0;
            padding: 0;
          }
          &-description {
            display: none;
          }
          &-with-icon {
            display: flex;
            align-items: center;
          }
        }
        .ant-notification-notice-close {
          position: static !important;
          width: auto;
          height: auto;
          color: var(--neutral-gray-400);
          margin-inline-start: auto;

          &:active,
          &:hover {
            color: var(--neutral-gray-700);
            background: transparent;
          }
        }
      }
    }
  }
}

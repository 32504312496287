@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .campaign-network-card {
    padding: vw(12, $s);
    border-radius: vw(12, $s);
    gap: vw(8, $s);

    .anticon {
      font-size: vw(24, $s);
    }
    .status {
      &::before {
        height: vw(6, $s);
        margin-inline-end: vw(8, $s);
      }
    }
    .ant-tag {
      padding-block: vw(4, $s);
    }
  }
}

.campaign-network-card {
  display: flex;
  flex-direction: column;
  background: #F0F5FF;

  .anticon {
    color: var(--primary-6);
  }
  .status {
    &::before {
      content: "";
      aspect-ratio: 1;
      border-radius: 50%;
      display: inline-flex;
      vertical-align: middle;
    }
    &-success {
      &::before {
        background: var(--polar-green-6);
      }
    }
    &-fail {
      &::before {
        background: var(--dust-red-6);
      }
    }
  }
  .ant-progress {
    .ant-progress-inner {
      background: #FFFFFF;
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .upload-image {
    &__preview {
      img {
        height: vw(64, $s);
        box-shadow: 0 0 vw(1, $s) var(--neutral-gray-500);
      }
      &-icon {
        font-size: vw(24, $s);
      }
    }
    &.empty {
      height: vw(64, $s);
    }
  }
}

.upload-image {
  display: block;
  position: relative;

  input {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;
  }
  &__preview {
    // height: var(--preview-size, 80px);
    // aspect-ratio: 1;
    // border-radius: 50%;
    // overflow: hidden;
    line-height: 1;

    img {
      object-fit: cover;
      object-position: center center;
      // height: 100%;
      // width: 100%;
      border-radius: 50%;
      aspect-ratio: 1;
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      background: rgba(55, 55, 55, 0.75);
      backdrop-filter: blur(4px);
      visibility: hidden;
      border-radius: 50%;
    }
    &-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      color: var(--primary-7);
      line-height: 1;
      pointer-events: none;
    }
  }
  &__remove {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    visibility: hidden;
    color: var(--text-alert);
  }
  // &:hover {
  //   .upload-image__preview::before {
  //     visibility: visible;
  //   }
  //   .upload-image__remove {
  //     visibility: visible;
  //   }
  // }
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // height: 80px;
    aspect-ratio: 1;
    // border-radius: 50%;
    // overflow: hidden;
    // background-color: #F3F4F6;
  }
}

@import '../../styles/root.scss';
@include vwConvert() using ($s) {
  .t-mt-28 {
    margin-top: vw(28, $s);
  }
  .t-mt-16 {
    margin-top: vw(16, $s);
  }
  .t-btn-link-icon {
    display: flex;
    gap: vw(10, $s);
    color: #fff;
    align-items: center;
    height: vw(32, $s);
    padding: vw(0, $s) vw(16, $s);
    border-radius: vw(6, $s);
    background-color: #096DD9;
    &:hover {
      color: #fff !important;
    }
    .icon-svg {
      display: flex;
      align-items: center;
    }
  }

  .class-list-page {
    .count-add-rank {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .list-rank {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: vw(24, $s);
    margin-top: vw(18, $s);
    .one-rank {
      border-radius: vw(12, $s);
      border: 1px solid rgba(208, 238, 255, 1);
      padding: vw(20, $s) vw(24, $s);
      position: relative;
      background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%),
      linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 2;
        .icon-name-rank {
          display: flex;
          gap: vw(8, $s);
          align-items: center;
          .icon-svg {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: vw(32, $s);
            height: vw(32, $s);
            flex: 0 0 auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .name {
            font-size: vw(16, $s);
            font-family: var(--font-medium);
            line-height: vw(24, $s);
          }
        }
        .list-icon-ctrl {
          display: flex;
          gap: vw(4, $s);
          align-items: center;
          .one-ctrl {
            display: flex;
            align-items: center;
            justify-content: center;
            width: vw(24, $s);
            height: vw(24, $s);
            background-color: #fff;
            border-radius: vw(4, $s);
            cursor: pointer;
            font-size: vw(14, $s);
          }
        }
      }
      .img-box {
        position: absolute;
        top: vw(11, $s);
        right: 0;
        pointer-events: none;
        z-index: 1;
        width: vw(150, $s);
        height: vw(100, $s);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .footer {
        margin-top: vw(8, $s);
        color: #98A2B3;
      }
    }
  }
}

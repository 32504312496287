@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .fz-12 {
    font-size: vw(12,$s);
  }
  .cl-096DD9 {
    color: #096DD9;
  }
  .pooling-data-detail-page {
    .title-btn-back {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(26, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
      display: flex;
      align-items: center;
      gap: vw(16, $s);
      color: #344054;

      .icon-svg {
        color: #000000E0;
        font-size: vw(16, $s);
        display: flex;
        align-items: center;
      }
    }
    .list-data {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: vw(24, $s);
      &.empty-item {
        grid-template-columns: repeat(2, 1fr);
        .select-search {
          margin-bottom: vw(55, $s);
        }
      }

      .one-data {
        padding-bottom: vw(66, $s);
      }

      .select-search {
        display: flex;
        gap: vw(12, $s);
        margin-top: vw(12, $s);

        .ant-select-selector {
          border: none !important;
        }

        .ant-input-wrapper {
          height: 100%;
          border: none;

          input,
          button {
            height: 100%;
            border: none;
          }
        }

        .ant-input {
          color: #344054;
          font-size: vw(16, $s);
          line-height: vw(24, $s);
          font-family: var(--font-regular);
          border-radius: vw(6, $s);
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-inline-end: vw(18, $s);
          line-height: vw(30, $s);
          font-size: vw(16, $s);
          color: #344054;
          font-family: var(--font-regular);
        }
      }

      .info-block {
        display: flex;
        flex-direction: column;
        padding: vw(24, $s);

        .title {
          font-family: var(--font-bold);
          font-weight: 600;
          padding: vw(4, $s) 0;
        }

        .list-info {
          display: flex;
          flex-direction: column;

          .one-info {
            padding: vw(12, $s) 0;
            display: flex;
            justify-content: space-between;

            .right {
              color: #98A2B3;
              max-width: vw(190, $s);
              text-align: right;

              .status {
                position: relative;
                display: inline-block;
                padding-left: vw(14, $s);

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: vw(6, $s);
                  height: vw(6, $s);
                  border-radius: 50%;
                  background-color: #98A2B3;
                }

                &.type-1 {
                  &::before {
                    background-color: #FAAD14;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ctrl-btn-add-new {
      display: flex;
      align-items: center;
      .btn-add-new-block {
        gap: vw(24, $s);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .btn {
          font-size: vw(36, $s);
          width: vw(56, $s);
          height: vw(56, $s);
          border-radius: vw(12, $s);
          border: vw(1, $s) dashed #1890FF;
          color: #1890FF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .title-page-cl-fabc16 {
      display: flex;
      gap: vw(16, $s);
      align-items: center;
      color: #FA8C16;
      margin-bottom: vw(24, $s);
      .anticon {
        font-size: vw(24, $s);
        padding: vw(2, $s) 0;
      }
    }

    .drag-drop-content {
      padding-left: 0;
    }

    .list-system {
      display: flex;
      gap: vw(10, $s);
      .one-system {
        border: vw(1,$s) solid #ADC6FF;
        background-color: #F0F5FF;
        border-radius: vw(8,$s);
        height: vw(22,$s);
        padding: 0 vw(8,$s);
        display: flex;
        align-items: center;
        font-size: vw(14,$s);
        color: #2F54EB;
      }
    }

    .username-system {
      padding: vw(8,$s) vw(12,$s);
      border-radius: vw(8,$s);
      border: solid vw(1,$s) #EAECF0;
      color: #344054;
      font-size: vw(16,$s);
    }

    .btn-block {
      margin-top: vw(54,$s);
      display: flex;
      justify-content: center;
      gap: vw(20, $s);
      align-items: center;
      margin-bottom: vw(32,$s);
      .btn {
        height: vw(32,$s);
        padding: 0 vw(15,$s);
        border-radius: vw(8,$s);
        box-shadow: 0 vw(6,$s) vw(8,$s) 0 #00000026;
        display: flex;
        align-items: center;
        justify-content: center;
        &.btn-primary {
          background-color: #096DD9;
          color: #fff;
        }
      }
      .bg-F2F4F7 {
        padding: vw(12,$s);
        border-radius: vw(8,$s);
        background-color: #F2F4F7;
      }
    }

    .des {
      max-width: vw(395, $s);
      padding: vw(9, $s) vw(16, $s);
      border-radius: vw( 8,$s );
      border: vw(1,$s) solid #91D5FF;
      background-color: #E6F7FF;
      margin-bottom: vw( 24,$s );
      display: flex;
      gap: vw( 14,$s );
      .icon {
        margin-top: vw( 3,$s );
      }
      .text {
        color: #344054;
        font-size: vw( 14,$s );
      }
    }

    .list-mapping {
      display: flex;
      .one-item {
        padding: 0 vw(10, $s);
        width: vw(200, $s);
        min-height: vw(458, $s);
        .title {
          font-weight: 700;
          font-size: vw(14, $s);
          color: #344054;
          display: flex;
          align-items: center;
        }
        .one-op {
          display: flex;
          gap: vw(0, $s);
          align-items: center;
          color: #98A2B3;
          .icon {
            font-size: vw(24, $s);
            display: flex;
            align-items: center;
            justify-content: center;
            flex:  0 0 auto;
            max-width: vw(24, $s);
            width: 100%;
          }
          span {
            color: #F5222D;
          }
        }
      }
      .list-add-mapping {
        overflow-x: auto;
        max-width: calc( 100% -  vw(245, $s));
        #dynamic_form_add_mapping {
          // max-width: calc( 100% -  vw(248, $s));
        }
        flex-grow: 1;
        .form-add-select {
          display: flex;
          .one-data-auto {
            flex: 0 0 vw(390, $s);
            width: vw(390, $s);
            flex-direction: column;
            padding: 0 vw(10, $s);
            border-left: vw(1, $s) solid #EAECF0;
            min-height: vw(458, $s);
            max-width: 100%;
            &.ant-space-gap-col-small {
              gap: 0 !important;
            }
            .ant-space-item {
              width: 100%;
            }
            .ant-form-item {
              margin: 0;
            }
            .title-add {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: vw(8, $s);
              width: 100%;
              .text {
                font-size: vw(14, $s);
                font-weight: 700;
                color: #344054;
                white-space: nowrap;
              }
              .icon {
                font-size: vw(16,$s);
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 100%;
                max-width: vw(16,$s);
                flex: 0 0 auto;
                &:hover {
                  .ctrl-delete {
                    display: flex;
                  }
                }
                .ctrl-delete {
                  position: absolute;
                  padding: vw(13,$s) vw(16,$s);
                  top: 100%;
                  right: 0;
                  display: flex;
                  gap: vw(10,$s);
                  align-items: center;
                  min-width: vw(140, $s);
                  font-size: vw(14, $s);
                  color: #F5222D;
                  box-shadow: 0 vw(2,$s) vw(8,$s) 0 #00000026;
                  border-radius: vw(8,$s);
                  z-index: 3;
                  background-color: #fff;
                  cursor: pointer;
                  display: none;
                }
              }
            }
          }
          .ctrl-btn-add {
            margin-bottom: 0;
            max-width: vw(240, $s);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: vw(8,$s);
            border: vw(1,$s) dashed #1890FF;
            min-height: vw(304, $s);
            width: 100%;
            padding-left: vw(50, $s);
            padding-right: vw(50, $s);
          }
        }

        .btn-primary-none-bg {
          border: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          font-size: vw(14, $s);
          gap: vw(24, $s);
          .ant-btn-icon {
            .anticon {
              width: vw(44,$s);
              height: vw(44,$s);
              display: flex;
              justify-content: center;
              border-radius: vw(12,$s);
              align-items: center;
              border: vw(1,$s) dashed #1890FF;
              color: #1890FF;
              display: inline-flex;
              font-size: vw(24,$s);
            }
          }
          span {
            font-weight: 500;
            color: #1890FF;
          }
        }

        .filter-select {
          width: 100%;
          height: vw(32, $s);
          position: relative;
          margin-top: vw(12, $s);
          .btn-select-show {
            border-radius: vw(8, $s);
            border: vw(1, $s) solid #F2F4F7;
            padding: vw(4, $s) vw(20, $s) vw(4, $s) vw(11, $s) ;
            height: vw(32, $s);
            font-size: vw(14, $s);
            cursor: pointer;
            .content-select {
              display: flex;
              gap: vw(4,$s);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              .cl-blue {
                color: #1890FF;
              }
            }
            span {
              color: #344054;
              display: block;
            }
          }

          .icon-down, .icon-close {
            font-size: vw(10, $s);
            color: #8a8a8b;
            position: absolute;
            top: 50%;
            right: vw(11, $s);
            transform: translateY(-50%);
          }

          .icon-down {
            &.active {
              display: none;
            }
          }

          .icon-close {
            display: none;
            &.active {
              display: block;
            }
            span {
              color: #ff0000;
            }
          }

          .list-filter {
            position: absolute;
            top: 100%;
            z-index: 5;
            padding: vw(8, $s);
            border-radius: vw(6, $s);
            box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
            width: 100%;
            margin-top: vw(12, $s);
            display: none;
            background-color: #fff;
            &.active {
              display: block;
            }
            input {
              margin-bottom: vw(8, $s);
            }
            .list-item {
              max-height: vw(320, $s);
              overflow-y: auto;
            }
            .ant-input, .item-select {
              border-radius: vw(2, $s);
              padding: vw(5, $s) vw(12, $s);
            }
            .item-select {
              margin-bottom: vw(8, $s);
              display: flex;
              color: #667085;
              gap: vw(8, $s);
              align-items: center;
              justify-content: space-between;
              padding: vw(5, $s) vw(12, $s);
              font-size: vw(14, $s);

              .icon-left {
                font-size: vw(12, $s);
                display: flex;
                gap: vw(8, $s);
              }
              .icon-right {
                font-size: vw(12, $s);
                color: #FF7875;
              }
            }
            .btn-filter {
              border-radius: vw(6, $s);
              border: vw(1, $s) solid #F2F4F7;
              padding: vw(4, $s) vw(11, $s);
              height: vw(32, $s);
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              background-color: #096DD9;
              color: #fff;
              gap: vw(10, $s);
              font-size: vw(14, $s);
              .icon-btn-filter {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .ctrl-select-system {
      display: none;
      &.active {
        display: block;
      }
    }
    .mt--10 {
      margin-top: vw(-10, $s) !important;
    }
    .h-44 {
      height: vw(44, $s);
    }
    .mb-24 {
      margin-bottom: vw(24, $s);
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .custom-upload-ant {
    &.ant-upload-wrapper {
      font-size: vw(14, $s);
      font-family: var(--font-regular);

      .ant-upload-drag {
        border: 1px solid #d9d9d9;
        border-radius: vw(8, $s);

        .ant-upload {
          padding: vw(16, $s);
        }

        .ant-upload-btn {
          border-radius: vw(8, $s);
        }

        p.ant-upload-drag-icon {
          margin-bottom: vw(16, $s);
        }

        p.ant-upload-drag-icon .anticon {
          font-size: vw(48, $s);
        }
      }

      .ant-upload-list .ant-upload-list-item {
        height: vw(22, $s);
        margin-top: vw(8, $s);
        font-size: vw(14, $s);

        .ant-upload-icon .anticon {
          font-size: vw(14, $s);
        }

        .ant-upload-list-item-name {

          padding: 0 vw(8, $s);
        }

        .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn {
          height: vw(20, $s);
        }

        .ant-btn-sm.ant-btn-icon-only {
          width: vw(24, $s);
        }

        .ant-btn-sm.ant-btn-icon-only .anticon {
          font-size: vw(14, $s);
        }
      }
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .app-breadcrumb {
    &-block {
      // padding-block-start: vw(16, $s);

      .ant-breadcrumb {
        font-size: inherit;

        ol {
          gap: vw(8, $s);
          align-items: center;
        }

        .ant-breadcrumb-separator {
          margin-inline: unset;
          color: #98A2B3;
        }
      }
      &.gutter-block-end {
        padding-block-end: vw(18, $s);
      }
    }

    &-title {
      gap: vw(16, $s);
      padding-block: vw(14, $s) vw(6, $s);
      .icon{
        display: flex;
      }
      .title {
        font-size: vw(20, $s);
        line-height: vw(28, $s);
      }

      .description {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        margin-inline-start: vw(-4, $s);
      }
    }
  }
}

.app-breadcrumb {
  &-block {
    display: flex;
    flex-direction: column;

    .ant-breadcrumb {
      color: var(--neutral-gray-400);
      font-family: inherit;

      &-separator {
        margin: 0;
      }

      a {
        height: auto;
        padding: 0;
        margin: 0;
        color: var(--neutral-gray-400);

        &:hover {
          background: transparent;
          color: var(--primary-7);
        }
      }

      li {
        &:last-child {
          color: var(--neutral-gray-700);
        }
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;

    .title {
      font-family: var(--font-medium);

      .anticon {
        font-size: inherit;
      }
    }

    .description {
      color: var(--neutral-gray-400);
    }
  }
}

@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {

  .t-mb-24 {
    margin-bottom: vw(24, $s);
  }

  .add-filter {
    background-color: #fff;

    &.active {
      display: block;
    }

    .title-add-filter {
      padding: vw(12, $s) vw(24, $s);
      border-bottom: vw(1, $s) solid #f0f0f0;
      font-size: vw(16, $s);
      font-weight: 500;
      line-height: vw(22, $s);
      color: #344054;
      font-family: var(--font-medium);
      min-height: vw(48, $s);
    }

    .ant-tree {
      border-radius: vw(2, $s);
      font-size: vw(14, $s);

      .ant-tree-checkbox.ant-tree-checkbox-checked {
        .ant-tree-checkbox-inner {
          background-color: #1890FF;
        }
      }

      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
        border: none !important;
      }

      .ant-tree-checkbox-inner {
        width: vw(16, $s);
        height: vw(16, $s);
        border: none;
        border-radius: vw(2, $s);
        background-color: #f1f5f9;

        &::after {
          width: vw(7, $s);
          height: vw(8, $s);
          border: vw(2, $s) solid #fff;
          border-top: none;
          border-left: none;
        }
      }
    }

    .ant-checkbox {
      border-radius: vw(2, $s);
      font-size: vw(14, $s);

      &.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1890FF;
      }

      .ant-checkbox-inner {
        width: vw(16, $s);
        height: vw(16, $s);
        border: none;
        border-radius: vw(2, $s);
        background-color: #f1f5f9;

        &::after {
          width: vw(7, $s);
          height: vw(8, $s);
          border: vw(2, $s) solid #fff;
          border-top: none;
          border-left: none;
        }
      }
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: vw(12, $s);
      margin-top: vw(12, $s);
    }

    .col-add-filter {
      width: vw(277, $s);

      &:not(:last-child) {
        border-right: vw(1, $s) solid #EAECF0;
        padding-right: vw(4, $s);
      }
    }

    .ant-checkbox-wrapper {
      font-size: vw(14, $s) !important;
      padding: vw(8, $s) vw(12, $s);
      border-radius: vw(8, $s);
      color: #667085;
      font-family: var(--font-regular);
      align-items: center;
      padding-top: vw(4, $s);

      &.ant-checkbox-wrapper-checked {
        background-color: #E6F7FF;

        .text {
          color: #1890FF;
        }
      }

      &:after {
        display: none;
      }
    }

    .col-title-add-filter {
      font-weight: 500;
      padding: vw(4, $s) vw(8, $s);
      color: #344054;
      font-family: var(--font-medium);
    }

    .ant-checkbox+span {
      padding-inline-start: vw(0, $s);
      padding-inline-end: vw(0, $s);
      width: 100%;
    }

    .list-add-filter-content {
      .one-add-filter-content {
        display: flex;
        align-items: center;

        .img-text {
          display: flex;
          align-items: center;
          margin-left: vw(8, $s);

          img {
            width: vw(16, $s);
            height: vw(16, $s);
            object-fit: contain;
          }
        }

        .text {
          padding-left: vw(8, $s);
          line-height: 1;
        }
      }
    }

    .slider-price {
      padding-left: vw(6, $s);

      .ant-slider {
        margin: vw(11, $s) vw(5, $s);
        font-size: vw(14, $s);
      }

      .ant-slider-horizontal {
        padding-block: vw(4, $s);
        height: vw(12, $s);

        &.ant-slider-with-marks {
          margin-bottom: vw(30, $s);
        }

        .ant-slider-rail {
          height: vw(4, $s);
          border-radius: vw(2, $s);
        }

        .ant-slider-track {
          height: vw(4, $s);
          border-radius: vw(2, $s);
        }

        .ant-slider-step {
          top: vw(4, $s);
          height: vw(4, $s);
        }

        .ant-slider-dot {
          inset-block-start: vw(-2, $s);
          width: vw(8, $s);
          height: vw(8, $s);
          background-color: #ffffff;
          border: vw(2, $s) solid #91caff;
        }

        .ant-slider-handle {
          inset-block-start: vw(1, $s);
          width: vw(10, $s);
          height: vw(10, $s);

          &::before {
            inset-inline-start: vw(-2, $s);
            inset-block-start: vw(-2, $s);
            width: vw(14, $s);
            height: vw(14, $s);
          }

          &::after {
            width: vw(10, $s);
            height: vw(10, $s);
            box-shadow: 0 0 0 vw(2, $s) #91caff;
          }
        }

        .ant-slider-mark {
          top: vw(12, $s);
          font-size: vw(14, $s);
        }
      }
    }

    .date-picker {
      width: vw(229, $s);
      margin-top: vw(12, $s);

      .ant-picker {
        width: vw(229, $s);
        padding: vw(4, $s) vw(11, $s) vw(4, $s);
        font-size: vw(14, $s);
        border-radius: vw(6, $s);
        border-width: vw(1, $s);
        border-color: #D9D9D9;
        font-family: var(--font-regular);

        .ant-picker-input {
          input {
            font-size: vw(14, $s);
            min-width: vw(1, $s);
          }
        }
      }
    }

    .list-select-2col {
      .checkbox+span {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }

      .ant-checkbox-group {
        flex-direction: unset;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: vw(12, $s);

        .ant-checkbox-wrapper {
          gap: vw(8, $s);
          display: flex;
          align-items: center;

          .text {
            white-space: nowrap;
            line-height: 1;
          }
        }
      }
    }

    .tree-select {
      margin-top: vw(12, $s);

      .ant-tree {
        .ant-tree-treenode {
          padding: 0 0 vw(4, $s) 0;
          position: relative;
          width: 100%;
          padding: vw(8, $s) vw(24, $s) vw(8, $s) vw(12, $s);
          display: flex;
          align-items: center;

          .ant-tree-node-content-wrapper {
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
        }

        .ant-tree-checkbox {
          margin-inline-end: vw(8, $s);
          margin-top: vw(4, $s);
          font-size: vw(14, $s);
        }

        .ant-tree-checkbox+span {
          min-height: vw(22, $s);
          margin: 0;
          padding: 0;
          color: inherit;
          line-height: 1;
          background: transparent;
          border-radius: vw(6, $s);
        }

        .ant-tree-indent-unit {
          width: vw(24, $s);
        }

        .ant-tree-switcher {
          width: vw(24, $s);
          line-height: vw(24, $s);
          border-radius: vw(6, $s);
          position: absolute;
          top: vw(12, $s);
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &.ant-tree-switcher_open {
            color: #096DD9;

            .ant-tree-switcher-icon {
              svg {
                transform: rotate(180deg);
              }
            }
          }

          .ant-tree-switcher-icon {
            font-size: vw(14, $s);

            svg {
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    .footer-filter-block {
      .btn-block {
        height: vw(48, $s);
        padding: vw(8, $s) vw(24, $s);
        border-top: vw(1, $s) solid #f0f0f0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: vw(17, $s);

        .btn {
          display: flex;
          gap: vw(8, $s);
          align-items: center;
          justify-content: center;
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #EAECF0;
          height: vw(32, $s);
          color: #000000D9;
          line-height: vw(22, $s);
          cursor: pointer;

          img {
            width: vw(14, $s);
            height: vw(14, $s);
            object-fit: contain;
          }

          &.btn-primary {
            color: #fff;
            background-color: #096DD9;
          }
        }
      }
    }
  }

  .content-add-filter {
    display: flex;

    .one-add-filter-block {
      padding-right: vw(32, $s);

      &.t-scrollbar {
        padding-right: vw(20, $s);
        max-height: vw(250, $s);
      }
    }
  }

  .t-scrollbar {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 vw(6, $s) transparent;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: vw(8, $s);
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 vw(6, $s) transparent;
      background-color: #D0D5DD;
    }
  }



  .col-title-add-filter {
    font-weight: 500;
    padding: vw(4, $s) vw(8, $s);
    color: #344054;
    font-family: var(--font-medium);
  }

  .list-add-filter-content {
    .one-add-filter-content {
      display: flex;
      align-items: center;

      .img-text {
        display: flex;
        align-items: center;
        margin-left: vw(8, $s);

        img {
          width: vw(16, $s);
          height: vw(16, $s);
          object-fit: contain;
        }
      }

      .text {
        padding-left: vw(8, $s);
        line-height: 1;
      }
    }
  }

  .slider-price {
    .ant-slider {
      margin: vw(11, $s) vw(5, $s);
      font-size: vw(14, $s);
    }

    .ant-slider-horizontal {
      padding-block: vw(4, $s);
      height: vw(12, $s);

      &.ant-slider-with-marks {
        margin-bottom: vw(30, $s);
      }

      .ant-slider-rail {
        height: vw(4, $s);
        border-radius: vw(2, $s);
      }

      .ant-slider-track {
        height: vw(4, $s);
        border-radius: vw(2, $s);
      }

      .ant-slider-step {
        top: vw(4, $s);
        height: vw(4, $s);
      }

      .ant-slider-dot {
        inset-block-start: vw(-2, $s);
        width: vw(8, $s);
        height: vw(8, $s);
        background-color: #ffffff;
        border: vw(2, $s) solid #91caff;
      }

      .ant-slider-handle {
        inset-block-start: vw(1, $s);
        width: vw(10, $s);
        height: vw(10, $s);

        &::before {
          inset-inline-start: vw(-2, $s);
          inset-block-start: vw(-2, $s);
          width: vw(14, $s);
          height: vw(14, $s);
        }

        &::after {
          width: vw(10, $s);
          height: vw(10, $s);
          box-shadow: 0 0 0 vw(2, $s) #91caff;
        }
      }

      .ant-slider-mark {
        top: vw(12, $s);
        font-size: vw(14, $s);
      }
    }
  }



  .t-ctrl-date-picker.ant-picker-dropdown {
    font-size: vw(14, $s);

    .ant-picker-header-view button:not(:first-child) {
      margin-inline-start: vw(8, $s);
    }

    .ant-picker-header button {
      line-height: vw(40, $s);
    }

    .ant-picker-header>button {
      font-size: vw(14, $s);
    }

    .ant-picker-header-view {
      line-height: vw(40, $s);
    }

    .ant-picker-next-icon {
      width: vw(7, $s);
      height: vw(7, $s);
    }

    .ant-picker-panel-container {
      border-radius: vw(8, $s);
      box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
    }

    .ant-picker-date-panel {
      width: vw(288, $s);
    }

    .ant-picker-content th {
      height: vw(36, $s);
      min-width: vw(24, $s);
    }

    table {
      border-spacing: vw(2, $s);
    }

    .ant-picker-footer {
      border-top: vw(1, $s) solid rgba(5, 5, 5, 0.06);
    }

    .ant-picker-ranges>li {
      line-height: vw(38, $s);
    }

    .ant-picker-super-prev-icon {
      width: vw(7, $s);
      height: vw(7, $s);
    }

    .ant-picker-super-prev-icon::before,
    .ant-picker-super-prev-icon::after,
    .ant-picker-next-icon::before,
    .ant-picker-next-icon::after,
    .ant-picker-super-next-icon,
    .ant-picker-super-next-icon::before,
    .ant-picker-super-next-icon::after,
    .ant-picker-prev-icon,
    .ant-picker-prev-icon::before {
      width: vw(7, $s);
      height: vw(7, $s);
      border-block-start-width: vw(1.5, $s);
      border-inline-start-width: vw(1.5, $s);
    }

    .ant-picker-super-prev-icon::after {
      top: vw(4, $s);
      inset-inline-start: vw(4, $s);
    }

    .ant-picker-super-next-icon::after {
      top: vw(4, $s);
      inset-inline-start: vw(4, $s);
    }

    .ant-picker-cell .ant-picker-cell-inner {
      min-width: vw(24, $s);
      height: vw(24, $s);
      line-height: vw(24, $s);
      border-radius: vw(4, $s);
    }

  }

  .list-select-2col {
    .checkbox+span {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    .ant-checkbox-group {
      flex-direction: unset;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: vw(12, $s);

      .ant-checkbox-wrapper {
        gap: vw(8, $s);
        display: flex;
        align-items: center;

        .text {
          white-space: nowrap;
          line-height: 1;
        }
      }
    }
  }

  .tree-select {
    margin-top: vw(12, $s);

    .ant-tree {
      .ant-tree-treenode {
        padding: 0 0 vw(4, $s) 0;
        position: relative;
        width: 100%;
        padding: vw(8, $s) vw(24, $s) vw(8, $s) vw(12, $s);
        display: flex;
        align-items: center;

        .ant-tree-node-content-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .ant-tree-checkbox {
        margin-inline-end: vw(8, $s);
        margin-top: vw(4, $s);
        font-size: vw(14, $s);
      }

      .ant-tree-checkbox+span {
        min-height: vw(22, $s);
        margin: 0;
        padding: 0;
        color: inherit;
        line-height: 1;
        background: transparent;
        border-radius: vw(6, $s);
      }

      .ant-tree-indent-unit {
        width: vw(24, $s);
      }

      .ant-tree-switcher {
        width: vw(24, $s);
        line-height: vw(24, $s);
        border-radius: vw(6, $s);
        position: absolute;
        top: vw(12, $s);
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-tree-switcher_open {
          color: #096DD9;

          .ant-tree-switcher-icon {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .ant-tree-switcher-icon {
          font-size: vw(14, $s);

          svg {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.p-r-none {
  .group-select-all {
    padding-right: 0 !important;
  }
}

.customer-list.fillter-create-group{
  .add-filter {
    position: static;
    box-shadow: none;
    .content-add-filter {
      height: auto;
      grid-template-columns: repeat(3, 1fr);
      flex-grow: 1;
      width: 100%;
      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        overflow-y: inherit !important;
      }
      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .col-add-filter {
        overflow: hidden;
      }
    }
    .content-add-filter .one-add-filter-block:last-child {
      padding-right: 0;
    }
  }
  .flex-column-1199 {
    @media screen and ( max-width: 1199px ) {
      flex-direction: column;
      gap: 24px;
    }
  }
  .pl-8 {
    @media screen and (max-width: 767px) {
      padding-left: 0px;
      border-left: none;
    }
  }
}

@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .h {
    &-214-px {
      height: vw(214, $s);
    }

    &-15-px {
      height: vw(15, $s);
    }
  }

  .w {
    &-21-px {
      width: vw(21, $s);
    }
  }

  .shadow-img {
    box-shadow: 0px 0px 0px vw(1, $s) rgba(255, 255, 255, 1);

  }

  .custom-drawer.ant-drawer {
    .ant-drawer-content-wrapper {
      width: vw(400, $s) !important;
    }

    .ant-drawer-body {
      padding: vw(24, $s);
    }

    .ant-drawer-footer {
      padding: unset !important;
    }

    .ant-drawer-header {
      padding: unset !important;
    }
  }

  .check-box-inner .ant-checkbox .ant-checkbox-inner {
    width: vw(16, $s);
    height: vw(16, $s);
    border-radius: vw(4, $s);
    border: unset;
    background-color: #F1F5F9;
  }

  .checked-active .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1677ff;
    border-color: #1677ff;
  }
}

@import '../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .modal-content-creat-area.ant-modal .ant-modal-content {
    border-radius: vw(8, $s);
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05) !important;
    padding: unset !important;
    height: auto;
  }
}

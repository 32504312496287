.neutral-gray {
  &-100 {
    color: var(--neutral-gray-100);
  }
  &-200 {
    color: var(--neutral-gray-200);
  }
  &-300 {
    color: var(--neutral-gray-300);
  }
  &-400 {
    color: var(--neutral-gray-400);
  }
  &-500 {
    color: var(--neutral-gray-500);
  }
  &-700 {
    color: var(--neutral-gray-700);
  }
}

.primary {
  &-1 {
    color: var(--primary-1);
  }
  &-3 {
    color: var(--primary-3);
  }
  &-6 {
    color: var(--primary-6);
  }
  &-7 {
    color: var(--primary-7);
  }
}

.polar-green {
  color: var(--polar-green-1);
}
.success {
  &-25 {
    color: var(--success-25);
  }
  &-600 {
    color: var(--success-600);
  }
}

.golden-purple {
  &-1 {
    color: var(--golden-purple-1);
  }
  &-3 {
    color: var(--golden-purple-3);
  }
  &-5 {
    color: var(--golden-purple-5);
  }
}

.sunset-orange {
  &-1 {
    color: var(--sunset-orange-1);
  }
  &-3 {
    color: var(--sunset-orange-3);
  }
  &-6 {
    color: var(--sunset-orange-6);
  }
}

.volcano {
  &-5 {
    color: var(--volcano-5);
  }
}
.magenta {
  &-5 {
    color: var(--magenta-5);
  }
}

.geek-blue {
  &-1 {
    color: var(--geek-blue-1);
  }
  &-3 {
    color: var(--geek-blue-3);
  }
  &-6 {
    color: var(--geek-blue-6);
  }
}

.dust-red {
  &-1 {
    color: var(--dust-red-1);
  }
  &-3 {
    color: var(--dust-red-3);
  }
  &-5 {
    color: var(--dust-red-5);
  }
  &-6 {
    color: var(--dust-red-6);
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .t-mb-24 {
    margin-bottom: vw(24, $s);
  }

  .t-mb-48 {
    margin-bottom: vw(48, $s);
  }

  .t-pt-16 {
    padding-top: vw(16, $s);
  }

  .head-box-bw {
    justify-content: space-between;

    .filter-item {
      display: flex;
      align-items: center;
      gap: vw(16, $s);

      .filter-select {
        min-width: vw(160, $s);
      }
    }

    .search {
      display: flex;
      gap: 8px;
    }
  }

  .t-scrollbar {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 vw(6, $s) transparent;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: vw(8, $s);
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 vw(6, $s) transparent;
      background-color: #D0D5DD;
    }
  }

  .table-row-light {
    background-color: #ffffff;
  }

  .table-row-dark {
    background-color: #F8F8F8;
  }

  .t-table-block {
    .ant-table-wrapper {
      .ant-table-column-sorter {
        margin-inline-start: vw(4, $s);
      }

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        font-size: vw(12, $s);
      }

      .ant-table-thead th.ant-table-column-sort {
        background: #096DD9;
      }

      .ant-table-column-sorter-down.active,
      .ant-table-column-sorter-up.active {
        color: #344054;
      }

      table tr td.ant-table-selection-column,
      .ant-table-selection-column {
        padding-inline-end: vw(8, $s);
        padding-inline-start: vw(8, $s);
      }

      table {
        border-radius: vw(8, $s) vw(8, $s) 0 0;
      }

      .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: vw(8, $s);
      }

      .ant-space-gap-col-middle {
        column-gap: vw(10, $s);
      }

      .ant-table-thead>tr>th {
        border-bottom: 0 solid #f0f0f0 !important;
        font-family: var(--font-medium);
        color: #fff;
        background-color: #096DD9;
        font-weight: 500;
        padding: vw(10, $s) vw(8, $s);
        white-space: nowrap;
        line-height: vw(24, $s);
      }

      .ant-table-tbody>tr>td {
        border-bottom: 0 solid #f0f0f0 !important;
        font-family: var(--font-regular);
      }

      .ant-table {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        color: #344054;
        font-family: var(--font-regular);
      }

      .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0;
      }

      .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: #096DD9;
      }

      .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
        background: transparent;
      }

      .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: vw(8, $s);
      }

      table tr th.ant-table-selection-column {
        padding-inline-end: vw(8, $s);
        padding-inline-start: vw(8, $s);
      }

      .ant-table-selection-column {
        .ant-checkbox-wrapper {
          font-size: vw(14, $s);
          align-items: center;
        }
      }

      .ant-checkbox {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        &.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1890FF;
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: vw(1, $s) solid #d9d9d9;
          border-radius: vw(4, $s);

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }

      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            border: none;
          }
        }
      }

      .ant-table-tbody>tr>td {
        padding: vw(9, $s) vw(8, $s);
        // white-space: nowrap;
      }

      .ant-space-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        span {
          width: vw(24, $s);
          height: vw(24, $s);
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #D9D9D9;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: vw(14, $s);
        }
      }
    }

    &.t-table-customer {
      .ant-table-wrapper {
        table tr th:nth-child(1) {
          width: vw(44, $s);
        }

        table tr th:nth-child(2) {
          width: vw(93, $s);
        }

        table tr th:nth-child(3) {
          width: vw(45, $s);
        }

        table tr th:nth-child(4) {
          width: vw(163.67, $s);
        }

        table tr th:nth-child(5) {
          width: vw(142, $s);
        }

        table tr th:nth-child(6) {
          width: vw(142, $s);
        }

        table tr th:nth-child(7) {
          width: vw(142, $s);
        }

        table tr th:nth-child(8) {
          width: vw(142, $s);
        }

        table tr th:nth-child(9) {
          width: vw(142, $s);
        }

        table tr th:nth-child(10) {
          width: vw(142, $s);
        }
      }
    }

    .pagination-block {
      padding: vw(9, $s);

      .ant-pagination {
        text-align: center;
        font-size: vw(14, $s);
        font-family: var(--font-regular);
        color: #344054;

        .ant-select-single .ant-select-selector {
          color: #344054;

          .ant-select-selection-search {
            inset-inline-start: vw(14, $s);
            inset-inline-end: vw(14, $s);
          }
        }

        .ant-pagination-item-active a {
          color: #1890FF !important;
        }

        .ant-pagination-item a {
          color: #344054;
        }

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-prev {
          min-width: vw(32, $s);
          height: vw(32, $s);
          margin-inline-end: vw(8, $s);
          font-family: var(--font-regular);
          line-height: vw(30, $s);
          border: vw(1, $s) solid #F2F4F7;
          border-radius: vw(2, $s);
        }

        .ant-pagination-item-active {
          border-color: #1890FF;
          font-family: var(--font-medium);
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          font-size: vw(12, $s);
        }

        .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
          font-size: vw(12, $s);
        }

        .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
          letter-spacing: vw(2, $s);
        }

        .ant-pagination-jump-next {
          min-width: vw(32, $s);
          height: vw(32, $s);
          line-height: vw(32, $s);
          border-radius: vw(6, $s);
          margin-inline-end: vw(8, $s);

          .ant-pagination-item-container .ant-pagination-item-link-icon {
            font-size: vw(12, $s);
          }
        }

        .ant-select-single {
          font-size: vw(14, $s);
          height: vw(32, $s);

          &.ant-select-show-arrow .ant-select-selection-item {
            padding-inline-end: vw(18, $s);
            line-height: vw(30, $s);
          }

          .ant-select-selector .ant-select-selection-item {
            line-height: vw(30, $s);
          }

          .ant-select-selector {
            font-size: vw(14, $s);
            border-radius: vw(6, $s);
            padding: 0 vw(11, $s) !important;
          }

          .ant-select-dropdown {
            font-size: vw(14, $s);
            padding: vw(4, $s);
            border-radius: vw(8, $s);
            box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);

            .ant-select-item {
              font-size: vw(14, $s);
            }
          }
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
          line-height: vw(30, $s);
          font-size: vw(14, $s);
        }

        .ant-pagination-options {
          margin-inline-start: vw(0, $s);
        }

        :where(.css-dev-only-do-not-override-1k979oh).ant-select .ant-select-arrow {
          inset-inline-end: vw(11, $s);
          height: vw(12, $s);
          margin-top: vw(-6, $s);
          font-size: vw(12, $s);
        }

        .ant-pagination-options-quick-jumper {
          height: vw(32, $s);
          margin-inline-start: vw(8, $s);
          line-height: vw(32, $s);

          input {
            width: vw(50, $s);
            padding: vw(4, $s) vw(11, $s);
            font-size: vw(14, $s);
            border-radius: vw(6, $s);
            border-width: vw(1, $s);
            height: vw(32, $s);
            margin-inline-start: vw(8, $s);
            margin-inline-end: vw(8, $s);
          }
        }
      }
    }
  }

  .t-select-ctrl {
    &.ant-select {
      width: 100%;
      font-size: vw(14, $s);
      height: vw(32, $s);

      .ant-select-selector {
        color: #344054;
        padding: 0 vw(16, $s) !important;
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(8, $s);
        font-family: var(--font-regular);

        .ant-select-selection-search {
          inset-inline-start: vw(11, $s);
          inset-inline-end: vw(11, $s);
        }
      }

      .ant-select-arrow {
        inset-inline-end: vw(11, $s);
        height: vw(12, $s);
        margin-top: vw(-6, $s);
        font-size: vw(12, $s);
      }

      .ant-select-selection-placeholder {
        height: 100% !important;
        line-height: 1;
        padding-inline-end: 0 !important;
        display: flex;
        align-items: center;
      }
    }
  }

  .t-ctrl-date-picker.ant-picker-dropdown {
    font-size: vw(14, $s);

    .ant-picker-header-view button:not(:first-child) {
      margin-inline-start: vw(8, $s);
    }

    .ant-picker-header button {
      line-height: vw(40, $s);
    }

    .ant-picker-header>button {
      font-size: vw(14, $s);
    }

    .ant-picker-header-view {
      line-height: vw(40, $s);
    }

    .ant-picker-next-icon {
      width: vw(7, $s);
      height: vw(7, $s);
    }

    .ant-picker-panel-container {
      border-radius: vw(8, $s);
      box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
    }

    .ant-picker-date-panel {
      width: vw(288, $s);
    }

    .ant-picker-content th {
      height: vw(36, $s);
      min-width: vw(24, $s);
    }

    table {
      border-spacing: vw(2, $s);
    }

    .ant-picker-footer {
      border-top: vw(1, $s) solid rgba(5, 5, 5, 0.06);
    }

    .ant-picker-ranges>li {
      line-height: vw(38, $s);
    }

    .ant-picker-super-prev-icon {
      width: vw(7, $s);
      height: vw(7, $s);
    }

    .ant-picker-super-prev-icon::before,
    .ant-picker-super-prev-icon::after,
    .ant-picker-next-icon::before,
    .ant-picker-next-icon::after,
    .ant-picker-super-next-icon,
    .ant-picker-super-next-icon::before,
    .ant-picker-super-next-icon::after,
    .ant-picker-prev-icon,
    .ant-picker-prev-icon::before {
      width: vw(7, $s);
      height: vw(7, $s);
      border-block-start-width: vw(1.5, $s);
      border-inline-start-width: vw(1.5, $s);
    }

    .ant-picker-super-prev-icon::after {
      top: vw(4, $s);
      inset-inline-start: vw(4, $s);
    }

    .ant-picker-super-next-icon::after {
      top: vw(4, $s);
      inset-inline-start: vw(4, $s);
    }

    .ant-picker-cell .ant-picker-cell-inner {
      min-width: vw(24, $s);
      height: vw(24, $s);
      line-height: vw(24, $s);
      border-radius: vw(4, $s);
    }

  }

  .t-mt-12 {
    margin-top: vw(12, $s);
  }

  .customer-list {
    .ant-breadcrumb {
      font-size: vw(14, $s);
      color: #98A2B3;
      line-height: vw(22, $s);
      font-family: var(--font-regular);
      margin-top: 0;

      a {
        color: #98A2B3;
        height: auto;
      }

      li:last-child {
        color: #344054;
      }

      .ant-breadcrumb-separator {
        // margin-inline: vw(8, $s);
        color: #98A2B3;
      }
    }

    .title-page {
      font-size: vw(20, $s);
      color: #344054;
      line-height: vw(28, $s);
      margin-top: vw(14, $s);
      font-weight: 500;
      margin-bottom: vw(24, $s);
      font-family: var(--font-medium);
    }

    .filter-block {
      display: flex;
      gap: vw(12, $s);
      margin-bottom: vw(18, $s);

      &.active {
        display: none;
      }

      &.filter-active-show {
        display: none;

        &.active {
          display: flex;
        }

        .total-chosse {
          display: flex;
          align-items: center;
        }

        .btn-input {
          display: flex;
          align-items: center;
          gap: vw(8, $s);
          border: vw(1, $s) solid #D0D5DD;

          .icon-svg {
            width: vw(12, $s);
            height: vw(12, $s);
            display: flex;
            align-items: center;

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }

      }

      .btn-input {
        display: flex;
        gap: vw(10, $s);
        border: vw(1, $s) solid #EAECF0;
        height: vw(32, $s);
        padding: vw(0, $s) vw(15, $s);
        align-items: center;
        border-radius: vw(6, $s);
        cursor: pointer;
        white-space: nowrap;

        &.btn-primary {
          background-color: #096DD9;
          color: #fff;
          border: vw(1, $s) solid #096DD9;
        }

        &.t-px-8 {
          padding: vw(8, $s);
        }
      }

      .ant-input-group-wrapper {
        height: 100%;
      }

      .ant-input-wrapper {
        height: 100%;

        .ant-input-outlined,
        .ant-btn-default {
          border: vw(1, $s) solid #F2F4F7;
          height: 100%;
        }

        .ant-input {
          border-start-end-radius: 0;
          border-end-end-radius: 0;
        }
      }

      .ant-input {
        border-radius: vw(8, $s);
        border-width: vw(1, $s);
        font-size: vw(14, $s);
        padding: vw(4, $s) vw(11, $s);
        line-height: vw(22, $s);
      }

      .ant-input-group .ant-input-group-addon {
        font-size: vw(14, $s);
        border-radius: vw(6, $s);
      }

      .ant-btn {
        height: 100%;
        width: vw(32, $s);
        border-start-end-radius: vw(8, $s) !important;
        border-end-end-radius: vw(8, $s) !important;

        .ant-btn-icon {
          color: #8a8a8b;
        }

        .anticon {
          font-size: vw(16, $s);
        }
      }

      .filter-select {
        max-width: vw(303, $s);
        width: 100%;
        height: vw(32, $s);
        position: relative;

        .btn-select-show {
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #F2F4F7;
          padding: vw(4, $s) vw(11, $s);
          height: vw(32, $s);
          cursor: pointer;

          span {
            color: #344054;
            display: block;
          }
        }

        .icon-down,
        .icon-close {
          font-size: vw(10, $s);
          color: #8a8a8b;
          position: absolute;
          top: 50%;
          right: vw(11, $s);
          transform: translateY(-50%);
        }

        .icon-down {
          &.active {
            display: none;
          }
        }

        .icon-close {
          display: none;

          &.active {
            display: block;
          }

          span {
            color: #ff0000;
          }
        }

        .list-filter {
          position: absolute;
          top: 100%;
          z-index: 5;
          padding: vw(8, $s);
          border-radius: vw(6, $s);
          box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
          width: 100%;
          margin-top: vw(12, $s);
          display: none;
          background-color: #fff;

          &.active {
            display: block;
          }

          input {
            margin-bottom: vw(8, $s);
          }

          .list-item {
            max-height: vw(320, $s);
            overflow-y: auto;
          }

          .ant-input,
          .item-select {
            cursor: pointer;
            border-radius: vw(2, $s);
            padding: vw(5, $s) vw(12, $s);
          }

          .item-select {
            margin-bottom: vw(8, $s);
            display: flex;
            color: #667085;
            gap: vw(8, $s);
            align-items: center;
            justify-content: space-between;
            padding: vw(5, $s) vw(12, $s);

            .icon-left {
              font-size: vw(12, $s);
              display: flex;
              gap: vw(8, $s);
            }

            .icon-right {
              font-size: vw(12, $s);
              color: #FF7875;
            }
          }

          .btn-filter {
            border-radius: vw(6, $s);
            border: vw(1, $s) solid #F2F4F7;
            padding: vw(4, $s) vw(11, $s);
            height: vw(32, $s);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #096DD9;
            color: #fff;
            gap: vw(10, $s);

            .icon-btn-filter {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .add-filter {
      display: none;
      position: fixed;
      width: vw(920, $s);
      height: 100vh;
      z-index: 98;
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      top: 0;
      right: 0;
      background-color: #fff;

      &.active {
        display: block;
      }

      .title-popup-add-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .setting-icon {
          display: flex;
          gap: vw(8, $s);
          align-items: center;
          cursor: pointer;

          .icon {
            font-size: vw(14, $s);
          }
        }
      }

      .list-count-car {
        .ant-checkbox-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: vw(12, $s);
        }
      }

      .group-select-all {
        .ant-checkbox-inner {
          margin-left: 0 !important;
        }

        .ant-checkbox-wrapper {
          padding: vw(0, $s) vw(12, $s);
          height: vw(32, $s);
          display: flex;
          align-items: center;

          span:last-child {
            margin-left: vw(8, $s);
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .border-group {
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #D0D5DD;
        }

        .ant-checkbox-group {
          gap: 0;
          max-width: vw(236, $s);
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          border-bottom: none !important;
          border-right: none !important;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner {
          background-color: #f1f5f9 !important;
        }
      }

      .title-add-filter {
        padding: vw(12, $s) vw(24, $s);
        border-bottom: vw(1, $s) solid #f0f0f0;
        font-size: vw(16, $s);
        font-weight: 500;
        line-height: vw(22, $s);
        color: #344054;
        font-family: var(--font-medium);
        min-height: vw(48, $s);
      }

      .t-item-check-gap-8 {
        .ant-checkbox-wrapper {
          gap: vw(8, $s);
          .ant-radio-inner::after {
            width: vw(16, $s);
            height: vw(16, $s);
          }
        }
      }

      .ant-radio-wrapper span.ant-radio+* {
        padding-inline-start: vw(8, $s);
        padding-inline-end: vw(8, $s);
      }

      .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span:last-child {
        color: #1890FF;
      }
      .radio-filter-2column {
        .ant-radio-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          .text {
            padding-left: 0 !important;
            font-size: vw(14, $s);
            font-weight: 400;
            line-height: vw(22, $s);
            color: #667085;
          }
          .ant-checkbox-inner {
            width: vw(16, $s);
            height: vw(16, $s);
          }
        }
      }
      .radio-filter-1column {
        .ant-radio-group {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 12px;
          .text {
            padding-left: 0 !important;
            font-size: vw(14, $s);
            font-weight: 400;
            line-height: vw(22, $s);
            color: #667085;
          }
          .ant-checkbox-inner {
            width: vw(16, $s);
            height: vw(16, $s);
          }
        }
      }
      .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        border-color: #1890FF;
        background-color: #1890FF;
        width: vw(16, $s);
        height: vw(16, $s);
      }

      .ant-radio-wrapper .ant-radio-inner {
        border-color: #1890FF;
        width: vw(16, $s);
        height: vw(16, $s);
      }

      .rs-input {
        height: vw(32, $s);
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(8, $s);
        width: 100%;
        padding: 0 vw(12, $s);
        display: flex;
        align-items: center;
      }

      .title-btn-check-all {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: vw(8, $s);
      }

      .t-search-block {
        margin-bottom: vw(12, $s);
      }

      .mx-h-120 {
        max-height: vw(120, $s);
        overflow-y: auto;

        .ant-checkbox-group {
          margin-top: 0;
        }
      }

      .ant-tree {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        .ant-tree-checkbox.ant-tree-checkbox-checked {
          .ant-tree-checkbox-inner {
            background-color: #1890FF;
          }
        }

        .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
          border: none !important;
        }

        .ant-tree-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: none;
          border-radius: vw(2, $s);
          background-color: #f1f5f9;

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }

      .ant-checkbox {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        &.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1890FF;
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: none;
          border-radius: vw(2, $s);
          background-color: #f1f5f9;

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }

      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: vw(12, $s);
        margin-top: vw(12, $s);
      }

      .col-add-filter {
        width: 100%;

        &:not(:last-child) {
          border-right: vw(1, $s) solid #EAECF0;
          padding-right: vw(4, $s);
        }
      }

      .content-add-filter {
        padding: vw(24, $s) vw(24, $s) 0 vw(24, $s);
        display: flex;
        gap: vw(32, $s);
        height: calc(100vh - vw(96, $s));
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .one-add-filter-block {
          padding-right: vw(32, $s);

          &.t-scrollbar {
            padding-right: vw(20, $s);
            max-height: vw(250, $s);
          }
        }
      }

      .ant-checkbox-wrapper {
        font-size: vw(14, $s) !important;
        padding: vw(8, $s) vw(12, $s);
        border-radius: vw(8, $s);
        color: #667085;
        font-family: var(--font-regular);
        align-items: center;

        // padding-top: vw(4, $s);
        &.ant-checkbox-wrapper-checked {
          background-color: #E6F7FF;

          .text {
            color: #1890FF;
          }
        }

        &:after {
          display: none;
        }
      }

      .col-title-add-filter {
        font-weight: 500;
        padding: vw(4, $s) vw(0, $s) vw(4, $s) 0;
        color: #344054;
        font-family: var(--font-medium);
      }

      .ant-checkbox+span {
        padding-inline-start: vw(0, $s);
        padding-inline-end: vw(0, $s);
        width: 100%;
      }

      .list-add-filter-content {
        .one-add-filter-content {
          display: flex;
          align-items: center;

          .img-text {
            display: flex;
            align-items: center;
            margin-left: vw(8, $s);

            img {
              width: vw(16, $s);
              height: vw(16, $s);
              object-fit: contain;
            }
          }

          .text {
            padding-left: vw(8, $s);
            line-height: 1;
          }
        }
      }

      .slider-price {
        padding-left: vw(2, $s);

        .ant-slider {
          margin: vw(11, $s) vw(5, $s);
          font-size: vw(14, $s);
        }

        .ant-slider-horizontal {
          padding-block: vw(4, $s);
          height: vw(12, $s);

          &.ant-slider-with-marks {
            margin-bottom: vw(30, $s);
          }

          .ant-slider-rail {
            height: vw(4, $s);
            border-radius: vw(2, $s);
          }

          .ant-slider-track {
            height: vw(4, $s);
            border-radius: vw(2, $s);
          }

          .ant-slider-step {
            top: vw(4, $s);
            height: vw(4, $s);
          }

          .ant-slider-dot {
            inset-block-start: vw(-2, $s);
            width: vw(8, $s);
            height: vw(8, $s);
            background-color: #ffffff;
            border: vw(2, $s) solid #91caff;
          }

          .ant-slider-handle {
            inset-block-start: vw(1, $s);
            width: vw(10, $s);
            height: vw(10, $s);

            &::before {
              inset-inline-start: vw(-2, $s);
              inset-block-start: vw(-2, $s);
              width: vw(14, $s);
              height: vw(14, $s);
            }

            &::after {
              width: vw(10, $s);
              height: vw(10, $s);
              box-shadow: 0 0 0 vw(2, $s) #91caff;
            }
          }

          .ant-slider-mark {
            top: vw(12, $s);
            font-size: vw(14, $s);
          }
        }
      }

      .date-picker {
        width: 100%;
        margin-top: vw(12, $s);

        .ant-picker {
          width: 100%;
          padding: vw(4, $s) vw(11, $s) vw(4, $s);
          font-size: vw(14, $s);
          border-radius: vw(6, $s);
          border-width: vw(1, $s);
          border-color: #D9D9D9;
          font-family: var(--font-regular);

          .ant-picker-input {
            input {
              font-size: vw(14, $s);
              min-width: vw(1, $s);
            }
          }
        }
      }

      .list-select-2col {
        .checkbox+span {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }

        .ant-checkbox-group {
          flex-direction: unset;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: vw(12, $s);

          .ant-checkbox-wrapper {
            gap: vw(8, $s);
            display: flex;
            align-items: center;

            .text {
              white-space: nowrap;
              line-height: 1;
            }
          }
        }
      }
      .list-select-1col {
        .checkbox+span {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }

        .ant-checkbox-group {
          flex-direction: column;
          display: flex;
          gap: vw(12, $s);

          .ant-checkbox-wrapper {
            gap: vw(8, $s);
            display: flex;
            align-items: center;

            .text {
              white-space: nowrap;
              line-height: 1;
            }
          }
        }
      }

      .tree-select {
        margin-top: vw(12, $s);

        .ant-tree {
          .ant-tree-treenode {
            padding: 0 0 vw(4, $s) 0;
            position: relative;
            width: 100%;
            padding: vw(8, $s) vw(24, $s) vw(8, $s) vw(12, $s);
            display: flex;
            align-items: center;

            .ant-tree-node-content-wrapper {
              display: flex;
              align-items: center;
            }
          }

          .ant-tree-checkbox {
            margin-inline-end: vw(8, $s);
            margin-top: vw(4, $s);
            font-size: vw(14, $s);
          }

          .ant-tree-checkbox+span {
            min-height: vw(22, $s);
            margin: 0;
            padding: 0;
            color: inherit;
            line-height: 1;
            background: transparent;
            border-radius: vw(6, $s);
          }

          .ant-tree-indent-unit {
            width: vw(24, $s);
          }

          .ant-tree-switcher {
            width: vw(24, $s);
            line-height: vw(24, $s);
            border-radius: vw(6, $s);
            position: absolute;
            top: vw(12, $s);
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &.ant-tree-switcher_open {
              color: #096DD9;

              .ant-tree-switcher-icon {
                svg {
                  transform: rotate(180deg);
                }
              }
            }

            .ant-tree-switcher-icon {
              font-size: vw(14, $s);

              svg {
                transform: rotate(0deg);
              }
            }
          }
        }
      }

      .footer-filter-block {
        .btn-block {
          height: vw(48, $s);
          padding: vw(8, $s) vw(24, $s);
          border-top: vw(1, $s) solid #f0f0f0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: vw(17, $s);

          .btn {
            display: flex;
            gap: vw(8, $s);
            align-items: center;
            justify-content: center;
            border-radius: vw(8, $s);
            border: vw(1, $s) solid #EAECF0;
            height: vw(32, $s);
            color: #000000D9;
            line-height: vw(22, $s);
            cursor: pointer;

            img {
              width: vw(14, $s);
              height: vw(14, $s);
              object-fit: contain;
            }

            &.btn-primary {
              color: #fff;
              background-color: #096DD9;
            }
          }
        }
      }
    }

    .show-access-filter {
      max-width: vw(400, $s);
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      background-color: #fff;
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      display: none;

      &.active {
        display: block;
      }

      .title-access {
        display: flex;
        justify-content: space-between;
        padding: vw(16, $s) vw(24, $s);
        font-size: vw(16, $s);
        font-weight: 500;
        font-family: var(--font-medium);
        border-bottom: vw(1, $s) solid #F0F0F0;
        height: vw(56, $s);
        align-items: center;

        .icon-close {
          color: #00000073;
          font-size: vw(12, $s);
          display: flex;
          align-items: center;
        }
      }

      .input-add-name {
        padding: vw(24, $s);
        border-bottom: vw(1, $s) solid #F0F0F0;
        height: vw(80, $s);

        .ant-input {
          border-radius: vw(8, $s);
          border-width: vw(1, $s);
          font-size: vw(14, $s);
          padding: vw(4, $s) vw(11, $s);
          line-height: vw(22, $s);
          font-family: var(--font-regular);
          border-color: #F2F4F7;

          &::placeholder {
            color: #344054;
          }
        }
      }

      .btn-footer-access {
        padding: vw(8, $s) vw(24, $s);
        display: flex;
        justify-content: flex-end;

        .btn {
          height: vw(32, $s);
          width: vw(55, $s);
          background-color: #096DD9;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: vw(8, $s);
          cursor: pointer;
        }
      }
    }

    .group-customers-block {
      max-width: vw(400, $s);
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      background-color: #fff;
      box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
      color: #344054;

      .title-access {
        display: flex;
        justify-content: space-between;
        padding: vw(16, $s) vw(24, $s);
        font-size: vw(16, $s);
        font-weight: 500;
        font-family: var(--font-medium);
        border-bottom: vw(1, $s) solid #F0F0F0;
        height: vw(56, $s);
        align-items: center;

        .icon-close {
          color: #00000073;
          font-size: vw(12, $s);
          display: flex;
          align-items: center;
        }
      }

      .title-content {
        font-family: var(--font-bold);
      }

      .modal-content {
        padding: vw(24, $s);
        border-bottom: vw(1, $s) solid #F0F0F0;
        display: flex;
        flex-direction: column;
        gap: vw(18, $s);

        .ant-input {
          border-radius: vw(8, $s);
          border-width: vw(1, $s);
          font-size: vw(14, $s);
          padding: vw(4, $s) vw(11, $s);
          line-height: vw(22, $s);
          font-family: var(--font-regular);
          border-color: #F2F4F7;

          &::placeholder {
            color: #344054;
          }
        }
      }

      .btn-footer-access {
        padding: vw(8, $s) vw(24, $s);
        display: flex;
        justify-content: flex-end;

        &.two-btn {
          justify-content: space-between;
          align-items: center;

          .btn-df {
            padding: 0 vw(7, $s);
            color: #98A2B3;
            cursor: pointer;
          }
        }

        .btn {
          height: vw(32, $s);
          width: vw(55, $s);
          background-color: #096DD9;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: vw(8, $s);
          cursor: pointer;
        }
      }

      .link-block {
        display: flex;
        gap: vw(10, $s);
        color: #1890FF;
        padding: vw(0, $s) vw(7, $s);
        color: #1890FF;
      }

      .list-tab {
        display: flex;

        .tab-left,
        .tab-right {
          flex: 0 0 auto;
          display: flex;
          gap: vw(4, $s);
          border: vw(1, $s) solid #D9D9D9;
          padding: vw(5, $s) vw(16, $s);
          align-items: center;
          cursor: pointer;

          .icon-svg {
            font-size: vw(12, $s);
          }

          &.active {
            border: vw(1, $s) solid #1890FF;
            color: #1890FF;
          }
        }

        .tab-left {
          border-radius: vw(6, $s) 0 0 vw(6, $s);
          border-right: none;
        }

        .tab-right {
          border-radius: 0 vw(6, $s) vw(6, $s) 0;
          border-left: none;
        }
      }

      .t-search-block {
        input::placeholder {
          color: #98A2B3 !important;
        }

        .ant-btn-default {
          border-color: #F2F4F7;
        }
      }

      .input-add-name {
        input::placeholder {
          color: #98A2B3 !important;
        }
      }

      .list-tags {
        display: flex;
        gap: 8px;
        margin-top: vw(12, $s);

        .one-tag {
          display: flex;
          gap: vw(3, $s);
          align-items: center;
          padding: 0 vw(8, $s);
          border-radius: 9999px;
          border: vw(1, $s) solid #91D5FF;
          background-color: #E6F7FF;
          color: #1890FF;
          font-size: vw(12, $s);
          min-height: vw(22, $s);

          &:nth-child(2) {
            border-color: #FFE58F;
            background-color: #FFFBE6;
            color: #FAAD14;
          }

          .icon-close {
            font-size: vw(10, $s);
            color: #00000073;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

    .text-area-block {
      textarea {
        height: vw(54, $s) !important;
        min-height: vw(54, $s) !important;
        max-height: vw(142, $s) !important;
        font-family: var(--font-regular);
        font-weight: 400 !important;

        &::placeholder {
          color: #98A2B3 !important;
        }
      }
    }

    // .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    //   line-height: vw(30, $s);
    //   padding-inline-end: vw(18, $s);
    // }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(30, $s);
      font-size: vw(14, $s);
    }

    .ant-select-single .ant-select-selector {
      font-size: vw(14, $s);
    }

    input {
      padding-block: vw(1, $s);
      padding-inline: vw(2, $s);
      font-size: vw(14, $s);
    }
  }

  .t-search-block {
    max-width: vw(363, $s);
    width: 100%;
    height: vw(32, $s);

    input {
      background-color: #FCFCFD;
    }

    .ant-input-group-addon {
      background-color: #FCFCFD;
    }

    .ant-input-wrapper .ant-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-width: vw(1, $s);
      padding: vw(4, $s) vw(11, $s);
    }

    .ant-btn {
      height: vw(32, $s);
      width: vw(32, $s);
      border-start-end-radius: vw(8, $s) !important;
      border-end-end-radius: vw(8, $s) !important;

      .ant-btn-icon {
        color: #8a8a8b;
      }

      .anticon {
        font-size: vw(16, $s);
      }
    }

    input::placeholder {
      color: #98A2B3;
    }

    .ant-btn-default {
      border-color: #F2F4F7;
    }
  }

  .t-ctrl-popup-select {
    &.ant-select-dropdown {
      padding: vw(4, $s);

      .ant-select-item {
        min-height: vw(32, $s);
        padding: vw(4, $s);
        font-size: vw(14, $s);
        border-radius: vw(8, $s);
        font-family: var(--font-regular);
      }

      .rc-virtual-list-holder {
        max-height: vw(256, $s) !important;
      }
    }
  }

  .filter-setting {
    position: fixed;
    width: vw(400, $s);
    height: 100vh;
    z-index: 98;
    box-shadow: vw(0, $s) vw(2, $s) vw(8, $s) vw(0, $s) #00000026;
    top: 0;
    right: 0;
    background-color: #fff;

    .title-add-filter {
      padding: vw(12, $s) vw(24, $s);
      border-bottom: vw(1, $s) solid #f0f0f0;
      font-size: vw(16, $s);
      font-weight: 500;
      line-height: vw(22, $s);
      color: #344054;
      font-family: var(--font-medium);
      min-height: vw(48, $s);
    }

    .content-filter-setting {
      padding: vw(24, $s);
      height: calc(100vh - vw(96, $s));

      .title-content {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
        color: #344054;
        font-weight: 500;
        font-family: var(--font-medium);
        margin-bottom: vw(18, $s);
      }
    }

    .list-add-filter-content {
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: vw(12, $s);

        .ant-checkbox-wrapper {
          gap: vw(8, $s);
          display: flex;
          align-items: center;

          .text {
            white-space: nowrap;
            line-height: 1;
          }
        }
      }

      .ant-checkbox-wrapper {
        font-size: vw(14, $s) !important;
        padding: vw(8, $s) vw(12, $s);
        border-radius: vw(8, $s);
        color: #667085;
        font-family: var(--font-regular);
        align-items: center;

        &.ant-checkbox-wrapper-checked {
          background-color: #E6F7FF;

          .text {
            color: #1890FF;
          }
        }

        &:after {
          display: none;
        }
      }

      .ant-checkbox {
        border-radius: vw(2, $s);
        font-size: vw(14, $s);

        &.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1890FF;
        }

        .ant-checkbox-inner {
          width: vw(16, $s);
          height: vw(16, $s);
          border: none;
          border-radius: vw(2, $s);
          background-color: #f1f5f9;

          &::after {
            width: vw(7, $s);
            height: vw(8, $s);
            border: vw(2, $s) solid #fff;
            border-top: none;
            border-left: none;
          }
        }
      }
    }

    .footer-filter-block {
      .btn-block {
        height: vw(48, $s);
        padding: vw(8, $s) vw(24, $s);
        border-top: vw(1, $s) solid #f0f0f0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: vw(17, $s);

        .btn {
          display: flex;
          gap: vw(8, $s);
          align-items: center;
          justify-content: center;
          border-radius: vw(8, $s);
          border: vw(1, $s) solid #EAECF0;
          height: vw(32, $s);
          color: #000000D9;
          line-height: vw(22, $s);
          cursor: pointer;

          img {
            width: vw(14, $s);
            height: vw(14, $s);
            object-fit: contain;
          }

          &.btn-primary {
            color: #fff;
            background-color: #096DD9;
          }
        }
      }
    }
  }

  .t-align-center {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }

  .t-switch-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.t-switch-block-sm {
      overflow: hidden;

      .ant-switch {
        min-width: 0;
        width: vw(28, $s);
        height: vw(16, $s);

        &.ant-switch-checked {
          .ant-switch-handle {
            inset-inline-start: calc(100% - vw(14, $s));
            width: vw(12, $s);
            height: vw(12, $s);

            &::before {
              border-radius: vw(9, $s);
              box-shadow: 0 vw(2, $s) vw(4, $s) 0 rgba(0, 35, 11, 0.2);
            }
          }

          .ant-switch-inner {
            padding-inline-start: vw(6, $s);
            padding-inline-end: vw(21, $s);
            border-radius: vw(100, $s);
          }
        }

        span {
          height: vw(16, $s) !important;
          border: 0.0694444444vw solid transparent !important;
        }

        .ant-switch-inner {
          padding-inline-start: vw(6, $s);
          padding-inline-end: vw(21, $s);
          border-radius: vw(100, $s);
        }

        .ant-switch-handle {
          top: 50%;
          inset-inline-start: vw(2, $s);
          width: vw(12, $s);
          height: vw(12, $s);
          transform: translateY(-50%);

          &::before {
            border-radius: vw(9, $s);
            box-shadow: 0 vw(2, $s) vw(4, $s) 0 rgba(0, 35, 11, 0.2);
          }
        }
      }
    }

    .ant-switch {
      font-family: var(--font-regular);
      font-size: vw(14, $s);
      line-height: vw(22, $s);
      min-width: vw(44, $s);
      height: vw(22, $s);
      border-radius: vw(100, $s);

      &.ant-switch-checked {
        .ant-switch-handle {
          // inset-inline-start: vw(2, $s);
          // top: vw(2, $s);
          // inset-inline-start: vw(2, $s);
          inset-inline-start: calc(100% - vw(20, $s));
          width: vw(18, $s);
          height: vw(18, $s);

          &::before {
            border-radius: vw(9, $s);
            box-shadow: 0 vw(2, $s) vw(4, $s) 0 rgba(0, 35, 11, 0.2);
          }
        }

        .ant-switch-inner {
          padding-inline-start: vw(9, $s);
          padding-inline-end: vw(24, $s);
          border-radius: vw(100, $s);
        }
      }

      .ant-switch-inner {
        padding-inline-start: vw(9, $s);
        padding-inline-end: vw(24, $s);
        border-radius: vw(100, $s);
      }

      .ant-switch-handle {
        top: vw(2, $s);
        inset-inline-start: vw(2, $s);
        width: vw(18, $s);
        height: vw(18, $s);

        &::before {
          border-radius: vw(9, $s);
          box-shadow: 0 vw(2, $s) vw(4, $s) 0 rgba(0, 35, 11, 0.2);
        }
      }
    }

    .ant-radio-group {
      display: flex !important;
    }
  }
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-tree .ant-tree-switcher:not(.ant-tree-switcher-noop):hover:before {
  background-color: transparent;
}

.customer-list .add-filter .tree-select .ant-tree .ant-tree-checkbox+span {
  color: #667085;
}

.t-mb-0 {
  margin-bottom: 0 !important;
}

@include vwConvert() using ($s) {
  .rs-badge {
    &.ant-badge-status {
      .ant-badge-status {
        &-dot {
          height: vw(6, $s);
          width: vw(6, $s);
          top: vw(-1, $s)
        }
      }
    }
  }
}

.rs-badge {
  &.ant-badge-status {
    font-size: inherit;
    font-family: inherit;
    color: inherit;

    .ant-badge-status {
      &-success {
        background: var(--polar-green);
      }
    }
  }
}

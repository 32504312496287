@include vwConvert() using ($s) {
  .rs-form {
    .ant-form {
      &-item {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        margin-block-end: vw(24, $s);

        .ant-form-item-explain,
        .ant-form-item-extra {
          font-size: vw(14, $s);
          line-height: vw(22, $s);
        }
      }
    }
    &-item {
      &--horizontal {
        .ant-form-item-row {
          gap: vw(20, $s);
        }
      }
    }
  }
}

.rs-form {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;

  .ant-form {
    &-item {
      font-family: var(--font-regular);
      color: var(--neutral-gray-700);

      .ant-form-item-explain,
      .ant-form-item-extra {
        font-family: var(--font-regular);
      }
    }
  }
  .ant-col,
  .ant-row,
  .ant-form-item .ant-form-item-label >label {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  &-item {
    &--horizontal {
      align-items: center;

      .ant-form-item-row .ant-form-item-label > label {
        height: auto;

        &::after {
          display: none;
        }
      }
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .relative-collapse {
    &__title {
      min-height: vw(46, $s);

      .icon {
        flex: 0 0 vw(40, $s);
        max-width: vw(40, $s);
        font-size: vw(14, $s);
      }
      .title {
        gap: vw(12, $s);
        padding-block: vw(12, $s);
      }
      &.show {
        box-shadow: 0 vw(-1, $s) 0 0 var(--primary-5) inset;
      }
      &.collapsed {
        box-shadow: 0 vw(-1, $s) 0 0 var(--neutral-gray-300) inset;
      }
    }
    &__content {
      .item {
        // min-height: vw(46, $s);
        line-height: vw(22, $s);
        padding-block: vw(12, $s);
        padding-inline: vw(16, $s);

        &__value {
          max-width: vw(200, $s);
        }
      }
      &.show {
        box-shadow: 0 vw(-1, $s) 0 0 var(--neutral-gray-300) inset;
      }
    }
    &-with-control {
      border-width: vw(1, $s);
      border-radius: vw(12, $s);

      .relative-collapse {
        &__title {
          padding-inline-end: vw(12, $s);

          .control {
            padding: vw(4, $s);
            gap: vw(8, $s);

            .ant-btn {
              box-shadow: 0 vw(2, $s) vw(8, $s) 0 #00000026;
              font-size: vw(14, $s);
              height: vw(24, $s);
            }
          }
          &.collapsed {
            box-shadow: none;
          }
        }
        &__content {
          &.show {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.relative-collapse {
  &__title {
    display: flex;
    user-select: none;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.25s;
    }
    .title {
      display: flex;
      align-items: center;
      align-self: center;
      flex-grow: 1;
    }
    &.show {
      background: var(--primary-1);

      .icon {
        transform: rotate(180deg);
        color: var(--primary-5);
      }
    }
  }
  &__content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.25s;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__value {
        color: var(--neutral-gray-400);
        text-align: end;
      }
    }
    // &.collapsed {
    //   display: none;
    // }
    &.show {
      max-height: 1000px;
    }
  }
  &-with-control {
    border-style: solid;
    border-color: var(--neutral-gray-100);
    overflow: hidden;

    .relative-collapse {
      &__title {
        .control {
          display: flex;
          align-items: center;
          margin-inline-start: auto;

          .ant-btn {
            box-shadow: 0px 2px 8px 0px #00000026;
            border: none;
          }
        }
      }
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .customer-detail-content {
    gap: vw(24, $s);
  }
}

.customer-detail-content {
  display: flex;
  flex-direction: column;

  // .ant-tabs {
  //   flex-grow: 1;

  //   &-content {
  //     &-holder {
  //       flex-grow: 1;
  //     }
  //     &-top {
  //       min-height: 100%;
  //     }
  //   }
  //   &-tabpane-active {
  //     min-height: 100%;
  //   }
  // }
}

.t-table-customer {
  .content-note-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .care-feedback-tab {
    grid-template-columns: vw(572, $s) vw(596, $s);
    gap: vw(24, $s);

    &__left {
      border-width: vw(1, $s);
      border-radius: vw(12, $s);
      padding: vw(16, $s);
      gap: vw(18, $s);
    }
    &__right {
      gap: vw(12, $s);
    }
    .tab {
      &-left {
        &__block {
          gap: vw(12, $s);
        }
      }
      &-right {
        &__head {
          grid-template-columns: vw(256, $s) vw(110, $s) vw(90, $s) vw(46, $s) vw(46, $s);
          gap: vw(12, $s);
        }
        &__foot {
          .ant-pagination-prev,
          .ant-pagination-next,
          .ant-pagination-jump-prev,
          .ant-pagination-jump-next,
          .ant-pagination-item {
            height: vw(24, $s);
            min-width: vw(24, $s);
            line-height: vw(24, $s);
            background: transparent;
          }
          .ant-select-single,
          .ant-pagination-total-text,
          .ant-pagination-options-quick-jumper,
          .ant-pagination-options-quick-jumper input {
            height: vw(24, $s);
            line-height: vw(24, $s);
          }
          .ant-pagination-options-quick-jumper,
          .ant-pagination-options-quick-jumper input {
            margin-inline-start: vw(7, $s);
          }
          .ant-pagination-options-quick-jumper input {
            width: vw(44, $s);
          }
          .ant-select-single .ant-select-selection-item,
          .ant-select-single .ant-select-selection-placeholder {
            padding: 0 vw(22, $s) 0 vw(8, $s);
          }
        }
      }
    }

    &-new {
      gap: vw(18, $s);

      .filter-block {
        gap: vw(12, $s);

        .ant-picker {
          flex: 1 1 vw(256, $s);
        }
        .ant-select {
          flex: 1 1 vw(163, $s);
        }
      }
    }
  }
}

.care-feedback-tab {
  display: grid;

  &__left {
    border-color: var(--neutral-gray-200);
    border-style: solid;
    display: flex;
    flex-direction: column;
  }
  &__right {
    display: flex;
    flex-direction: column;
  }
  .tab {
    &-left {
      &__block {
        display: flex;
        flex-direction: column;
      }
    }
    &-right {
      &__head {
        display: grid;
      }
      &__foot {
        .ant-pagination {
          gap: 0;
        }
        .ant-pagination-jump-prev,
        .ant-pagination-jump-next,
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link,
        .ant-pagination-item:not(.ant-pagination-item-active) {
          border-color: transparent;
        }
        .ant-pagination-options {
          margin-inline-start: 0;
        }
        .ant-pagination-options-quick-jumper input {
          margin-inline-end: 0;
        }
      }
    }
  }

  &-new {
    display: flex;
    flex-direction: column;

    .filter-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 1200px) {
      .filter-block {
        flex-wrap: nowrap;
      }
    }
  }
}

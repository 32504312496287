@import '../../../styles/root.scss';

@include vwConvert() using ($s) {
  .warranty-history-popup {
    &__content {
      gap: vw(18, $s);
    }
    &__footer {
      bottom: vw(-24, $s);
      padding: vw(8, $s) vw(24, $s);
      margin-inline: vw(-24, $s);
      margin-block-end: vw(-24, $s);
      border-block-start: vw(1, $s) solid #0000000F;
    }
  }
}

.warranty-history-popup {
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__footer {
    position: sticky;
    z-index: 1;
    background: #FFFFFF;
    margin-block-start: auto;
  }
}
@include vwConvert() using ($s) {
  .rs-switch {
    width: vw(44, $s);
    height: vw(22, $s);

    .ant-switch-handle {
      height: vw(18, $s);
      width: vw(18, $s);
      top: vw(2, $s);
      inset-inline-start: vw(2, $s);

      &::before {
        border-radius: vw(9, $s);
        box-shadow: 0 vw(2, $s) vw(4, $s) 0 rgba(0, 35, 11, 0.2);
      }
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        inset-inline-start: calc(100% - vw(20, $s));
      }
    }
    &--sm {
      width: vw(28, $s);
      height: vw(16, $s);

      .ant-switch-handle {
        height: vw(12, $s);
        width: vw(12, $s);
      }
      &.ant-switch-checked {
        .ant-switch-handle {
          inset-inline-start: calc(100% - vw(14, $s));
        }
      }
      &.ant-switch{
        font-size: vw(14, $s);
        line-height: vw(22, $s);
        .ant-switch-loading-icon.anticon{
          top: vw(2, $s);
        }
        .anticon-loading svg{
          width: vw(8, $s);
          height: vw(8, $s);
        }
      }
    }
  }
}

.rs-switch {
  min-width: auto;

  .ant-switch-inner {
    padding: 0;
  }
  &.ant-switch-checked {
    background: var(--primary-6);

    .ant-switch-inner {
      padding: 0;
    }
  }
}

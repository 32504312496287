@import '../../../../Customers/Component/AddCustomer/style.scss';
@import '../../../../../styles/root.scss';

@include vwConvert() using ($s) {
  .modal-content-vehicle.ant-modal .ant-modal-content {
    border-radius: vw(2, $s) !important;
    box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05) !important;
    padding: unset !important;
    height: auto;
  }

  .input-custom.ant-input {
    padding: vw(4, $s) vw(16, $s);
  }

  .custome-select-w-210-px {
    &.ant-select-single {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: vw(210, $s);
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #ff4d4f;
      background: white;
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      background: #FCFCFD;
      border-color: #F2F4F7;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &.ant-select-single .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(32, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      font-size: vw(12, $s);
      color: #98A2B3;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-arrow {
      color: #ff4d4f !important;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: vw(30, $s);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: vw(30, $s);
    }
  }

  .custome-select-t {
    &.ant-select-single {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: 100%;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #ff4d4f;
      background: white;
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      background: #FCFCFD;
      border-color: #F2F4F7;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &.ant-select-single .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(32, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      font-size: vw(12, $s);
      color: #344054;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-arrow {
      color: #ff4d4f !important;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: vw(30, $s);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: vw(30, $s);
    }
  }

  .custome-select-98A2B3 {
    &.ant-select-single {
      font-size: vw(14, $s);
      height: vw(32, $s);
      width: 100%;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #ff4d4f;
      background: white;
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      background: #FCFCFD;
      border-color: #F2F4F7;
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: vw(16, $s);
      padding-right: vw(16, $s);
    }

    &.ant-select-single .ant-select-selector {
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      font-family: var(--font-regular);
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: vw(32, $s);
    }

    &.ant-select .ant-select-arrow {
      inset-inline-end: vw(11, $s);
      height: vw(12, $s);
      margin-top: vw(-6, $s);
      font-size: vw(12, $s);
      color: #98A2B3;
    }

    &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-arrow {
      color: #ff4d4f !important;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: vw(30, $s);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: vw(30, $s);
    }
  }
}

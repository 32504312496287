@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .campaign-sends-notification-content {
    .vip-care-report-table {
      padding-block-start: vw(6, $s);
    }

    .ctrl-select-kenh {
      position: relative;
      &:hover {
        .ctrl-pa {
          display: block;
        }
      }
      .ctrl-pa {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 3;
        min-width: vw(106,$s);
        padding-top: vw(12,$s);
        display: none;
        .list-kenh {
          position: relative;
          padding: vw(4,$s);
          background-color: #fff;
          border-radius: vw(8,$s);
          box-shadow: 0 vw(3,$s) vw(6,$s) vw(-4,$s) #0000001F;
          .icon-top-right {
            position: absolute;
            bottom: 100%;
            right: vw(8,$s);
            z-index: 1;
            height: vw(12,$s);
          }
        }
      }
      .btn-input {
        display: flex;
        align-items: center;
        gap: vw(8, $s);
        height: vw(32,$s);
        padding-left: vw(12,$s);
        padding-right: vw(12,$s);
        font-size: vw(14,$s);
        cursor: pointer;
        &:hover {
          background-color: #F2F4F7;
        }
        .icon-svg {
          width: vw(12, $s);
          height: vw(12, $s);
          display: flex;
          align-items: center;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        .text {
          white-space: nowrap;
        }
      }
    }
  }
}

.campaign-sends-notification-content {
  display: flex;
  flex-direction: column;

  .vip-care-report-table,
  .manual-campaign-table,
  .automated-campaign-table,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

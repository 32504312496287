@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .voucher-card {
    padding: vw(16, $s) vw(24, $s);
    border-radius: vw(12, $s);
    gap: vw(16, $s);
    box-shadow: 0 0 0 vw(1, $s) #FFFFFF;
    min-height: vw(148, $s);

    &::before,
    &::after {
      border-radius: vw(12, $s);
    }

    &::after {
      inset: vw(1, $s);
    }

    .bg-img {
      inset: vw(29, $s) 0 vw(1, $s) auto;
      height: vw(120, $s);
      width: vw(181, $s);
    }

    .top {
      gap: vw(12, $s);
      position: relative;
      z-index: 1;

      &-item {
        gap: vw(8, $s);
        .anticon-edit,
        .anticon-delete{
          cursor: pointer;
        }

        &:not(:last-child) {
          &::after {
            content: "";
            height: vw(16, $s);
            width: vw(1, $s);
            background: var(--neutral-gray-100);
            margin-inline-start: vw(4, $s);
          }
        }
      }
    }

    .bottom {
      gap: vw(16, $s);
    }

    .title {
      font-size: vw(16, $s);
      line-height: vw(24, $s);
      position: relative;
      z-index: 1;
    }
  }

  .pagination-block {
    padding: vw(9, $s);

    .ant-pagination {
      text-align: center;
      font-size: vw(14, $s);
      font-family: var(--font-regular);
      color: #344054;

      .ant-select-single .ant-select-selector {
        color: #344054;

        .ant-select-selection-search {
          inset-inline-start: vw(14, $s);
          inset-inline-end: vw(14, $s);
        }
      }

      .ant-pagination-item-active a {
        color: #1890FF !important;
      }

      .ant-pagination-item a {
        color: #344054;
      }

      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-prev {
        min-width: vw(32, $s);
        height: vw(32, $s);
        margin-inline-end: vw(8, $s);
        font-family: var(--font-regular);
        line-height: vw(30, $s);
        border: vw(1, $s) solid #F2F4F7;
        border-radius: vw(2, $s);
      }

      .ant-pagination-item-active {
        border-color: #1890FF;
        font-family: var(--font-medium);
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        font-size: vw(12, $s);
      }

      .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
        font-size: vw(12, $s);
      }

      .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        letter-spacing: vw(2, $s);
      }

      .ant-pagination-jump-next {
        min-width: vw(32, $s);
        height: vw(32, $s);
        line-height: vw(32, $s);
        border-radius: vw(6, $s);
        margin-inline-end: vw(8, $s);

        .ant-pagination-item-container .ant-pagination-item-link-icon {
          font-size: vw(12, $s);
        }
      }

      .ant-select-single {
        font-size: vw(14, $s);
        height: vw(32, $s);

        &.ant-select-show-arrow .ant-select-selection-item {
          padding-inline-end: vw(18, $s);
          line-height: vw(30, $s);
        }

        .ant-select-selector .ant-select-selection-item {
          line-height: vw(30, $s);
        }

        .ant-select-selector {
          font-size: vw(14, $s);
          border-radius: vw(6, $s);
          padding: 0 vw(11, $s) !important;
        }

        .ant-select-dropdown {
          font-size: vw(14, $s);
          padding: vw(4, $s);
          border-radius: vw(8, $s);
          box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);

          .ant-select-item {
            font-size: vw(14, $s);
          }
        }
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
        line-height: vw(30, $s);
        font-size: vw(14, $s);
      }

      .ant-pagination-options {
        margin-inline-start: vw(0, $s);
      }

      :where(.css-dev-only-do-not-override-1k979oh).ant-select .ant-select-arrow {
        inset-inline-end: vw(11, $s);
        height: vw(12, $s);
        margin-top: vw(-6, $s);
        font-size: vw(12, $s);
      }

      .ant-pagination-options-quick-jumper {
        height: vw(32, $s);
        margin-inline-start: vw(8, $s);
        line-height: vw(32, $s);

        input {
          width: vw(50, $s);
          padding: vw(4, $s) vw(11, $s);
          font-size: vw(14, $s);
          border-radius: vw(6, $s);
          border-width: vw(1, $s);
          height: vw(32, $s);
          margin-inline-start: vw(8, $s);
          margin-inline-end: vw(8, $s);
        }
      }
    }
  }
}

.voucher-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }

  &::before {
    background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
    inset: 0;
  }

  &::after {
    background: linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%), linear-gradient(99.66deg, #FFFFFF 7.42%, #EEF7FF 100%);
  }

  .bg-img {
    position: absolute;
    pointer-events: none;
    z-index: 0;
  }

  .bottom,
  .top {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
    }
  }

  .top {
    &-item {
      &:not(:last-child) {
        &::after {
          content: "";
        }
      }
    }
  }

  .title {
    font-family: var(--font-medium);
  }
}

@font-face {
  font-family: 'SF-Pro-Display-Light';
  src: url('../fonts/SF-Pro-Display/SF-Pro-Display-Light.otf') format('opentype');
}
@font-face {
  font-family: 'SF-Pro-Display-Regular';
  src: url('../fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'SF-Pro-Display-Medium';
  src: url('../fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'SF-Pro-Display-Bold';
  src: url('../fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf') format('opentype');
}

.font {
  &-light {
    font-family: var(--font-light);
  }
  &-regular {
    font-family: var(--font-regular);
  }
  &-medium {
    font-family: var(--font-medium);
  }
  &-bold {
    font-family: var(--font-bold);
  }
}
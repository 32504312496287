@include vwConvert() using ($s) {
  .custom-search-auto{
    font-size: vw(14, $s);
    .search-res-main{
      box-shadow: 0  vw(16, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08), 0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12), 0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
      border-radius: vw(4, $s);
    }
    .search-res-list{
      padding: vw(8, $s);
      display: flex;
      flex-direction: column;
      gap: vw(2, $s);
      max-height: vw(200, $s);
      overflow-y: auto;
    }
    .search-res-item{
      gap: vw(8, $s);
      padding: vw(4, $s) vw(6, $s);
    }
    .input-search{
      padding: vw(6, $s) vw(16, $s);
      gap: vw(8, $s);
      border: vw(1, $s) solid #e0e0e0;
      border-radius: vw(8, $s);
    }
    .search-res{
      padding-top: vw(8, $s);
    }
  }
}
.custom-search-auto{
  position: relative;
  z-index: 10;
  &.active{
    .search-res{
      display: block;
    }
  }
  .search-res{
    position: absolute;
    top: 100%;
    width: 100%;
    display: none;
  }
  .search-res-main{
    background: #fff;
  }
  .search-res-item{
    display: flex;
    justify-content: space-between;
    .anticon-check{
      color: #1890FF;
    }
    &.active{
      background: #f5f5f5;
    }
  }
  .input-search{
    display: flex;
    &:hover{
      border-color: #1890FF;
    }
    .custom-input-search-auto{
      width: 100%;
      border: none;
      &::placeholder{
        color: #b0b0b0;
      }
    }
    .icon-search-auto{
      color: #b0b0b0;
    }
  }
}

@import '../../styles/root.scss';

@include vwConvert() using ($s) {
  .right-popup {
    width: vw(400, $s);
    box-shadow: 0 vw(6, $s) vw(8, $s) 0 #00000026;

    &__title {
      padding: vw(12, $s) vw(24, $s);
      box-shadow: 0 vw(-1, $s) 0 0 #F0F0F0 inset;
      font-size: vw(16, $s);
      line-height: vw(24, $s);
    }
    &__content {
      padding: vw(24, $s);
    }
  }
}

.right-popup {
  position: fixed;
  inset: 0 0 0 auto;
  z-index: 19;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  transition: all .25s;
  transform-origin: right;
  transform: rotateY(90deg);
  opacity: 0;
  
  &__title {
    // box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
    font-family: var(--font-medium);
    color: var(--neutral-gray-700);
  }
  &__content {
    flex-grow: 1;
    overflow-y: auto;
  }
  &-backdrop {
    position: fixed;
    inset: 0;
    height: 100vh;
    width: 100vw;
    background: transparent;
    z-index: 18;
  }
  &.show {
    transform: translateX(0);
    opacity: 1;
  }
}